import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Breadcrumbs,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import MenuIcon from "../icons/MenuIcon";
import { useTheme } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, Button, IconButton, Paper, Skeleton } from "@mui/material";

import { getDataWithToken } from "../../services/GetDataService";
import { AuthContext } from "../../context/AuthContext";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import { handlePutData } from "../../services/PutDataService";
import RefreshToken from "../../services/RefreshToken";
// import Reject from "./Reject";
import CopyUrl from "../carts/live_cart/CopyUrl";
import PulseLoader from "react-spinners/PulseLoader";
import { handlePutRowData } from "../../services/PutRowDataService";
import Descreption from "../carts/live_cart/Descreption";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import DoneAllIcon from "@mui/icons-material/DoneAll";
// import EditPrice from "./EditPrice";
// import EditPostage from "./EditPostage";
// import RejectCart from "./RejectCart";
const options = ["Option 1", "Option 2"];

const itemTrackingStatusData = [
  "PROCESSED",
  "PLACED",
  "SHIPPED_TO_WAREHOUSE",
  "IN_WAREHOUSE",
  "SHIPPED_TO_IRAQ",
  "ARRIVED_AT_BAGHDAD",
  "OUT_FOR_DELIVERY",
  "OFFICE_PICKUP",
  "CANCELLED",
];
const OrderDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  const { orderType } = useParams();
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [shoppingCartList, setShoppingCartList] = useState([]);
  const [message, setMessage] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [validationIndex, setValidationIndex] = useState();
  const [cancelDialog, setCancelDialog] = useState(false);
  const [reason, setReason] = useState("");
  const [approveCartDialog, setApproveCartDialog] = useState(false);
  const [approveCartloading, setApproveCartloading] = useState(false);
  const [cancelCartLoading, setCancelCartLoading] = useState(false);

  const handleClickOpen = () => {
    setApproveCartDialog(true);
  };

  const handleApproveCartDialogClose = () => {
    setApproveCartDialog(false);
  };
  const customeTextFeild = {
    // padding: "15px 20px",
    background: "#FAFAFA",
    "& label.Mui-focused": {
      color: "#A0AAB4",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#B2BAC2",
    },
    "& .MuiOutlinedInput-input": {
      // padding: "15px 24px 15px 0px",
    },
    "& .MuiOutlinedInput-root": {
      // paddingLeft: "24px",
      "& fieldset": {
        borderColor: "rgba(0,0,0,0)",
      },

      "&:hover fieldset": {
        borderColor: "#969696",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#969696",
      },
    },
  };
  const handleCloseCancelDialog = () => {
    setCancelDialog(false);
    setReason("");
  };

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const pageLoading = () => {
    let rows = [];

    for (let i = 0; i < 10; i++) {
      let cells = [];

      for (let j = 0; j < 27; j++) {
        cells.push(
          <TableCell key={j} sx={{ py: 1.5 }}>
            <Skeleton></Skeleton>
          </TableCell>
        );
      }

      rows.push(<TableRow key={i}>{cells}</TableRow>);
    }

    return rows;
  };

  const fnCancelReason = (item, index) => {
    if (
      orderType === "Live" &&
      item.shipmentItemDetails?.itemTrackingStatus === "CANCELLED"
    ) {
      return (
        <TextField
          id={`cancellationReason${index}`}
          variant="standard"
          sx={{
            backgroundColor: "#FFFAE7",
            px: 1,
            width: "300px",
          }}
          InputProps={{
            disableUnderline: true,
            // inputProps: {
            //   min: 0,
            //   maxLength: 100,
            //   step: 1,
            // },
          }}
          // type="number"
          size="small"
          value={item.shipmentItemDetails?.cancellationReason}
          onChange={(e) =>
            handleInputChange(e.target.value, "cancellationReason", item, index)
          }

          // disabled={item.itemStatus?.statusType === "R_N"}
        />
      );
    } else {
      return item.shipmentItemDetails?.cancellationReason;
    }
  };
  function convertToTitleCase(str) {
    // Split the string by underscores
    let words = str.split("_");

    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1).toLowerCase();
    }

    // Join the words with spaces
    return words.join(" ");
  }
  const getData = async () => {
    setLoading(true);
    setMessage("");
    let newPageNO;

    let url = `api/v1/private/order/${id}/details`;
    let token = await RefreshToken(dizli_admin_panel, logout, login);

    let res = await getDataWithToken(url, token);

    if (res?.status > 199 && res?.status < 300) {
      if (orderType === "Live") {
        let newShoppingCartList = [];
        res.data.data.shoppingCartList.map((item) => {
          let oldShipmentItemDetails = item.shipmentItemDetails;

          console.log(
            "item?.itemStatus?.statusType ",
            item?.itemStatus?.statusType,
            item?.customItemId
          );
          if (item?.itemStatus?.statusType === "A_N") {
            newShoppingCartList.push({
              ...item,
              shipmentItemDetails: {
                ...oldShipmentItemDetails,
                purchasedStatus:
                  oldShipmentItemDetails?.purchasedStatus === null
                    ? "PENDING"
                    : oldShipmentItemDetails?.purchasedStatus,
                itemTrackingStatus: oldShipmentItemDetails?.itemTrackingStatus
                  ? oldShipmentItemDetails?.itemTrackingStatus
                  : itemTrackingStatusData[0],
              },
            });
          }
        });

        setShoppingCartList(newShoppingCartList);
      } else {
        setShoppingCartList(res.data.data.shoppingCartList);
      }

      setList(res.data.data);
    } else {
      setMessage(res.data.message);
    }
    setLoading(false);
  };

  const getCategory = async () => {
    let url = `api/v1/private/admin/dashboard-category/category-names`;
    let token = await RefreshToken(dizli_admin_panel, logout, login);

    let res = await getDataWithToken(url, token);

    if (res?.status > 199 && res?.status < 300) {
      setCategoryList(res.data.data);
    } else {
      setMessage(res.data.message);
    }
    setLoading(false);
  };

  const handleUpdate = async (e) => {
    setUpdateLoading(true);

    let token = await RefreshToken(dizli_admin_panel, logout, login);

    // return console.log('data', data);

    try {
      const formData = new FormData();
      formData.append("cartId", id);
      let url = `api/v1/private/admin/order/transfer-to-delivery-list`;
      let response = await handlePutData(url, formData, token);

      if (response?.status > 199 && response?.status < 300) {
        handleSnakbarOpen(response.data.message, "success");
        navigate("/orders");
      }
      setUpdateLoading(false);
    } catch (error) {
      setUpdateLoading(false);
      if (error?.response?.status === 500) {
        handleSnakbarOpen(error?.response?.statusText, "error");
      } else {
        setErrors(error.response.data.errors);
      }
    }

    setUpdateLoading(false);
  };

  const buttonStyle = {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "14px",
    pr: 2,
    pl: 2.625,
    py: 0.75,
    "& .MuiButton-startIcon": {
      mr: 0.625,
    },
  };
  const customeSelect = {
    background: "#fff",

    "& .MuiInputBase-input": {
      borderRadius: 50,
      position: "relative",
      // backgroundColor: theme.palette.background.paper,
      // border: "1px solid #ced4da",
      fontSize: 14,
      color: "#222",
      // padding: "6px 16px 6px 44px",
      // Use the system font instead of the default Roboto font.
    },
  };

  const handleInputChange = (value, inputName, item, i) => {
    // let product = item.product;
    // item.shipmentItemDetails?.altLink
    let newProduct = item.product;
    let newShipmentItemDetails = item.shipmentItemDetails;
    let newObj = item;

    if (inputName === "postage") {
      // newShipmentItemDetails = {
      //   ...item?.shipmentItemDetails,
      //   altLink: value,
      // };

      newObj = {
        ...item,
        postage: value,
      };
    }
    if (inputName === "altLink") {
      newShipmentItemDetails = {
        ...item?.shipmentItemDetails,
        altLink: value,
      };

      newObj = {
        ...item,
        shipmentItemDetails: newShipmentItemDetails,
      };
    }
    if (inputName === "cancellationReason") {
      newShipmentItemDetails = {
        ...item?.shipmentItemDetails,
        cancellationReason: value,
      };

      newObj = {
        ...item,
        shipmentItemDetails: newShipmentItemDetails,
      };
    }
    if (inputName === "purchasedVia") {
      newShipmentItemDetails = {
        ...item?.shipmentItemDetails,
        purchasedVia: value,
      };

      newObj = {
        ...item,
        shipmentItemDetails: newShipmentItemDetails,
      };
    }
    if (inputName === "orderNoFromSeller") {
      newShipmentItemDetails = {
        ...item?.shipmentItemDetails,
        orderNoFromSeller: value,
      };

      newObj = {
        ...item,
        shipmentItemDetails: newShipmentItemDetails,
      };
    }
    if (inputName === "trackingNumber") {
      newShipmentItemDetails = {
        ...item?.shipmentItemDetails,
        trackingNumber: value,
      };

      newObj = {
        ...item,
        shipmentItemDetails: newShipmentItemDetails,
      };
    }
    if (inputName === "warehouseNumber") {
      newShipmentItemDetails = {
        ...item?.shipmentItemDetails,
        warehouseNumber: value,
      };

      newObj = {
        ...item,
        shipmentItemDetails: newShipmentItemDetails,
      };
    }
    if (inputName === "shipmentNumber") {
      newShipmentItemDetails = {
        ...item?.shipmentItemDetails,
        shipmentNumber: value,
      };

      newObj = {
        ...item,
        shipmentItemDetails: newShipmentItemDetails,
      };
    }
    if (inputName === "shippedBy") {
      newShipmentItemDetails = {
        ...item?.shipmentItemDetails,
        shippedBy: value,
      };

      newObj = {
        ...item,
        shipmentItemDetails: newShipmentItemDetails,
      };
    }
    if (inputName === "originalPurchasedPrice") {
      newShipmentItemDetails = {
        ...item?.shipmentItemDetails,
        originalPurchasedPrice: value,
      };

      newObj = {
        ...item,
        shipmentItemDetails: newShipmentItemDetails,
      };
    }
    if (inputName === "itemTrackingStatus") {
      if (value !== "CANCELLED") {
        newShipmentItemDetails = {
          ...item?.shipmentItemDetails,
          itemTrackingStatus: value,
          cancellationReason: "",
        };
      } else {
        newShipmentItemDetails = {
          ...item?.shipmentItemDetails,
          itemTrackingStatus: value,
        };
      }

      // if (value !== "CANCELLED") {
      //   newShipmentItemDetails = {
      //     ...item?.shipmentItemDetails,
      //     cancellationReason: "",
      //   };
      // }

      newObj = {
        ...item,
        shipmentItemDetails: newShipmentItemDetails,
      };
    }
    if (inputName === "purchasedStatus") {
      newShipmentItemDetails = {
        ...item?.shipmentItemDetails,
        purchasedStatus: value,
      };

      newObj = {
        ...item,
        shipmentItemDetails: newShipmentItemDetails,
      };
    }
    if (inputName === "productCategory") {
      newShipmentItemDetails = {
        ...item?.shipmentItemDetails,
        productCategory: value,
      };

      newObj = {
        ...item,
        shipmentItemDetails: newShipmentItemDetails,
      };
    }
    if (inputName === "price") {
      // if (parseFloat(value) > 1000000) {
      //   handleSnakbarOpen("price shall not exceed 1000000", "error");
      // } else {
      newProduct = {
        ...item?.product,
        price: parseFloat(value),
      };
      newObj = {
        ...item,
        product: newProduct,
      };
      // }
    }
    // if (inputName === "postage") {
    //   if (parseFloat(value) > 1000000) {
    //     handleSnakbarOpen("Postage shall not exceed 1000000", "error");
    //   } else {
    //     newObj = {
    //       ...item,
    //       postage: parseFloat(value),
    //     };
    //   }
    // }

    let newShoppingCartList = shoppingCartList;
    newShoppingCartList[i] = newObj;
    setShoppingCartList(newShoppingCartList);

    // calculateInvoiceData(newShoppingCartList);

    setRefresh(!refresh);
  };
  const handleCancelSubmit = async (e) => {
    // console.log("editIndexNo", editIndexNo);
    e.preventDefault();
    let err = validation();
    if (err) {
      return;
    } else {
      setCancelCartLoading(true);
      let response;
      let token = await RefreshToken(dizli_admin_panel, logout, login);

      // return console.log('data', data);

      const formData = new FormData();
      formData.append("cancellationReason", reason);

      let url = `api/v1/private/admin/order/${id}/cancel`;
      response = await handlePutData(url, formData, token);

      if (response?.status > 199 && response?.status < 300) {
        setReason("");

        handleCloseCancelDialog();

        handleSnakbarOpen("Successful", "success");
        navigate("/live-order-list");
      } else {
        handleSnakbarOpen(response.data.message, "error");
      }
      setCancelCartLoading(false);
    }
    setCancelCartLoading(false);
  };
  const handleApproveEntireCart = async () => {
    // console.log("editIndexNo", editIndexNo);

    setApproveCartloading(true);
    let response;
    let token = await RefreshToken(dizli_admin_panel, logout, login);

    // return console.log('data', data);

    let url = `api/v1/private/admin/order/${id}/mark-as-delivered`;
    response = await handlePutRowData(url, null, token);

    if (response?.status > 199 && response?.status < 300) {
      handleApproveCartDialogClose();

      handleSnakbarOpen("Successful", "success");
      navigate("/live-order-list");
    } else {
      handleSnakbarOpen(response.data.message, "error");
    }
    setApproveCartloading(false);
  };

  const validation = () => {
    let isError = false;

    if (!reason) {
      handleSnakbarOpen("Please enter reason", "error");
      document.getElementById("reason").focus();
      return (isError = true);
    }

    return isError;
  };

  const check = () => {
    console.log("shoppingCartList------------------", shoppingCartList);
  };
  const postData = async (postType) => {
    // let err = validation();

    // if (err) {
    //   return;
    // } else {
    setUpdateLoading(true);
    setMessage("");

    let url = `api/v1/private/admin/order/process`;

    let token = await RefreshToken(dizli_admin_panel, logout, login);
    // working
    let newShipmentItemUpdateRequestList = [];
    // r console.log('postdata', postList)
    shoppingCartList.map((item) => {
      console.log("item.customItemId", item.customItemId);
      let l = {
        shoppingCartItemId: item.id,
        altLink: item.shipmentItemDetails.altLink,
        productCategory: item.shipmentItemDetails.productCategory,
        weight: item.product.weight,
        purchasedStatus: item.shipmentItemDetails.purchasedStatus,
        purchasedVia: item.shipmentItemDetails.purchasedVia,
        orderNoFromSeller: item.shipmentItemDetails.orderNoFromSeller,
        trackingNumber: item.shipmentItemDetails.trackingNumber,
        warehouseNumber: item.shipmentItemDetails.warehouseNumber,
        shipmentNumber: item.shipmentItemDetails.shipmentNumber,
        shippedBy: item.shipmentItemDetails.shippedBy,
        originalPurchasedPrice: item.shipmentItemDetails.originalPurchasedPrice,
        itemPrice: item.product.price,
        postage: item.postage,
        itemTrackingStatus: item.shipmentItemDetails.itemTrackingStatus,
        cancellationReason: item.shipmentItemDetails.cancellationReason,
      };
      newShipmentItemUpdateRequestList.push(l);
    });
    // working

    console.log("shoppingCartList", shoppingCartList);

    let data = {
      cartId: id,
      shipmentItemUpdateRequestList: newShipmentItemUpdateRequestList,
    };
    console.log("post", data);

    let res = await handlePutRowData(url, data, token);

    if (res?.status > 199 && res?.status < 300) {
      console.log("response: ", res);

      enqueueSnackbar(res.data.message, {
        variant: "success",
        autoHideDuration: 3000,
      });

      // navigate("/live-order-list");
    } else {
      setUpdateLoading(false);
      console.log("error response: ", res.data?.message);
      enqueueSnackbar(res.data?.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setUpdateLoading(false);
    // }
    setUpdateLoading(false);
  };

  useEffect(() => {
    getData();
    getCategory();
    // getAllStatus();
  }, []);
  return (
    <div>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100vh" }}
      >
        <Grid item sx={{ width: "100%" }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 2.125, borderBottom: "1px solid #E5E5E5" }}
          >
            <Grid item xs="auto">
              <Typography
                variant="h6"
                color="text.light"
                sx={{ fontWeight: 600 }}
                onClick={check}
              >
                Order Details
                <span style={{ fontWeight: 400 }}>
                  ({shoppingCartList?.length})
                </span>
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Breadcrumbs aria-label="breadcrumb" separator="/">
                <Link to="#">Admin</Link>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: "Calc(100vh - 80px)",
              overflowY: "auto",
              // pt: 2.5,
              px: 2,
              boxSizing: "border-box",
              // background: "green",
            }}
          >
            <Box sx={{ mt: 4 }}>
              {" "}
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs="auto">
                  <Grid
                    sx={{
                      background: "#FAFAFA",
                      py: 0.5,
                      borderRadius: "6px",
                    }}
                  >
                    <Typography
                      variant="medium"
                      color="text.fade"
                      sx={{
                        fontWeight: 700,
                        px: 1.5,
                        borderRight: "1px solid #DBDADE",
                        display: "inline-flex",
                      }}
                    >
                      Cart ID: &nbsp;&nbsp;
                      <span style={{ color: "#222", fontWeight: 400 }}>
                        {list?.customCartId}
                      </span>
                    </Typography>
                    <Typography
                      variant="medium"
                      color="text.fade"
                      sx={{
                        fontWeight: 700,
                        px: 1.5,
                        borderRight: "1px solid #DBDADE",
                        display: "inline-flex",
                      }}
                    >
                      Date:&nbsp;&nbsp;
                      <span style={{ color: "#222", fontWeight: 400 }}>
                        {moment(list.submittedAt).format("MMM DD YYYY")}
                      </span>
                    </Typography>
                    <Typography
                      variant="medium"
                      color="text.fade"
                      sx={{
                        fontWeight: 700,
                        px: 1.5,
                        borderRight: "1px solid #DBDADE",
                        display: "inline-flex",
                      }}
                    >
                      Time:&nbsp;&nbsp;
                      <span style={{ color: "#222", fontWeight: 400 }}>
                        {moment(list.submittedAt).format("hh:mm")}
                      </span>
                    </Typography>
                    <Typography
                      variant="medium"
                      color="text.fade"
                      sx={{
                        fontWeight: 700,
                        px: 1.5,
                        // borderRight: "1px solid #DBDADE",
                        display: "inline-flex",
                      }}
                    >
                      Approved Status:&nbsp;&nbsp;
                      <span style={{ color: "#222", fontWeight: 400 }}>
                        {list?.approveType}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Box
                sx={{
                  width: "Calc(100vw - 320px)",
                  overflow: "hidden",
                  px: 2,
                  boxSizing: "border-box",
                }}
              >
                <TableContainer
                  style={{
                    maxHeight: "Calc(100vh - 210px)",

                    // background: "red",
                  }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Item Id
                        </TableCell>
                        <TableCell>Img</TableCell>
                        <TableCell>Link</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Atl. Link
                        </TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          weight(KG)
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Quantity
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Purchase Status
                        </TableCell>

                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Purchased Via
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Order # from seller
                        </TableCell>

                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Tracking Number
                        </TableCell>

                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Warehouse Number
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Shipment Number
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Shipped Via
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Original Purchase Price
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Cost of Goods $
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Postage $
                        </TableCell>
                        {/* <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Customs
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Service & Insurance
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Shipping
                        </TableCell> */}
                        {/* <TableCell sx={{ whiteSpace: "nowrap" }}>
                          SOLD Value $
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          SOLD Value IQD
                        </TableCell> */}
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Sold Value
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Sold Value IQD
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Deposit $
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Deposit IQD
                        </TableCell>
                        {/* <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Remaining Value $
                        </TableCell> */}
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Remaining Value IQD
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Deposit Payment Method
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Item Tracking Status
                        </TableCell>

                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Cancel Reason
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!loading &&
                        shoppingCartList?.map((item, index) => (
                          <TableRow key={item.id}>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              {item.customItemId}
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              {item.product?.image === null ? (
                                <img
                                  src="/no_image.png"
                                  alt=""
                                  style={{
                                    maxWidth: "90px",
                                    maxHeight: "120px",
                                  }}
                                />
                              ) : (
                                <Tooltip
                                  slotProps={{
                                    tooltip: {
                                      sx: {
                                        // color: "#514E6A",
                                        maxWidth: 500,
                                        backgroundColor: "#fff",
                                        boxShadow:
                                          "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                        p: 3,
                                      },
                                    },
                                  }}
                                  title={
                                    <div>
                                      <img
                                        src={item.product?.image}
                                        alt=""
                                        style={{
                                          maxWidth: "350px",
                                          maxHeight: "450px",
                                        }}
                                      />
                                    </div>
                                  }
                                  placement="right"
                                >
                                  <img
                                    src={item.product?.image}
                                    alt=""
                                    style={{
                                      maxWidth: "90px",
                                      maxHeight: "120px",
                                    }}
                                  />
                                </Tooltip>
                              )}{" "}
                            </TableCell>
                            <TableCell
                              sx={{ color: "#3669C9", whiteSpace: "nowrap" }}
                            >
                              <a href={item.product?.url} target="_blank">
                                {item.product?.websiteName} Url
                              </a>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              {orderType === "Live" ? (
                                <TextField
                                  id={`altLink${index}`}
                                  variant="standard"
                                  sx={{
                                    backgroundColor: "#FFFAE7",
                                    px: 1,
                                    width: "100px",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    // inputProps: {
                                    //   min: 0,
                                    //   maxLength: 100,
                                    //   step: 1,
                                    // },
                                  }}
                                  // type="number"
                                  size="small"
                                  value={item.shipmentItemDetails?.altLink}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      "altLink",
                                      item,
                                      index
                                    )
                                  }

                                  // disabled={item.itemStatus?.statusType === "R_N"}
                                />
                              ) : (
                                <a
                                  href={item.shipmentItemDetails?.altLink}
                                  target="_blank"
                                >
                                  Atl. Link Url
                                </a>
                              )}
                            </TableCell>
                            <TableCell>
                              {" "}
                              {orderType === "Live" ? (
                                <FormControl
                                  id={`productCategory${index}`}
                                  size="small"
                                  sx={{
                                    mb: 2,
                                    mt: 2,
                                    // ml: 2,
                                    ...customeSelect,
                                    ".MuiInputLabel-root": {
                                      color: "#bfbfbf",
                                    },
                                    width: 120,
                                  }}
                                >
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // sx={{
                                    //   ...customeSelect,
                                    // }}

                                    value={
                                      item.shipmentItemDetails?.productCategory
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        e.target.value,
                                        "productCategory",
                                        item,
                                        index
                                      )
                                    }
                                  >
                                    {categoryList?.map((item, i) => (
                                      <MenuItem value={item} key={i}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                item.shipmentItemDetails?.productCategory
                              )}
                            </TableCell>
                            <TableCell>
                              {" "}
                              <Descreption detail={item.product} />{" "}
                            </TableCell>
                            <TableCell>{item.product?.weight}</TableCell>
                            <TableCell>{item.product?.quantity}</TableCell>
                            <TableCell>
                              {orderType === "Live" ? (
                                <FormControl
                                  id={`purchasedStatus${index}`}
                                  size="small"
                                  sx={{
                                    mb: 2,
                                    mt: 2,
                                    // ml: 2,
                                    ...customeSelect,
                                    ".MuiInputLabel-root": {
                                      color: "#bfbfbf",
                                    },
                                    width: 120,
                                  }}
                                >
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // sx={{
                                    //   ...customeSelect,
                                    // }}

                                    value={
                                      item.shipmentItemDetails?.purchasedStatus
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        e.target.value,
                                        "purchasedStatus",
                                        item,
                                        index
                                      )
                                    }
                                  >
                                    <MenuItem value="PENDING">Pending</MenuItem>
                                    <MenuItem value="PURCHASED">
                                      Purchased
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              ) : (
                                item.shipmentItemDetails?.purchasedStatus
                              )}
                            </TableCell>
                            <TableCell>
                              {orderType === "Live" ? (
                                <TextField
                                  id={`purchasedVia${index}`}
                                  variant="standard"
                                  sx={{
                                    backgroundColor: "#FFFAE7",
                                    px: 1,
                                    width: "100px",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    // inputProps: {
                                    //   min: 0,
                                    //   maxLength: 100,
                                    //   step: 1,
                                    // },
                                  }}
                                  // type="number"
                                  size="small"
                                  value={item.shipmentItemDetails?.purchasedVia}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      "purchasedVia",
                                      item,
                                      index
                                    )
                                  }

                                  // disabled={item.itemStatus?.statusType === "R_N"}
                                />
                              ) : (
                                item.shipmentItemDetails?.purchasedVia
                              )}
                            </TableCell>
                            <TableCell>
                              {orderType === "Live" ? (
                                <TextField
                                  id={`orderNoFromSeller${index}`}
                                  variant="standard"
                                  sx={{
                                    backgroundColor: "#FFFAE7",
                                    px: 1,
                                    width: "100px",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    // inputProps: {
                                    //   min: 0,
                                    //   maxLength: 100,
                                    //   step: 1,
                                    // },
                                  }}
                                  // type="number"
                                  size="small"
                                  value={
                                    item.shipmentItemDetails?.orderNoFromSeller
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      "orderNoFromSeller",
                                      item,
                                      index
                                    )
                                  }

                                  // disabled={item.itemStatus?.statusType === "R_N"}
                                />
                              ) : (
                                item.shipmentItemDetails?.orderNoFromSeller
                              )}
                            </TableCell>
                            <TableCell>
                              {orderType === "Live" ? (
                                <TextField
                                  id={`trackingNumber${index}`}
                                  variant="standard"
                                  sx={{
                                    backgroundColor: "#FFFAE7",
                                    px: 1,
                                    width: "100px",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    // inputProps: {
                                    //   min: 0,
                                    //   maxLength: 100,
                                    //   step: 1,
                                    // },
                                  }}
                                  // type="number"
                                  size="small"
                                  value={
                                    item.shipmentItemDetails?.trackingNumber
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      "trackingNumber",
                                      item,
                                      index
                                    )
                                  }

                                  // disabled={item.itemStatus?.statusType === "R_N"}
                                />
                              ) : (
                                item.shipmentItemDetails?.trackingNumber
                              )}
                            </TableCell>
                            <TableCell>
                              {orderType === "Live" ? (
                                <TextField
                                  id={`warehouseNumber${index}`}
                                  variant="standard"
                                  sx={{
                                    backgroundColor: "#FFFAE7",
                                    px: 1,
                                    width: "100px",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    // inputProps: {
                                    //   min: 0,
                                    //   maxLength: 100,
                                    //   step: 1,
                                    // },
                                  }}
                                  // type="number"
                                  size="small"
                                  value={
                                    item.shipmentItemDetails?.warehouseNumber
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      "warehouseNumber",
                                      item,
                                      index
                                    )
                                  }

                                  // disabled={item.itemStatus?.statusType === "R_N"}
                                />
                              ) : (
                                item.shipmentItemDetails?.warehouseNumber
                              )}
                            </TableCell>
                            <TableCell>
                              {orderType === "Live" ? (
                                <TextField
                                  id={`shipmentNumber${index}`}
                                  variant="standard"
                                  sx={{
                                    backgroundColor: "#FFFAE7",
                                    px: 1,
                                    width: "100px",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    // inputProps: {
                                    //   min: 0,
                                    //   maxLength: 100,
                                    //   step: 1,
                                    // },
                                  }}
                                  // type="number"
                                  size="small"
                                  value={
                                    item.shipmentItemDetails?.shipmentNumber
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      "shipmentNumber",
                                      item,
                                      index
                                    )
                                  }

                                  // disabled={item.itemStatus?.statusType === "R_N"}
                                />
                              ) : (
                                item.shipmentItemDetails?.shipmentNumber
                              )}
                            </TableCell>
                            <TableCell>
                              {orderType === "Live" ? (
                                <TextField
                                  id={`shippedBy${index}`}
                                  variant="standard"
                                  sx={{
                                    backgroundColor: "#FFFAE7",
                                    px: 1,
                                    width: "100px",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    // inputProps: {
                                    //   min: 0,
                                    //   maxLength: 100,
                                    //   step: 1,
                                    // },
                                  }}
                                  // type="number"
                                  size="small"
                                  value={item.shipmentItemDetails?.shippedBy}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      "shippedBy",
                                      item,
                                      index
                                    )
                                  }

                                  // disabled={item.itemStatus?.statusType === "R_N"}
                                />
                              ) : (
                                item.shipmentItemDetails?.shippedBy
                              )}
                            </TableCell>
                            <TableCell>
                              {orderType === "Live" ? (
                                <TextField
                                  id={`originalPurchasedPrice${index}`}
                                  variant="standard"
                                  sx={{
                                    backgroundColor: "#FFFAE7",
                                    px: 1,
                                    width: "100px",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    // inputProps: {
                                    //   min: 0,
                                    //   maxLength: 100,
                                    //   step: 1,
                                    // },
                                  }}
                                  type="number"
                                  size="small"
                                  value={
                                    item.shipmentItemDetails
                                      ?.originalPurchasedPrice
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      "originalPurchasedPrice",
                                      item,
                                      index
                                    )
                                  }

                                  // disabled={item.itemStatus?.statusType === "R_N"}
                                />
                              ) : (
                                item.shipmentItemDetails?.originalPurchasedPrice.toFixed(
                                  2
                                )
                              )}
                            </TableCell>
                            <TableCell>
                              {orderType === "Live" ? (
                                <TextField
                                  id={`price${index}`}
                                  variant="standard"
                                  sx={{
                                    backgroundColor: "#FFFAE7",
                                    px: 1,
                                    width: "100px",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    // inputProps: {
                                    //   min: 0,
                                    //   maxLength: 100,
                                    //   step: 1,
                                    // },
                                  }}
                                  type="number"
                                  size="small"
                                  value={item.product?.price}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      "price",
                                      item,
                                      index
                                    )
                                  }
                                  disabled={list?.approveType === "APPROVE"}
                                />
                              ) : (
                                item.product?.price
                              )}
                            </TableCell>
                            <TableCell>
                              {orderType === "Live" ? (
                                <TextField
                                  id={`postage${index}`}
                                  variant="standard"
                                  sx={{
                                    backgroundColor: "#FFFAE7",
                                    px: 1,
                                    width: "100px",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    // inputProps: {
                                    //   min: 0,
                                    //   maxLength: 100,
                                    //   step: 1,
                                    // },
                                  }}
                                  // type="number"
                                  size="small"
                                  value={item?.postage}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      "postage",
                                      item,
                                      index
                                    )
                                  }
                                  disabled={list?.approveType === "APPROVE"}
                                />
                              ) : (
                                item?.postage
                              )}
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              {item.soldValue.toFixed(2)}
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              {item.soldValueInIQD.toFixed(2)}
                            </TableCell>
                            {/* {index === 0 && (
                              <TableCell rowSpan={shoppingCartList?.length}>
                                {list.invoice?.customsVAT}
                              </TableCell>
                            )}
                            {index === 0 && (
                              <TableCell rowSpan={shoppingCartList?.length}>
                                {list.invoice?.service}
                              </TableCell>
                            )}
                            {index === 0 && (
                              <TableCell rowSpan={shoppingCartList?.length}>
                                {list.invoice?.shipping}
                              </TableCell>
                            )} */}
                            {/* <TableCell>
                              {item?.soldValue?.toFixed(2)}
                              soldValue
                            </TableCell>
                            <TableCell>
                              {item?.soldValueInIQD.toFixed(2)}
                              soldValueInIQD
                            </TableCell> */}

                            {index === 0 && (
                              <TableCell rowSpan={shoppingCartList?.length}>
                                {list.invoice?.deposit}
                              </TableCell>
                            )}
                            {index === 0 && (
                              <TableCell rowSpan={shoppingCartList?.length}>
                                {list.invoice?.depositInIQD}
                              </TableCell>
                            )}
                            {/* <TableCell>
                        -----
                            </TableCell> */}

                            {index === 0 && (
                              <TableCell rowSpan={shoppingCartList?.length}>
                                {list?.invoice?.totalInIQD -
                                  list?.invoice?.paidAmount}
                              </TableCell>
                            )}

                            <TableCell>
                              {list?.invoice?.depositPaymentMethod}
                            </TableCell>

                            <TableCell align="left">
                              {orderType === "Live" ? (
                                <FormControl
                                  id={`itemTrackingStatus${index}`}
                                  size="small"
                                  sx={{
                                    mb: 2,
                                    mt: 2,
                                    // ml: 2,
                                    ...customeSelect,
                                    ".MuiInputLabel-root": {
                                      color: "#bfbfbf",
                                    },
                                    width: 160,
                                  }}
                                >
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // sx={{
                                    //   ...customeSelect,
                                    // }}

                                    value={
                                      item.shipmentItemDetails
                                        ?.itemTrackingStatus
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        e.target.value,
                                        "itemTrackingStatus",
                                        item,
                                        index
                                      )
                                    }
                                  >
                                    {itemTrackingStatusData.map((item) => (
                                      <MenuItem value={item}>
                                        {convertToTitleCase(item)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                item.shipmentItemDetails?.itemTrackingStatus
                              )}
                            </TableCell>
                            <TableCell>
                              {fnCancelReason(item, index)}

                              {/* <Button
                                disableElevation
                                variant="contained"
                                color="error"
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: 700,
                                }}
                              >
                                Cancel Item
                              </Button> */}
                            </TableCell>
                          </TableRow>
                        ))}
                      {loading && pageLoading()}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {orderType === "Live" && (
                <Box sx={{ textAlign: "right", mt: 2, pr: 4 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    disableElevation
                    sx={{
                      ...buttonStyle,
                      py: 1,
                      minHeight: "41px",
                      // minWidth: "190px",
                    }}
                    disabled={updateLoading}
                    onClick={() => setCancelDialog(true)}
                  >
                    Cancel Entire Order
                  </Button>{" "}
                  {/* &nbsp;&nbsp;
                  <Button
                    variant="outlined"
                    color="success"
                    disableElevation
                    sx={{
                      ...buttonStyle,
                      py: 1,
                      minHeight: "41px",
                      // minWidth: "190px",
                    }}
                    disabled={updateLoading}
                    onClick={() => setApproveCartDialog(true)}
                  >
                    Approve Entire Order
                  </Button>{" "} */}
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="success"
                    disableElevation
                    sx={{
                      ...buttonStyle,
                      py: 1,
                      minHeight: "41px",
                      minWidth: "220px",
                    }}
                    disabled={updateLoading}
                    // type="submit"
                    // startIcon={<DoneAllIcon />}
                    onClick={() => postData()}
                  >
                    {!updateLoading && "Confirm"}
                    <PulseLoader
                      color={"#353b48"}
                      loading={updateLoading}
                      size={10}
                      speedMultiplier={0.5}
                    />{" "}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={cancelDialog}
        onClose={handleCloseCancelDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 4, minWidth: "500px" }}>
          <div style={{ position: "relative", margin: "15px 0px 32px" }}>
            <IconButton
              style={{ position: "absolute", right: 0, top: -24 }}
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseCancelDialog();
              }}
            >
              <ClearOutlinedIcon />
            </IconButton>

            <Typography
              sx={{
                fontWeight: 600,
                color: "#222222",
                fontFamily: "'Poppins', sans-serif",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Cancel Entire Order
            </Typography>
          </div>

          <form onSubmit={handleCancelSubmit}>
            <Box sx={{ mb: 1.5 }}>
              <Typography sx={{ fontWeight: 600 }}> Reason</Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"
                multiline
                rows={3}
                variant="outlined"
                id="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />{" "}
              {errors?.reason && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.reason.toString()}
                </Typography>
              )}
            </Box>

            <Box sx={{ mt: 4, textAlign: "center" }}>
              <Button
                variant="contained"
                color="error"
                disableElevation
                sx={{ minHeight: "50px", minWidth: "180px" }}
                disabled={cancelCartLoading}
                type="submit"
                // startIcon={<DoneAllIcon />}
                // onClick={() => handleUpdateStatus("VERIFIED")}
              >
                {/* {updateStatusValue !== "VERIFIED" && "Submit"} */}
                Confirm
                <PulseLoader
                  color={"#353b48"}
                  loading={cancelCartLoading}
                  size={10}
                  speedMultiplier={0.5}
                />{" "}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={approveCartDialog}
        onClose={handleApproveCartDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            minWidth: "450px",
            position: "relative",
            px: 4,
            pt: 5,
            textAlign: "center",
          }}
        >
          Are you sure to Approve the entire cart?
        </DialogTitle>
        <DialogContent></DialogContent>

        <DialogActions sx={{ justifyContent: "center", px: 4, pb: 5 }}>
          <Button
            variant="outlined"
            color="text"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            startIcon={<ClearIcon />}
            onClick={handleApproveCartDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            disabled={approveCartloading}
            startIcon={<DoneAllIcon />}
            onClick={handleApproveEntireCart}
          >
            {!approveCartloading && "Approve"}
            <PulseLoader
              color={"#353b48"}
              loading={approveCartloading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderDetails;
