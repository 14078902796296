import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import MenuIcon from "../icons/MenuIcon";
import { useTheme } from "@mui/material/styles";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Chip from "@mui/material/Chip";
import {
  Avatar,
  Button,
  IconButton,
  Paper,
  Skeleton,
  TextField,
} from "@mui/material";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Collapse from "@mui/material/Collapse";
import { getDataWithToken } from "../../services/GetDataService";
import { AuthContext } from "../../context/AuthContext";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RefreshToken from "../../services/RefreshToken";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { handlePostData } from "../../services/PostDataService";
import PulseLoader from "react-spinners/PulseLoader";
import { handlePutRowData } from "../../services/PutRowDataService";
import { handlePutData } from "../../services/PutDataService";

const ActivityLog = () => {
  const theme = useTheme();
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [phoneNo, setPhoneNo] = useState("");
  const [name, setName] = useState("");
  const [userIP, setUserIP] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [gender, setGender] = useState("");
  const [status, setStatus] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [message, setMessage] = useState("");

  const [createdStartTime, setCreatedStartTime] = useState(null);
  const [createdEndTime, setCreatedEndTime] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [totalElements, setTotalElements] = useState(0);
  const [codStatusUpdateOpen, setCodStatusUpdateOpen] = useState(false);
  const [newUpdateValue, setNewUpdateValue] = useState({});
  const [selectedForUpdate, setSelectedForUpdate] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateStatusValue, setUpdateStatusValue] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [sortType, setSortType] = useState("RECENT");
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const handleCodStatusUpdateClickOpen = (index, row) => {
    setCodStatusUpdateOpen(true);
  };

  const handleCodStatusUpdateClose = () => {
    setCodStatusUpdateOpen(false);
    setUpdateStatusValue("");
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    getData(0, event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    // let pageNo = newPage + 1;
    console.log("newPage", newPage);
    getData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };
  const pageLoading = () => {
    let rows = [];

    for (let i = 0; i < 15; i++) {
      let cells = [];

      for (let j = 0; j < 5; j++) {
        cells.push(
          <TableCell key={j} sx={{ py: 1.5 }}>
            <Skeleton></Skeleton>
          </TableCell>
        );
      }

      rows.push(<TableRow key={i}>{cells}</TableRow>);
    }

    return rows;
  };

  const handleUserName = (e) => {
    setName(e.target.value);
    getData(0, "", "", "", e.target.value);
  };
  const handleUserIP = (e) => {
    setUserIP(e.target.value);
    getData(0, "", "", "", null, e.target.value);
  };
  const handleFromDate = (date) => {
    console.log("date", date);
    setFromDate(date);
    getData(0, "", "", "", null, null, date === null ? dayjs() : date);
  };
  const handleToDate = (date) => {
    console.log(date);
    setToDate(date);
    getData(0, "", "", "", null, null, null, date === null ? dayjs() : date);
  };

  const getData = async (
    pageNO,
    pageLimit,
    newUrl,
    sortTypeValue,
    userName,
    pUserIP,
    pFromDate,
    pToDate
  ) => {
    setLoading(true);
    setMessage("");
    let newPageNO;
    let newLimit;
    let newSortTypeValue;
    let newUserName;
    let newUserIP;
    let newFromDate = dayjs().format("YYYY-MM-DD");
    let newToDate = dayjs().format("YYYY-MM-DD");
    let url;
    console.log("userName", userName);
    console.log("pUserIP", pUserIP);
    if (pFromDate) {
      newFromDate = dayjs(pFromDate).format("YYYY-MM-DD");
    } else {
      newFromDate =
        fromDate !== null ? dayjs(fromDate).format("YYYY-MM-DD") : fromDate;
    }
    if (pToDate) {
      newToDate = dayjs(pToDate).format("YYYY-MM-DD");
    } else {
      newToDate = toDate !== null ? dayjs(toDate).format("YYYY-MM-DD") : toDate;
    }

    if (userName?.length >= 0) {
      newUserName = userName;
    } else {
      newUserName = name;
    }
    if (pUserIP?.length >= 0) {
      newUserIP = pUserIP;
    } else {
      newUserIP = userIP;
    }
    if (sortTypeValue) {
      newSortTypeValue = sortTypeValue;
    } else {
      newSortTypeValue = sortType;
    }
    // let url;
    if (pageNO) {
      newPageNO = pageNO - 1;
    } else {
      newPageNO = 0;
    }
    if (pageLimit) {
      newLimit = pageLimit;
    } else {
      newLimit = limit;
    }

    url = `/api/v1/private/admin/auditlogs?size=${newLimit}&userIP=${newUserIP.trim()}&username=${newUserName.trim()}&page=${parseInt(
      newPageNO
    )}`;

    if (newFromDate) {
      url = url.concat(`&fromDate=${newFromDate}`);
    }
    if (newToDate) {
      url = url.concat(`&toDate=${newToDate}`);
    }

    let token = await RefreshToken(dizli_admin_panel, logout, login);
    // let token = dizli_admin_panel.access_token;
    let res = await getDataWithToken(url, token);
    // if (res?.status === 401 || res?.status === 403) {
    //   logout();
    //   return;
    // }
    console.log("res.data.data --------------", res);
    if (res?.status > 199 && res?.status < 300) {
      setTotalElements(res.data?.totalElements);
      setTotalPage(res?.data?.totalPages);
      // setRowsPerPage(res?.data?.data.per_page);

      if (res?.data?.content.length > 0) {
        setList(res.data.content);
      } else {
        setMessage("No data found");
        setList([]);
      }
    } else {
      setMessage(res.data.message);
    }
    setLoading(false);
  };
  const handleUpdateStatus = async (updateStatus) => {
    // try {
    setUpdateLoading(true);
    setUpdateStatusValue(updateStatus);
    let formData = new FormData();
    formData.append("phoneNumber", selectedForUpdate.phoneNumber);
    formData.append("documentStatus", updateStatus);

    let token = await RefreshToken(dizli_admin_panel, logout, login);
    let response = await handlePutData(
      `/api/v1/private/user/update/cod-status`,
      formData,
      token
    );
    // await axios({
    //   url: `api/update-user-status`,
    //   method: "post",
    //   data: data,
    //   headers: {
    //     Authorization: `Bearer ${fastpay_support_panel_user.token}`,
    //   },
    // });

    if (response.status === 200) {
      let newUserObj = { ...selectedForUpdate, codStatus: updateStatus };
      list[newUpdateValue.index] = newUserObj;
      handleSnakbarOpen("Update successfully", "success");
      handleCodStatusUpdateClose();
    } else {
      handleSnakbarOpen(response.data.messages.toString(), "error");
    }
    // } catch (error) {
    //   console.log("error", error);

    //   handleSnakbarOpen(error.response.data.messages.toString(), "error");
    // }
    setUpdateLoading(false);
  };
  useEffect(() => {
    // setLoading(true);
    getData();
  }, []);
  return (
    <div>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100vh" }}
      >
        <Grid item sx={{ width: "100%" }}>
          {" "}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 2.125, borderBottom: "1px solid #E5E5E5" }}
          >
            <Grid item xs="auto">
              <Typography
                variant="h6"
                color="text.light"
                sx={{ fontWeight: 600 }}
              >
                Activity Log{" "}
                <span style={{ fontWeight: 400 }}>({totalElements})</span>
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator="/"
                // className={classes.breadcrumbsStyle}
              >
                <Link to="#">Activity Log</Link>

                {/* <Link to="#">Confirmed Carts</Link> */}
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: "Calc(100vh - 135px)",
              // background: "red",
              overflowY: "auto",
              // pt: 2.5,
              px: 2,
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs="auto">
                <TextField
                  // label="With normal TextField"
                  placeholder="Phone No / Email"
                  id="outlined-start-adornment"
                  size="small"
                  sx={{ mb: 2, mt: 2, ml: 2, width: "18ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00033 8.00004C9.84127 8.00004 11.3337 6.50766 11.3337 4.66671C11.3337 2.82576 9.84127 1.33337 8.00033 1.33337C6.15938 1.33337 4.66699 2.82576 4.66699 4.66671C4.66699 6.50766 6.15938 8.00004 8.00033 8.00004Z"
                            stroke="#969696"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                            stroke="#969696"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </InputAdornment>
                    ),
                  }}
                  value={name}
                  onChange={handleUserName}
                />
                <TextField
                  // label="With normal TextField"
                  placeholder="Search User IP"
                  id="outlined-start-adornment"
                  size="small"
                  sx={{ mb: 2, mt: 2, ml: 2, width: "18ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00033 8.00004C9.84127 8.00004 11.3337 6.50766 11.3337 4.66671C11.3337 2.82576 9.84127 1.33337 8.00033 1.33337C6.15938 1.33337 4.66699 2.82576 4.66699 4.66671C4.66699 6.50766 6.15938 8.00004 8.00033 8.00004Z"
                            stroke="#969696"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                            stroke="#969696"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </InputAdornment>
                    ),
                  }}
                  value={userIP}
                  onChange={handleUserIP}
                />
                <Box
                  sx={{
                    mb: 2,
                    mt: 2,
                    ml: 2,
                    width: "18ch",
                    display: "inline-flex",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        ".MuiInputLabel-root": {
                          color: "#bfbfbf",
                        },
                      }}
                      slotProps={{
                        field: {
                          clearable: true,
                          onClear: () => handleFromDate(null),
                        },
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                      renderInput={(props) => <TextField {...props} />}
                      label="From Date"
                      maxDate={dayjs()}
                      value={fromDate}
                      onChange={(newValue) => {
                        handleFromDate(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  sx={{
                    mb: 2,
                    mt: 2,
                    ml: 2,
                    width: "18ch",
                    display: "inline-flex",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        ".MuiInputLabel-root": {
                          color: "#bfbfbf",
                        },
                      }}
                      slotProps={{
                        field: {
                          clearable: true,
                          onClear: () => handleToDate(null),
                        },
                        textField: { size: "small", fullWidth: true },
                      }}
                      renderInput={(props) => <TextField {...props} />}
                      label="To Date"
                      maxDate={dayjs()}
                      minDate={fromDate}
                      value={toDate}
                      onChange={(newValue) => {
                        handleToDate(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs="auto"></Grid>
            </Grid>
            <Box
              sx={{
                width: "100%",
                overflow: "auto",
                px: 2,
              }}
            >
              <TableContainer
                style={{
                  maxHeight: "Calc(100vh - 210px)",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell sx={{ whiteSpace: "nowrap" }}>User ID</TableCell> */}
                      {/* <TableCell sx={{ whiteSpace: "nowrap" }}>
                      Sign up Date
                    </TableCell> */}
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Module
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Phone Number / Email
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: "nowrap", minWidth: "320px" }}
                      >
                        Activity Details
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        User IP
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>Date</TableCell>

                      {/* <TableCell sx={{ whiteSpace: "nowrap" }} align="right">
                      Card Details
                    </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading &&
                      list?.length > 0 &&
                      list?.map((row, i) => (
                        <TableRow
                        // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {row?.className}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {row?.username}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {row?.activityDetails}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {row?.userIP}
                          </TableCell>
                          <TableCell>
                            {row?.activityDate !== null
                              ? moment(row?.activityDate).format("MMM DD, YYYY")
                              : "-------"}
                          </TableCell>

                          {/* <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {row?.deposit.toFixed(2)}
                        </TableCell> */}

                          {/* <TableCell
                          sx={{ whiteSpace: "nowrap", p: 0 }}
                          align="right"
                        >
                          <Button
                            disableElevation
                            variant="contained"
                            color="warning"
                            component={Link}
                            to={`/cart-details/${row?.cartId}`}
                            sx={{
                              background: "#EFF3FF",
                              color: theme.palette.text.light,
                              fontSize: "12px",
                              fontWeight: 700,
                              "&:hover": {
                                background: "#CDD9FF",
                              },
                            }}
                          >
                            View
                          </Button>
                        </TableCell> */}
                        </TableRow>
                      ))}

                    {loading && pageLoading()}
                  </TableBody>
                </Table>
              </TableContainer>
              {!loading && list?.length < 1 ? (
                <Box sx={{ textAlign: "center", p: 2 }}>
                  <strong> No Data Found</strong>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs="auto"
          sx={{
            px: 2,
            py: 1.75,
            borderTop: "1px solid #E5E5E5",
            width: "100%",
          }}
        >
          {/* {list?.length > 0 && ( */}
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs="auto">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs="auto">
                  <Typography
                    variant="medium"
                    color="text.light"
                    // sx={{ display: "inline-flex" }}
                  >
                    Showing&nbsp; &nbsp;
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box>
                    <FormControl size="small">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={limit}
                        onChange={handleLimitChange}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                    &nbsp; &nbsp;
                  </Box>
                </Grid>
                {/* <Grid item xs="auto">
                  <Typography
                    variant="medium"
                    color="text.light"
                 
                  >
                    of 100
                  </Typography>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs="auto">
              {" "}
              <Pagination
                sx={{ display: "inline-block" }}
                count={totalPage}
                showFirstButton
                showLastButton
                shape="rounded"
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>
          {/* )} */}
        </Grid>
      </Grid>
      <Dialog
        open={codStatusUpdateOpen}
        onClose={handleCodStatusUpdateClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            minWidth: "450px",
            position: "relative",
            px: 4,
            pt: 5,
            textAlign: "center",
          }}
        >
          <b>{"COD Status Update"}</b>
          <IconButton
            onClick={handleCodStatusUpdateClose}
            style={{ position: "absolute", right: 24, top: 10 }}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 4 }}>
          <Typography>
            {" "}
            <b>Name : </b> &nbsp; {selectedForUpdate?.firstName}{" "}
            {selectedForUpdate?.lastName}
          </Typography>
          <Typography>
            {" "}
            <b>Email : </b> &nbsp; {selectedForUpdate?.email}
          </Typography>
          <Typography>
            {" "}
            <b>Number : </b> &nbsp; {selectedForUpdate?.phoneNumber}
          </Typography>
          <img
            src={selectedForUpdate?.doc}
            style={{
              width: "100%",
              // maxWidth: "350px",
              maxHeight: "400px",
              marginTop: "12px",
            }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", px: 4, pb: 5 }}>
          <Button
            variant="outlined"
            color="error"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            disabled={updateStatusValue === "REJECTED"}
            startIcon={<ClearIcon />}
            onClick={() => handleUpdateStatus("REJECTED")}
          >
            {updateStatusValue !== "REJECTED" && "REJECTED"}
            <PulseLoader
              color={"#353b48"}
              loading={updateStatusValue === "REJECTED"}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
          <Button
            variant="contained"
            color="success"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            disabled={updateStatusValue === "VERIFIED"}
            startIcon={<DoneAllIcon />}
            onClick={() => handleUpdateStatus("VERIFIED")}
          >
            {updateStatusValue !== "VERIFIED" && "VERIFIED"}
            <PulseLoader
              color={"#353b48"}
              loading={updateStatusValue === "VERIFIED"}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
          {/* <Button
             onClick={()=>handleUpdateStatus("REJECTED")}
            variant="outlined"
            color="error"
            startIcon={<ClearIcon />}
          >
            REJECTED
          </Button>
          <Button
            disableElevation
            onClick={()=>handleUpdateStatus("VERIFIED")}
            variant="contained"
            color="success"
            startIcon={<DoneAllIcon />}
          >
            VERIFIED
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActivityLog;
