import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import MenuIcon from "../icons/MenuIcon";
import { useTheme } from "@mui/material/styles";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Chip from "@mui/material/Chip";
import {
  Avatar,
  Button,
  IconButton,
  Paper,
  Skeleton,
  TextField,
} from "@mui/material";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Collapse from "@mui/material/Collapse";
import { getDataWithToken } from "../../../services/GetDataService";
import { AuthContext } from "../../../context/AuthContext";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RefreshToken from "../../../services/RefreshToken";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import PulseLoader from "react-spinners/PulseLoader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { handlePutData } from "../../../services/PutDataService";
import { handlePutRowData } from "../../../services/PutRowDataService";

const ConfirmedCarts = () => {
  const theme = useTheme();
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [gender, setGender] = useState("");
  const [status, setStatus] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [message, setMessage] = useState("");

  const [createdStartTime, setCreatedStartTime] = useState(null);
  const [createdEndTime, setCreatedEndTime] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [totalElements, setTotalElements] = useState(0);
  const [newUpdateValue, setNewUpdateValue] = useState({});
  const [selectedForUpdate, setSelectedForUpdate] = useState({});
  const [updateStatusValue, setUpdateStatusValue] = useState("");

  const [updateLoading, setUpdateLoading] = useState(false);
  const [statusUpdateOpen, setStatusUpdateOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [cartId, setCartId] = useState("");
  const [sortType, setSortType] = useState("RECENT");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [reviewStatus, setReviewStatus] = useState(null);

  const customeSelect = {
    background: "#fff",

    "& .MuiInputBase-input": {
      borderRadius: 50,
      position: "relative",
      // backgroundColor: theme.palette.background.paper,
      // border: "1px solid #ced4da",
      fontSize: 14,
      color: "#222",
      // padding: "6px 16px 6px 44px",
      // Use the system font instead of the default Roboto font.
    },
  };

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const handleSortTypeChange = (event) => {
    setSortType(event.target.value);
    getData(0, "", "", event.target.value);
  };

  const handleFromDate = (date) => {
    console.log("date", date);
    setFromDate(date);
    getData(0, "", "", "", null, null, date === null ? dayjs() : date);
  };
  const handleToDate = (date) => {
    console.log(date);
    setToDate(date);
    getData(0, "", "", "", null, null, null, date === null ? dayjs() : date);
  };
  const handleUserName = (e) => {
    setName(e.target.value);
    getData(0, "", "", "", e.target.value);
  };
  const handleCartId = (e) => {
    setCartId(e.target.value);
    getData(0, "", "", "", null, e.target.value);
  };

  const handleApprovalStatus = (e) => {
    setApprovalStatus(e.target.value);
    getData(0, "", "", "", null, null, null, null, e.target.value);
  };
  const handleReviewStatus = (e) => {
    setReviewStatus(e.target.value);
    getData(0, "", "", "", null, null, null, null, "", e.target.value);
  };
  const handleStatusUpdateClose = () => {
    setStatusUpdateOpen(false);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    getData(0, event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    // let pageNo = newPage + 1;
    console.log("newPage", newPage);
    getData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };
  const pageLoading = () => {
    let rows = [];

    for (let i = 0; i < 15; i++) {
      let cells = [];

      for (let j = 0; j < 9; j++) {
        cells.push(
          <TableCell key={j} sx={{ py: 1.5 }}>
            <Skeleton></Skeleton>
          </TableCell>
        );
      }

      rows.push(<TableRow key={i}>{cells}</TableRow>);
    }

    return rows;
  };

  const getData = async (
    pageNO,
    pageLimit,
    newUrl,
    sortTypeValue,
    userName,
    cartid,
    pFromDate,
    pToDate,
    pApprovalStatus,
    pReviewStatus
  ) => {
    setLoading(true);
    setMessage("");
    let newPageNO;
    let newLimit;
    let newSortTypeValue;
    let newUserName;
    let newCardid;
    let newFromDate = dayjs().format("YYYY-MM-DD");
    let newToDate = dayjs().format("YYYY-MM-DD");
    let newApprovalStatus;
    let newReviewStatus;

    // if (pFromDate !== null && pFromDate !== undefined) {
    //   newFromDate = dayjs(pFromDate).format("YYYY-MM-DD");
    // } else if (fromDate !== null) {
    //   newFromDate = dayjs(fromDate).format("YYYY-MM-DD");
    // }
    // if (pToDate !== null && pToDate !== undefined) {
    //   newToDate = dayjs(pToDate).format("YYYY-MM-DD");
    // } else if (toDate !== null) {
    //   newToDate = dayjs(toDate).format("YYYY-MM-DD");
    // }

    if (pFromDate) {
      newFromDate = dayjs(pFromDate).format("YYYY-MM-DD");
    } else {
      newFromDate =
        fromDate !== null ? dayjs(fromDate).format("YYYY-MM-DD") : fromDate;
    }
    if (pToDate) {
      newToDate = dayjs(pToDate).format("YYYY-MM-DD");
    } else {
      newToDate = toDate !== null ? dayjs(toDate).format("YYYY-MM-DD") : toDate;
    }
    if (pApprovalStatus) {
      newApprovalStatus = pApprovalStatus;
    } else {
      newApprovalStatus = approvalStatus;
    }
    console.log("pReviewStatus", pReviewStatus);
    if (pReviewStatus === true) {
      newReviewStatus = true;
    } else if (pReviewStatus === false) {
      newReviewStatus = false;
    } else if (pReviewStatus === "All") {
      newReviewStatus = "All";
    } else {
      newReviewStatus = reviewStatus;
    }

    if (userName?.length >= 0) {
      newUserName = userName;
    } else {
      newUserName = name;
    }
    if (cartid?.length >= 0) {
      newCardid = cartid;
    } else {
      newCardid = cartId;
    }
    if (sortTypeValue) {
      newSortTypeValue = sortTypeValue;
    } else {
      newSortTypeValue = sortType;
    }

    if (pageNO) {
      newPageNO = pageNO - 1;
    } else {
      newPageNO = 0;
    }
    if (pageLimit) {
      newLimit = pageLimit;
    } else {
      newLimit = limit;
    }
    let url;
    if (newReviewStatus === true || newReviewStatus === false) {
      url = `/api/v1/private/admin/confirmed-carts?size=${newLimit}&cartId=${newCardid.trim()}&clientName=${newUserName.trim()}&approveType=${
        newApprovalStatus === "All" ? "" : newApprovalStatus
      }&reviewStatus=${newReviewStatus}&sortType=${newSortTypeValue}&page=${parseInt(
        newPageNO
      )}`;
    } else {
      url = `/api/v1/private/admin/confirmed-carts?size=${newLimit}&cartId=${newCardid.trim()}&clientName=${newUserName.trim()}&approveType=${
        newApprovalStatus === "All" ? "" : newApprovalStatus
      }&sortType=${newSortTypeValue}&page=${parseInt(newPageNO)}`;
    }

    if (newFromDate) {
      url = url.concat(`&fromDate=${newFromDate}`);
    }
    if (newToDate) {
      url = url.concat(`&toDate=${newToDate}`);
    }
    console.log("dizli_admin_panel", dizli_admin_panel);

    let token = await RefreshToken(dizli_admin_panel, logout, login);

    console.log("token -----------------", token);
    // let token = dizli_admin_panel.access_token;
    let res = await getDataWithToken(url, token);
    console.log("res", res?.data?.data);
    // if (res?.status === 401 || res?.status === 403) {
    //   logout();
    //   return;
    // }

    if (res?.status > 199 && res?.status < 300) {
      setTotalElements(res.data.data.totalElements);
      setTotalPage(res.data.data.totalPages);
      setRowsPerPage(res.data.data.per_page);
      if (res.data.data.content.length > 0) {
        setList(res.data.data.content);
      } else {
        setMessage("No data found");
        setList([]);
      }
    } else {
      setMessage(res.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    // setLoading(true);
    getData();
  }, []);
  return (
    <div>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100vh" }}
      >
        <Grid item sx={{ width: "100%" }}>
          {" "}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 2.125, borderBottom: "1px solid #E5E5E5" }}
          >
            <Grid item xs="auto">
              <Typography
                variant="h6"
                color="text.light"
                sx={{ fontWeight: 600 }}
              >
                Confirmed Carts
                <span style={{ fontWeight: 400 }}>({totalElements})</span>
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator="/"
                // className={classes.breadcrumbsStyle}
              >
                {/* <Link to="#">Carts</Link> */}

                <Link to="#">Confirmed Carts</Link>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: "Calc(100vh - 135px)",
              // background: "red",
              overflowY: "auto",
              // pt: 2.5,
              px: 2,
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs="auto">
                <TextField
                  // label="With normal TextField"
                  placeholder="Search Cart ID"
                  id="outlined-start-adornment"
                  size="small"
                  sx={{ mb: 2, mt: 2, width: "18ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.66634 14C11.1641 14 13.9997 11.1645 13.9997 7.66671C13.9997 4.1689 11.1641 1.33337 7.66634 1.33337C4.16854 1.33337 1.33301 4.1689 1.33301 7.66671C1.33301 11.1645 4.16854 14 7.66634 14Z"
                            stroke="#969696"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.6663 14.6667L13.333 13.3334"
                            stroke="#969696"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </InputAdornment>
                    ),
                  }}
                  value={cartId}
                  onChange={handleCartId}
                />
                <TextField
                  // label="With normal TextField"
                  placeholder="Search Client Name"
                  id="outlined-start-adornment"
                  size="small"
                  sx={{ mb: 2, mt: 2, ml: 2, width: "18ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00033 8.00004C9.84127 8.00004 11.3337 6.50766 11.3337 4.66671C11.3337 2.82576 9.84127 1.33337 8.00033 1.33337C6.15938 1.33337 4.66699 2.82576 4.66699 4.66671C4.66699 6.50766 6.15938 8.00004 8.00033 8.00004Z"
                            stroke="#969696"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                            stroke="#969696"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </InputAdornment>
                    ),
                  }}
                  value={name}
                  onChange={handleUserName}
                />
                <Box
                  sx={{
                    mb: 2,
                    mt: 2,
                    ml: 2,
                    width: "18ch",
                    display: "inline-flex",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        ".MuiInputLabel-root": {
                          color: "#bfbfbf",
                        },
                      }}
                      slotProps={{
                        field: {
                          clearable: true,
                          onClear: () => handleFromDate(null),
                        },
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                      renderInput={(props) => <TextField {...props} />}
                      label="From Date"
                      maxDate={dayjs()}
                      value={fromDate}
                      onChange={(newValue) => {
                        handleFromDate(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  sx={{
                    mb: 2,
                    mt: 2,
                    ml: 2,
                    width: "18ch",
                    display: "inline-flex",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        ".MuiInputLabel-root": {
                          color: "#bfbfbf",
                        },
                      }}
                      slotProps={{
                        field: {
                          clearable: true,
                          onClear: () => handleToDate(null),
                        },
                        textField: { size: "small", fullWidth: true },
                      }}
                      renderInput={(props) => <TextField {...props} />}
                      label="To Date"
                      maxDate={dayjs()}
                      minDate={fromDate}
                      value={toDate}
                      onChange={(newValue) => {
                        handleToDate(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <FormControl
                  size="small"
                  sx={{
                    mb: 2,
                    mt: 2,
                    ml: 2,
                    ...customeSelect,
                    ".MuiInputLabel-root": {
                      color: "#bfbfbf",
                    },
                    width: 210,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Approval Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Approval Status"
                    // sx={{
                    //   ...customeSelect,
                    // }}
                    value={approvalStatus}
                    onChange={handleApprovalStatus}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="APPROVE">Approve</MenuItem>
                    <MenuItem value="APPROVE_EST">Approve EST</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  sx={{
                    mb: 2,
                    mt: 2,
                    ml: 2,
                    ...customeSelect,
                    ".MuiInputLabel-root": {
                      color: "#bfbfbf",
                    },
                    width: 200,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Review Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Review Status"
                    // sx={{
                    //   ...customeSelect,
                    // }}
                    value={reviewStatus}
                    onChange={handleReviewStatus}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value={true}>Reviewed</MenuItem>
                    <MenuItem value={false}>Not Reviewed</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  sx={{
                    mb: 2,
                    mt: 2,
                    ml: 2,
                    ...customeSelect,
                    ".MuiInputLabel-root": {
                      color: "#bfbfbf",
                    },
                    width: 120,
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // sx={{
                    //   ...customeSelect,
                    // }}
                    value={sortType}
                    onChange={handleSortTypeChange}
                  >
                    <MenuItem value="RECENT">Recent</MenuItem>
                    <MenuItem value="LAST">Last</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box sx={{ width: "100%", overflow: "auto", px: 2 }}>
              <TableContainer
                style={{
                  maxHeight: "Calc(100vh - 210px)",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>Date</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Cart ID
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Client Name
                      </TableCell>

                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Checkout Time
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Approval Time
                      </TableCell>

                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Agent Name
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Total Value ($)
                      </TableCell>

                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Deposit ($)
                      </TableCell>
                      {/* <TableCell sx={{ whiteSpace: "nowrap" }}>
                      Deposit Payment Status
                    </TableCell> */}

                      <TableCell sx={{ whiteSpace: "nowrap" }} align="right">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading &&
                      list?.length > 0 &&
                      list?.map((row, i) => (
                        <TableRow
                        // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell>
                            {moment(row?.date).format("MMM DD, YYYY")}
                          </TableCell>

                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row?.cartId}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {row?.clientName}
                          </TableCell>

                          <TableCell>
                            {moment(row?.checkoutTime).format(
                              "MMM DD YYYY; HH:mm"
                            )}
                          </TableCell>

                          <TableCell>
                            {moment(row?.approvedTime).format(
                              "MMM DD YYYY; HH:mm"
                            )}
                          </TableCell>

                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {row?.agentName}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {row?.totalValue?.toFixed(2)}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {row?.deposit?.toFixed(2)}
                          </TableCell>
                          {/* <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {row?.depositPaymentStatus ? (
                            <span style={{ color: "#435700", fontWeight: 700 }}>
                              Paid
                            </span>
                          ) : (
                            <span style={{ color: "#9A3B26", fontWeight: 700 }}>
                              Unpaid
                            </span>
                          )}
                        </TableCell> */}

                          {/* <TableCell>{row?.gender}</TableCell> */}

                          <TableCell
                            sx={{ whiteSpace: "nowrap", p: 0 }}
                            align="right"
                          >
                            <Button
                              disableElevation
                              variant="contained"
                              color="warning"
                              component={Link}
                              to={`/confirmed-carts-details/${row?.cartId}`}
                              state={{ data: row }}
                              sx={{
                                background: "#EFF3FF",
                                color: theme.palette.text.light,
                                fontSize: "12px",
                                fontWeight: 700,
                                "&:hover": {
                                  background: "#CDD9FF",
                                },
                              }}
                            >
                              View
                            </Button>

                            {/* <Button
                              disableElevation
                              variant="contained"
                              color="warning"
                              // component={Link}
                              // to={`/confirmed-carts-details/${row?.cartId}`}
                              onClick={(e) => {
                                setNewUpdateValue({
                                  index: i,
                                  id: row.id,
                                  value: e.target.checked,
                                });
                                setSelectedForUpdate(row);
                                setStatusUpdateOpen(true);
                              }}
                              sx={{
                                background: "#EFF3FF",
                                color: theme.palette.text.light,
                                fontSize: "12px",
                                fontWeight: 700,
                                "&:hover": {
                                  background: "#CDD9FF",
                                },
                              }}
                            >
                              Add to order
                            </Button> */}
                          </TableCell>
                        </TableRow>
                      ))}

                    {loading && pageLoading()}
                  </TableBody>
                </Table>
              </TableContainer>
              {!loading && list?.length < 1 ? (
                <Box sx={{ textAlign: "center", p: 2 }}>
                  <strong> No Data Found</strong>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs="auto"
          sx={{
            px: 2,
            py: 1.75,
            borderTop: "1px solid #E5E5E5",
            width: "100%",
          }}
        >
          {/* {list?.length > 0 && ( */}
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs="auto">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs="auto">
                  <Typography
                    variant="medium"
                    color="text.light"
                    // sx={{ display: "inline-flex" }}
                  >
                    Showing&nbsp; &nbsp;
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box>
                    <FormControl size="small">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={limit}
                        onChange={handleLimitChange}
                      >
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                    &nbsp; &nbsp;
                  </Box>
                </Grid>
                {/* <Grid item xs="auto">
                  <Typography
                    variant="medium"
                    color="text.light"
                 
                  >
                    of 100
                  </Typography>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs="auto">
              {" "}
              <Pagination
                sx={{ display: "inline-block" }}
                count={totalPage}
                showFirstButton
                showLastButton
                shape="rounded"
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>
          {/* )} */}
        </Grid>
      </Grid>
    </div>
  );
};

export default ConfirmedCarts;
