import React, { useContext, useEffect, useState } from "react";
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { useTheme } from "@mui/material/styles";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Chip from "@mui/material/Chip";
import {
  Avatar,
  Button,
  IconButton,
  Paper,
  Skeleton,
  TextField,
} from "@mui/material";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Collapse from "@mui/material/Collapse";
import { getDataWithToken } from "../../services/GetDataService";
import { AuthContext } from "../../context/AuthContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RefreshToken from "../../services/RefreshToken";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { handlePostData } from "../../services/PostDataService";
import PulseLoader from "react-spinners/PulseLoader";
import { handlePutRowData } from "../../services/PutRowDataService";
import { handlePutData } from "../../services/PutDataService";
import DeliveryReplaceList from "./DelivereyReplaceList";

const DeliveryReplace = () => {
  const theme = useTheme();
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [gender, setGender] = useState("");
  const [status, setStatus] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [message, setMessage] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [createdStartTime, setCreatedStartTime] = useState(null);
  const [createdEndTime, setCreatedEndTime] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [codStatusUpdateOpen, setCodStatusUpdateOpen] = useState(false);
  const [newUpdateValue, setNewUpdateValue] = useState({});
  const [selectedForUpdate, setSelectedForUpdate] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateStatusValue, setUpdateStatusValue] = useState("");
  
  const [sortType, setSortType] = useState("RECENT");

  const [updateLoading, setUpdateLoading] = useState(false);

  const handleSortTypeChange = (event) => {
    setSortType(event.target.value);
    getData(0, "", "", event.target.value);
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const handleCodStatusUpdateClickOpen = (index, row) => {
    setCodStatusUpdateOpen(true);
  };

  const handleCodStatusUpdateClose = () => {
    setCodStatusUpdateOpen(false);
    setUpdateStatusValue("");
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    getData(0, event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    // let pageNo = newPage + 1;
    console.log("newPage", newPage);
    getData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };
  const pageLoading = () => {
    let rows = [];

    for (let i = 0; i < 15; i++) {
      let cells = [];

      for (let j = 0; j < 9; j++) {
        cells.push(
          <TableCell key={j} sx={{ py: 1.5 }}>
            <Skeleton></Skeleton>
          </TableCell>
        );
      }

      rows.push(<TableRow key={i}>{cells}</TableRow>);
    }

    return rows;
  };
  const clearFilter = (event) => {
    setName("");
    setEmail("");
    setmobileNo("");
    setStatus("");
    setCreatedStartTime(null);
    setCreatedEndTime(null);
    setPage(0);
    let newUrl = `api/customer`;
    getData("", "", newUrl);
  };
  const getData = async (pageNO, pageLimit, newUrl,sortTypeValue) => {
    setLoading(true);
    setMessage("");
    let newPageNO;
    let newLimit;
    let newSortTypeValue;
    // let url;
    if (pageNO) {
      newPageNO = pageNO - 1;
    } else {
      newPageNO = 0;
    }
    if (pageLimit) {
      newLimit = pageLimit;
    } else {
      newLimit = limit;
    }

    if (sortTypeValue) {
      newSortTypeValue = sortTypeValue;
    } else {
      newSortTypeValue = sortType;
    }
    // if (newUrl) {
    //   url = newUrl;
    // } else {
    //   let newStatus = status;
    //   let newCreatedStartTime = "";
    //   let newCreatedEndTime = "";
    //   if (status === "None") {
    //     newStatus = "";
    //   }
    //   if (createdStartTime !== null) {
    //     newCreatedStartTime = dayjs(createdStartTime).format("YYYY-MM-DD");
    //   }
    //   if (createdEndTime !== null) {
    //     newCreatedEndTime = dayjs(createdEndTime).format("YYYY-MM-DD");
    //   }

    //   url = `api/customer?keyword=${name.trim()}&email=${encodeURIComponent(
    //     email.trim()
    //   )}&mobile=${encodeURIComponent(
    //     mobileNo.trim()
    //   )}&gender=${gender}&status=${newStatus}&from=${newCreatedStartTime}&to=${newCreatedEndTime}&page=${newPageNO}`;
    // }
    let url = `/api/v1/private/admin/delivery-list?sortType=${newSortTypeValue}&size=${newLimit}&page=${parseInt(
      newPageNO
    )}`;

    let token = await RefreshToken(dizli_admin_panel, logout, login);

    console.log("parent page -----------------", token);
    // let token = dizli_admin_panel.access_token;
    let res = await getDataWithToken(url, token);
    // console.log("res", res?.data?.data);
    // if (res?.status === 401 || res?.status === 403) {
    //   logout();
    //   return;
    // }

    if (res?.status > 199 && res?.status < 300) {
      setTotalElements(res.data.data.totalElements);
      setTotalPage(res.data.data.totalPages);
      setRowsPerPage(res.data.data.per_page);
      if (res.data.data.content.length > 0) {
        setList(res.data.data.content);
      } else {
        setMessage("No data found");
        setList([]);
      }
    } else {
      setMessage(res.data.message);
    }
    setLoading(false);
  };

  const handleUpdateStatus = async (updateStatus) => {
    try {
      setUpdateLoading(true);
      let formData = new FormData();
      formData.append("cartId", selectedForUpdate.cartId);

      let token = await RefreshToken(dizli_admin_panel, logout, login);
      // `/api/v1/private/admin/order/mark-as-delivered`,
      // let response = await handlePutRowData(
      //   `api/v1/private/admin/order/${selectedForUpdate.cartId}/mark-as-delivered`,
      //   null,
      //   token
      // );

      let response = await axios({
        method: "put",
        url: `api/v1/private/admin/order/${selectedForUpdate.cartId}/mark-as-delivered`,

        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.status > 199 && response?.status < 300) {
        handleSnakbarOpen("Update successfully", "success");
        getData(0);
        handleCodStatusUpdateClose();
      } else {
        handleSnakbarOpen(response.data.message.toString(), "error");
      }
    } catch (error) {
      console.log("error", error);
      setUpdateLoading(false);
      handleSnakbarOpen(error.response.data.message.toString(), "error");
    }
    setUpdateLoading(false);
  };

  const showStatus = (status) => {
    const parts = status.split("_");
    const lastValue = parts[parts.length - 1];
    return lastValue;
  };

  const customeSelect = {
    background: "#fff",

    "& .MuiInputBase-input": {
      borderRadius: 50,
      position: "relative",
      // backgroundColor: theme.palette.background.paper,
      // border: "1px solid #ced4da",
      fontSize: 14,
      color: "#222",
      // padding: "6px 16px 6px 44px",
      // Use the system font instead of the default Roboto font.
    },
  };
  useEffect(() => {
    // setLoading(true);
    getData();
  }, []);
  return (
    <div>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100vh" }}
      >
        <Grid item sx={{ width: "100%" }}>
          {" "}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 2.125, borderBottom: "1px solid #E5E5E5" }}
          >
            <Grid item xs="auto">
              <Typography
                variant="h6"
                color="text.light"
                sx={{ fontWeight: 600 }}
              >
                Delivery List{" "}
                <span style={{ fontWeight: 400 }}>({totalElements})</span>
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator="/"
                // className={classes.breadcrumbsStyle}
              >
                <Link to="#">Delivery List</Link>

                {/* <Link to="#">Confirmed Carts</Link> */}
              </Breadcrumbs>
            </Grid>
          </Grid>
          
          <Box
            sx={{
              width: "100%",
              overflow: "auto",
              px: 2,
            }}
          >
             <Grid container justifyContent="space-between">
              <Grid item xs="auto">
                
              </Grid>
              <Grid item xs="auto">
                <FormControl
                  size="small"
                  sx={{
                    mb: 2,
                    mt: 2,
                    ml: 2,
                    ...customeSelect,
                    ".MuiInputLabel-root": {
                      color: "#bfbfbf",
                    },
                    width: 120,
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // sx={{
                    //   ...customeSelect,
                    // }}
                    value={sortType}
                    onChange={handleSortTypeChange}
                  >
                    <MenuItem value="RECENT">Recent</MenuItem>
                    <MenuItem value="LAST">Last</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <TableContainer
              style={{
                maxHeight: "Calc(100vh - 210px)",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                      Date
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                      Cart ID
                    </TableCell>

                    <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                      Client Name
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                      Phone Number
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                      co#
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                      Waybill#
                    </TableCell>
                    {/* <TableCell sx={{ whiteSpace: "nowrap" }}>Address</TableCell> */}
                    <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                      Status
                    </TableCell>

                    <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                      Invoice
                    </TableCell>

                    <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                      Comment
                    </TableCell>

                    <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading &&
                    list?.length > 0 &&
                    list?.map((row, i) => (
                      <DeliveryReplaceList
                        key={i}
                        row={row}
                        getData={getData}
                      />
                    ))}

                  {loading && pageLoading()}
                </TableBody>
              </Table>
            </TableContainer>
            {!loading && list?.length < 1 ? (
              <Box sx={{ textAlign: "center", p: 2 }}>
                <strong> No Data Found</strong>
              </Box>
            ) : null}
          </Box>
        </Grid>
        <Grid
          item
          xs="auto"
          sx={{
            px: 2,
            py: 1.75,
            borderTop: "1px solid #E5E5E5",
            width: "100%",
          }}
        >
          {/* {list?.length > 0 && ( */}
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs="auto">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs="auto">
                  <Typography
                    variant="medium"
                    color="text.light"
                    // sx={{ display: "inline-flex" }}
                  >
                    Showing&nbsp; &nbsp;
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box>
                    <FormControl size="small">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={limit}
                        onChange={handleLimitChange}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                    &nbsp; &nbsp;
                  </Box>
                </Grid>
                {/* <Grid item xs="auto">
                  <Typography
                    variant="medium"
                    color="text.light"
                 
                  >
                    of 100
                  </Typography>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs="auto">
              {" "}
              <Pagination
                sx={{ display: "inline-block" }}
                count={totalPage}
                showFirstButton
                showLastButton
                shape="rounded"
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>
          {/* )} */}
        </Grid>
      </Grid>
      <Dialog
        open={codStatusUpdateOpen}
        onClose={handleCodStatusUpdateClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            minWidth: "450px",
            position: "relative",
            px: 4,
            pt: 5,
            textAlign: "center",
          }}
        >
          {/* <b>{"Mark Order as Delivered"}</b> */}
          <IconButton
            onClick={handleCodStatusUpdateClose}
            style={{ position: "absolute", right: 24, top: 10 }}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 4 }}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            {" "}
            Mark as Delivered :{" "}
            <b>
              <i>{selectedForUpdate?.cartId}</i>
            </b>{" "}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", px: 4, pb: 5 }}>
          <Button
            variant="outlined"
            color="text"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            disabled={updateStatusValue === "REJECTED"}
            startIcon={<ClearIcon />}
            onClick={handleCodStatusUpdateClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            disabled={updateLoading}
            startIcon={<DoneAllIcon />}
            onClick={handleUpdateStatus}
          >
            {!updateLoading && "Confirm"}
            <PulseLoader
              color={"#353b48"}
              loading={updateLoading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeliveryReplace;
