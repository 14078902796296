import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import RefreshToken from "../../services/RefreshToken";
import { AuthContext } from "../../context/AuthContext";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";

// Create styles for the PDF document
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 30,
  },
  section: {
    display: "flex",
    flexDirection: "column",
    float: "right",
    position: "relative",
    height: "82px",
  },
  header: {
    fontSize: 30,
    fontWeight: 600,
    marginTop: "10px",
    position: "absolute",
    right: 0,
    color: "#000",
    textTransform: "uppercase",
  },
  headerTitle: {
    fontSize: 12,
    fontWeight: 600,
    marginTop: "49px",
    position: "absolute",
    right: 5,
    color: "#000",
    textTransform: "uppercase",
  },

  sectionTwo: {
    display: "flex",
    flexDirection: "column",
    float: "left",
    position: "relative",
  },
  headerTwo: {
    fontSize: 16,
    marginTop: "2px",
    position: "absolute",
    left: 0,
    color: "#000",
    textTransform: "uppercase",
  },
  headerTitleTwo: {
    fontSize: 16,
    marginTop: "32px",
    position: "absolute",
    left: 0,
    textTransform: "none",
    color: "#000",
    fontWeight: 600,
  },
  headerTitleTwoName: {
    fontSize: 16,
    marginTop: "32px",
    position: "absolute",
    right: 280,
    textTransform: "none",
    color: "#000",
    fontWeight: 600,
  },
  // headerTitleTwoNameLast: {
  //   fontSize: 16,
  //   marginTop: "32px",
  //   position: "absolute",
  //   right: 280,
  //   textTransform: "none",
  //   color: "#000",
  //   fontWeight: 600,
  // },

  headerTitleNumber: {
    fontSize: 16,
    marginTop: "60px",
    position: "absolute",
    left: 0,
    textTransform: "none",
    color: "#000",
    fontWeight: 600,
  },
  headerTitleTwoNumber: {
    fontSize: 16,
    marginTop: "60px",
    position: "absolute",
    right: 280,
    textTransform: "none",
    color: "#000",
    fontWeight: 600,
  },

  headerTitleAddress: {
    fontSize: 16,
    marginTop: "87px",
    position: "absolute",
    left: 0,
    textTransform: "none",
    color: "#000",
    fontWeight: 600,
  },
  headerTitleAddressTwo: {
    fontSize: 16,
    marginTop: "87px",
    position: "absolute",
    right: 283,
    textTransform: "none",
    color: "#000",
    fontWeight: 600,
  },

  sectionThree: {
    marginTop: "130px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "0px",
    marginBottom: "0px",
  },
  sectionContent: {
    marginTop: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "0px",
  },
  extraStyle: {
    color: "#000",
    fontSize: "15px",
    fontWeight: 900,
  },
  sectionContentExtra: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "203px",
  },
});

// Create Document Component
const MyDocument = ({
  cartID,
  userDatas,
  invoice,
  coNumber,
  wayBillNumber,
  handleClose,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Invoice</Text>
        <Text style={styles.headerTitle}>#{cartID}</Text>
      </View>
      <View style={styles.sectionTwo}>
        <Text style={styles.headerTwo}>BILLED TO</Text>
        <Text style={styles.headerTitleTwo}>Name: </Text>
        <Text style={styles.headerTitleTwoName}>
          {userDatas.firstName} {userDatas.lastName}
        </Text>
        <Text style={styles.headerTitleNumber}>Number: </Text>
        <Text style={styles.headerTitleTwoNumber}>{userDatas.phoneNumber}</Text>
        <Text style={styles.headerTitleAddress}>Address: </Text>
        <Text style={styles.headerTitleAddressTwo}>{userDatas.region}</Text>
      </View>

      <View style={styles.sectionThree}>
        <Text style={{ color: "#000", fontSize: "15px", paddingBottom: "0px" }}>
          DESCRIPTION
        </Text>
        <Text style={{ color: "#000", fontSize: "15px", paddingBottom: "0px" }}>
          AMOUNT (USD)
        </Text>
      </View>
      <div
        style={{ marginTop: "10px", border: "1px solid #000", height: "1px" }}
      ></div>

      <View style={styles.sectionContent}>
        <Text style={{ color: "#000", fontSize: "15px" }}>Item price:</Text>
        <Text style={styles.extraStyle}>{invoice.itemsSubTotal}</Text>
      </View>
      <div
        style={{ marginTop: "10px", border: "1px solid #000", height: "1px" }}
      ></div>

      <View style={styles.sectionContent}>
        <Text style={{ color: "#000", fontSize: "15px" }}>Postage:</Text>
        <Text style={{ color: "#000", fontSize: "15px", fontWeight: "1000px" }}>
          {invoice.postage}
        </Text>
      </View>
      <div
        style={{ marginTop: "10px", border: "1px solid #000", height: "1px" }}
      ></div>

      <View style={styles.sectionContent}>
        <Text style={{ color: "#000", fontSize: "15px" }}>Shipping:</Text>
        <Text style={{ color: "#000", fontSize: "15px", fontWeight: "1000px" }}>
          {invoice.shipping}
        </Text>
      </View>
      <div
        style={{ marginTop: "10px", border: "1px solid #000", height: "1px" }}
      ></div>

      <View style={styles.sectionContent}>
        <Text style={{ color: "#000", fontSize: "15px" }}>customsVAT:</Text>
        <Text style={{ color: "#000", fontSize: "15px", fontWeight: "1000px" }}>
          {invoice.customsVAT}
        </Text>
      </View>
      <div
        style={{ marginTop: "10px", border: "1px solid #000", height: "1px" }}
      ></div>

      <View style={styles.sectionContent}>
        <Text style={{ color: "#000", fontSize: "15px" }}>Service:</Text>
        <Text style={{ color: "#000", fontSize: "15px", fontWeight: "1000px" }}>
          {invoice.service}
        </Text>
      </View>
      <div
        style={{ marginTop: "10px", border: "1px solid #000", height: "2px" }}
      ></div>

      <View style={styles.sectionContent}>
        <Text style={{ color: "#000", fontSize: "15px" }}>Total:</Text>
        <Text style={styles.extraStyle}>{invoice.total}</Text>
      </View>

      <View style={styles.sectionContentExtra}>
        <Text style={{ color: "#000", fontSize: "16px" }}>Deposit:</Text>
        <Text
          style={{
            color: "#000",
            fontSize: "18px",
            fontWeight: 900,
            marginLeft: "220px",
          }}
        >
          {invoice.deposit}
        </Text>
      </View>

      <View
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: "203px",
        }}
      >
        <Text style={{ color: "#000", fontSize: "16px" }}>Deposit in IQD:</Text>
        <Text
          style={{
            color: "#000",
            fontSize: "16px",
            fontWeight: 900,
            marginRight: "0px",
          }}
        >
          {invoice.depositInIQD}
        </Text>
      </View>

      <View
        style={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: "203px",
        }}
      >
        <Text style={{ color: "#000", fontSize: "14px" }}>CO#:</Text>
        <Text
          style={{
            color: "#000",
            fontSize: "18px",
            fontWeight: 900,
            marginRight: "2px",
          }}
        >
          ......................
        </Text>
      </View>

      <View
        style={{
          marginTop: "7px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: "203px",
        }}
      >
        <Text style={{ color: "#000", fontSize: "14px" }}>Waybill#:</Text>
        <Text
          style={{
            color: "#000",
            fontSize: "18px",
            fontWeight: 900,
            marginRight: "2px",
          }}
        >
          ......................
        </Text>
      </View>

      <View
        style={{
          marginTop: "0px",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          right: 32,
          bottom: 86,
        }}
      >
        <div
          style={{ border: "1px solid #000", height: "1px", width: "100px" }}
        ></div>
        <Text style={{ color: "#000", fontSize: "20px", marginLeft: "10px" }}>
          signature
        </Text>
      </View>
    </Page>
  </Document>
);

const InvoiceModal = ({ id, number, coNumber, wayBillNumber, handleClose }) => {
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  //get props id...............!
  const cartID = id;
  const PhoneNumber = number;

  const [userDatas, setUserDatas] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [error, setError] = useState(null);
  const [invoice, setInvoice] = useState([]);

  //get user details....................!
  const getUser = async () => {
    try {
      setUserLoading(true);
      const url = `https://dev.getdizli.com/api/v1/private/user/statistics/${PhoneNumber}`;

      // Assuming RefreshToken is a function that refreshes the token
      let token = await RefreshToken(dizli_admin_panel, logout, login);

      // Make the API request
      let response = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Handle successful response
      if (response?.status > 199 && response?.status < 300) {
        setUserDatas(response.data.data);
      }
    } catch (error) {
      setUserLoading(false);
      // Handle error
      setError(error);
    }
    setUserLoading(false);
  };

  //get invoice data..........................!
  const getInvoice = async () => {
    try {
      setInvoiceLoading(true);
      const url = `/api/v1/private/order/${cartID}/details`;

      // Assuming RefreshToken is a function that refreshes the token
      let token = await RefreshToken(dizli_admin_panel, logout, login);

      // Make the API request
      let response = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Handle successful response
      if (response?.status > 199 && response?.status < 300) {
        setInvoice(response.data.data.invoice);
      }
    } catch (error) {
      setInvoiceLoading(false);
      // Handle error
      setError(error);
    }
    setInvoiceLoading(false);
  };

  const handleDownload = async () => {
    const blob = await pdf(
      <MyDocument
        cartID={id}
        userDatas={userDatas}
        invoice={invoice}
        coNumber={coNumber}
        wayBillNumber={wayBillNumber}
      />
    ).toBlob();

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `invoice_${id}.pdf`;
    link.click();
    handleClose();
  };

  useEffect(() => {
    getUser();
    getInvoice();
  }, []);

  return (
    <>
      <div style={{ padding: "0px" }}>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Grid item lg={12} xs={12}>
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
                float: "right",
                position: "relative",
                height: "82px",
              }}
            >
              <Typography sx={{ fontWeight: 400, fontSize: "38px" }}>
                INVOICE
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  bottom: 10,
                  right: 0,
                  fontWeight: 600,
                  fontSize: "11px",
                }}
              >
                #{cartID}
              </Typography>
            </div>
          </Grid>
          <Grid item lg={6} xs={12} style={{ marginTop: "8px" }}>
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "4px",
                width: "200px",
              }}
            >
              <Typography sx={{ fontWeight: 600 }} variant="body2">
                BILLED TO{" "}
              </Typography>
            </div>
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "4px",
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
                Name :{" "}
              </Typography>
              <Typography sx={{ fontSize: "13px" }}>
                {userLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{ width: "160px", height: "19px" }}
                  ></Skeleton>
                ) : (
                  `${userDatas?.firstName} ${userDatas?.lastName}`
                )}
              </Typography>
            </div>
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "4px",
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
                Number :{" "}
              </Typography>
              <Typography sx={{ fontSize: "13px" }}>
                {userLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{ width: "160px", height: "19px" }}
                  ></Skeleton>
                ) : (
                  `${userDatas?.phoneNumber}`
                )}
              </Typography>
            </div>
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "4px",
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
                Address :{" "}
              </Typography>
              <Typography sx={{ fontSize: "13px" }}>
                {userLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{ width: "160px", height: "19px" }}
                  ></Skeleton>
                ) : (
                  `${userDatas?.region}`
                )}
              </Typography>
            </div>
          </Grid>
        </Grid>

        <TableContainer
          style={{
            maxHeight: "Calc(100vh - 135px)",
            marginTop: "8px",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    paddingLeft: "0px",
                    color: "#000",
                    fontSize: "11px",
                    paddingBottom: "1px",
                  }}
                  align="left"
                >
                  DESCRIPTION
                </TableCell>
                <TableCell
                  sx={{
                    paddingRight: "0px",
                    color: "#000",
                    fontSize: "11px",
                    paddingBottom: "1px",
                  }}
                  align="right"
                >
                  AMOUNT (USD)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                    padding: "7px",
                    paddingLeft: "0px",
                  }}
                  align="left"
                >
                  Item price:
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    fontWeight: 800,
                    fontSize: "12px",
                    padding: "7px",
                    paddingRight: "0px",
                  }}
                  align="right"
                >
                  {invoiceLoading ? (
                    <Skeleton
                      variant="text"
                      sx={{
                        width: "50px",
                        height: "11px",
                        float: "right",
                        fontSize: "13px",
                      }}
                    ></Skeleton>
                  ) : (
                    `${invoice.itemsSubTotal}`
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                    padding: "7px",
                    paddingLeft: "0px",
                  }}
                  align="left"
                >
                  Postage:
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                    padding: "7px",
                    paddingRight: "0px",
                  }}
                  align="right"
                >
                  {invoiceLoading ? (
                    <Skeleton
                      variant="text"
                      sx={{ width: "50px", height: "11px", float: "right" }}
                    ></Skeleton>
                  ) : (
                    `${invoice.postage}`
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                    padding: "7px",
                    paddingLeft: "0px",
                  }}
                  align="left"
                >
                  Shipping:
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                    padding: "7px",
                    paddingRight: "0px",
                  }}
                  align="right"
                >
                  {invoiceLoading ? (
                    <Skeleton
                      variant="text"
                      sx={{ width: "50px", height: "11px", float: "right" }}
                    ></Skeleton>
                  ) : (
                    `${invoice.shipping}`
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                    padding: "7px",
                    paddingLeft: "0px",
                  }}
                  align="left"
                >
                  Customs/VAT:
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                    padding: "7px",
                    paddingRight: "0px",
                  }}
                  align="right"
                >
                  {invoiceLoading ? (
                    <Skeleton
                      variant="text"
                      sx={{ width: "50px", height: "11px", float: "right" }}
                    ></Skeleton>
                  ) : (
                    `${invoice.customsVAT}`
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                    padding: "7px",
                    paddingLeft: "0px",
                  }}
                  align="left"
                >
                  Service:
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    fontSize: "12px",
                    padding: "7px",
                    paddingRight: "0px",
                  }}
                  align="right"
                >
                  {invoiceLoading ? (
                    <Skeleton
                      variant="text"
                      sx={{ width: "50px", height: "11px", float: "right" }}
                    ></Skeleton>
                  ) : (
                    `${invoice.service}`
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Divider
            sx={{
              height: "1px",
              backgroundColor: "#000",
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          />
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "1px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                fontSize: "13px",
                padding: "7px",
                paddingLeft: "1px",
                paddingTop: "0px",
              }}
            >
              Total
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "13px",
                padding: "7px",
                paddingRight: "1px",
                paddingTop: "0px",
              }}
            >
              {invoiceLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ width: "50px", height: "12px", float: "right" }}
                ></Skeleton>
              ) : (
                `${invoice.total}`
              )}
            </Typography>
          </div>
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "14px",
              marginBottom: "6px",
            }}
          >
            <Typography sx={{ marginLeft: "200px", fontSize: "12px" }}>
              Deposit:
            </Typography>
            <Typography
              sx={{ textAlign: "right", fontWeight: 700, fontSize: "12px" }}
            >
              {invoiceLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ width: "50px", height: "12px", float: "right" }}
                ></Skeleton>
              ) : (
                `${invoice.deposit}`
              )}
            </Typography>
          </div>
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "0px",
              marginBottom: "15px",
            }}
          >
            <Typography sx={{ marginLeft: "200px", fontSize: "12px" }}>
              Deposit in IQD:
            </Typography>
            <Typography
              sx={{ textAlign: "right", fontWeight: 700, fontSize: "12px" }}
            >
              {invoiceLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ width: "50px", height: "12px", float: "right" }}
                ></Skeleton>
              ) : (
                `${invoice.depositInIQD}`
              )}
            </Typography>
          </div>
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "0px",
              marginBottom: "6px",
            }}
          >
            <Typography sx={{ marginLeft: "200px", fontSize: "12px" }}>
              CO#:
            </Typography>
            <Typography
              sx={{
                textAlign: "right",
                fontWeight: 600,
                marginTop: "0px",
                color: "#9b9b9b",
              }}
            >
              -----------------
            </Typography>
          </div>
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "0px",
              marginBottom: "60px",
            }}
          >
            <Typography sx={{ marginLeft: "200px", fontSize: "12px" }}>
              Waybill#:
            </Typography>
            <Typography
              sx={{
                textAlign: "right",
                fontWeight: 600,
                marginTop: "0px",
                color: "#9b9b9b",
              }}
            >
              -----------------
            </Typography>
          </div>
        </TableContainer>

        <div
          className=""
          style={{
            float: "right",
            marginBottom: "0px",
            paddingBottom: "0px",
            minWidth: "150px",
          }}
        >
          <Divider />
          <p style={{ padding: "0px", margin: "0px", textAlign: "center" }}>
            Signature
          </p>
        </div>
      </div>
      <div
        className=""
        style={{ float: "left", marginBottom: "0px", paddingBottom: "0px" }}
      >
        {/* Apply Convert To PDF */}
        <Button
          variant="contained"
          color="info"
          size="small"
          type="submit"
          onClick={handleDownload}
        >
          Download PDF
        </Button>
      </div>
      {showPDFViewer && (
        <PDFViewer style={{ width: "100%", height: "500px" }}>
          <MyDocument
            cartID={id}
            userDatas={userDatas}
            invoice={invoice}
            coNumber={coNumber}
            wayBillNumber={wayBillNumber}
          />
        </PDFViewer>
      )}
      {/* <PDFViewer style={{ width: "100%", height: "500px" }}>
        <MyDocument
          cartID={id}
          userDatas={userDatas}
          invoice={invoice}
          coNumber={coNumber}
          wayBillNumber={wayBillNumber}
        />
      </PDFViewer> */}
    </>
  );
};

export default InvoiceModal;
