import React, { useContext, useEffect, useState } from "react";
import Countdown from "react-countdown";
import { Box, Breadcrumbs, Grid, TextField, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import MenuIcon from "../icons/MenuIcon";
import { useTheme } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, Button, IconButton, Paper, Skeleton } from "@mui/material";

import { getDataWithToken } from "../../../services/GetDataService";
import { AuthContext } from "../../../context/AuthContext";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useParams } from "react-router-dom";
import RefreshToken from "../../../services/RefreshToken";

import { handlePutRowData } from "../../../services/PutRowDataService"; 
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"; 
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import ClearIcon from "@mui/icons-material/Clear";

const EditHistory = () => {
  const navigate = useNavigate();
  const location = useLocation(); 
  const { id } = useParams(); 
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
 
 
  
  const [message, setMessage] = useState("");
 
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
 
  const [validationIndex, setValidationIndex] = useState();
  const [loading2, setLoading2] = useState(false);
  const [editHistoryList, setEditHistoryList] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const buttonStyle = {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "14px",
    pr: 2,
    pl: 2.625,
    py: 0.75,
    "& .MuiButton-startIcon": {
      mr: 0.625,
    },
  };

  const pageLoading = () => {
    let rows = [];

    for (let i = 0; i < 10; i++) {
      rows.push(
        <Box key={i} sx={{ py: 1.5 }}>
          <Skeleton></Skeleton>
        </Box>
      );
    }

    return rows;
  };
  const getData = async () => {
    handleClickOpen();

    setLoading2(true);
    setMessage("");
    let newPageNO;
    let url = `api/v1/private/admin/cart-edit-history/${id}`;
    // if (cartType === "review") {
    //   url = `api/v1/private/admin/review-cart/${id}`;
    // }

    // let token = dizli_admin_panel.access_token;
    let token = await RefreshToken(dizli_admin_panel, logout, login);

    let res = await getDataWithToken(url, token);

    console.log("res--------------------------------", res.data.data);
    if (res?.status > 199 && res?.status < 300) {
      setEditHistoryList(res.data.data);
    } else {
      enqueueSnackbar(res.data.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setLoading2(false);
  };
  return (
    <>
      <Button
        disableElevation
        variant="contained"
        color="success"
        startIcon={
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4993 7.53337V4.69338C14.4993 2.00671 13.8727 1.33337 11.3527 1.33337H6.31268C3.79268 1.33337 3.16602 2.00671 3.16602 4.69338V12.2C3.16602 13.9734 4.13936 14.3934 5.31936 13.1267L5.32601 13.12C5.87268 12.54 6.70601 12.5867 7.17934 13.22L7.85268 14.12"
              stroke="#555555"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.16602 4.66663H11.4993"
              stroke="#555555"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.83301 7.33337H10.833"
              stroke="#555555"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.974 9.84685L10.614 12.2069C10.5207 12.3002 10.434 12.4735 10.414 12.6002L10.2873 13.5002C10.2407 13.8269 10.4673 14.0535 10.794 14.0069L11.694 13.8802C11.8207 13.8602 12.0007 13.7735 12.0873 13.6802L14.4474 11.3202C14.854 10.9135 15.0474 10.4402 14.4474 9.8402C13.854 9.24686 13.3807 9.44018 12.974 9.84685Z"
              stroke="#555555"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.6328 10.1869C12.8328 10.9069 13.3928 11.4669 14.1128 11.6669"
              stroke="#555555"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        }
        sx={{
          ...buttonStyle,
          background: "#FFECEC",
          color: "#555555",
          "&:hover": {
            background: "#FFC4C4",
          },
        }}
        onClick={getData}
      >
        View Edits
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            minWidth: "450px",
            position: "relative",
            px: 4,
            pt: 5,
            pb: 0,
            fontSize: 24,
          }}
        >
          <b> Cart History </b>
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: 24, top: 10 }}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ px: 4, minWidth: "900px" }}
          // sx={{ display: "flex", flexDirection: "column", gap: 2, width: 400 }}
        >
          <Box
            sx={{
              width: "100%",
              overflow: "auto",
              pt: 1.125,
            }}
          >
            <TableContainer
              style={
                {
                  // maxHeight: "Calc(100vh - 135px)",
                }
              }
            >
              {!loading2 &&
                editHistoryList?.length > 0 &&
                editHistoryList?.map((row, index) => (
                  <Box key={index}>
                    <Typography
                      variant="base"
                      sx={{ fontWeight: 600, background: "#ddd", p: 1 }}
                    >
                      Edit Version : {index + 1} &nbsp; &nbsp;
                      &nbsp;||&nbsp;&nbsp;&nbsp; Edited By : {row?.editedBy}
                      {row?.approveStatus && (
                        <span
                          style={{
                            float: "right",
                          }}
                        >
                          Approve Status :&nbsp;
                          <span
                            style={{
                              color:
                                row?.approveStatus === "APPROVE"
                                  ? "#35b522"
                                  : row?.approveStatus === "APPROVE_EST"
                                  ? "#1e88e5"
                                  : "#F23836",
                            }}
                          >
                            {row?.approveStatus}
                          </span>
                        </span>
                      )}
                    </Typography>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      sx={{ mb: 3 }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Items</TableCell>
                          <TableCell>Img</TableCell>
                          <TableCell>Link</TableCell>
                          {/* <TableCell>Description</TableCell> */}
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            Quantity
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            Price  
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            Postage  
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            Ounces 
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            Pounds  
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            KG  
                          </TableCell>
                          <TableCell>Total W KG</TableCell>
                          <TableCell sx={{ minWidth: "160px" }}>
                            Reason
                          </TableCell>

                          <TableCell>Item Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <>
                          <>
                            {row?.cartItemList?.map((item, index) => (
                              <TableRow
                                key={item.id}
                                sx={{
                                  background:
                                    validationIndex === index && "#FFECEC",
                                }}
                              >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>
                                  {item.product?.image === null ? (
                                    <img
                                      src="/no_image.png"
                                      alt=""
                                      style={{
                                        maxWidth: "350px",
                                        maxHeight: "450px",
                                      }}
                                    />
                                  ) : (
                                    <Tooltip
                                      slotProps={{
                                        tooltip: {
                                          sx: {
                                            // color: "#514E6A",
                                            maxWidth: 500,
                                            backgroundColor: "#ffff",
                                            boxShadow:
                                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                            p: 3,
                                          },
                                        },
                                      }}
                                      title={
                                        <div>
                                          <img
                                            src={item?.image}
                                            alt=""
                                            style={{
                                              maxWidth: "350px",
                                              maxHeight: "450px",
                                            }}
                                          />
                                        </div>
                                      }
                                      placement="right"
                                    >
                                      <img
                                        src={item?.image}
                                        alt=""
                                        style={{
                                          maxWidth: "90px",
                                          maxHeight: "120px",
                                        }}
                                      />
                                    </Tooltip>
                                  )}{" "}
                                </TableCell>
                                <TableCell>
                                  <a
                                    href={item?.url}
                                    target="_blank"
                                    style={{
                                      whiteSpace: "nowrap",
                                      color: "#666",
                                    }}
                                  >
                                    {item?.websiteName} URL
                                  </a>
                                </TableCell>

                                <TableCell>{item?.quantity}</TableCell>
                                <TableCell>{parseFloat(item?.price)}</TableCell>
                                <TableCell>
                                  {parseFloat(item.postage)}
                                </TableCell>
                                <TableCell>{parseFloat(item.ounces)}</TableCell>
                                <TableCell>{parseFloat(item.pounds)}</TableCell>
                                <TableCell>{parseFloat(item.kg)}</TableCell>
                                <TableCell>
                                  {parseFloat(item.totalWeight)}
                                </TableCell>
                                <TableCell sx={{ minWidth: "100px" }}>
                                  {item?.reason === null
                                    ? "-------"
                                    : item?.reason}
                                </TableCell>
                                <TableCell sx={{ minWidth: "100px" }}>
                                  {item?.status === null ? (
                                    "-------"
                                  ) : item?.status === "A_N" ? (
                                    <span style={{ color: "#35b522" }}>
                                      {" "}
                                      Accepted
                                    </span>
                                  ) : (
                                    <span style={{ color: "#F23836" }}>
                                      Rejected
                                    </span>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        </>
                      </TableBody>
                    </Table>
                  </Box>
                ))}
            </TableContainer>
            {loading2 &&
              // editHistoryList?.cartItemList?.length < 1 &&
              pageLoading()}
            {!loading2 && editHistoryList?.length < 1 ? (
              <Box sx={{ textAlign: "center", p: 2 }}>
                <strong> No edit history exist</strong>
              </Box>
            ) : null}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditHistory;
