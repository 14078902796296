import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { useSnackbar } from "notistack";
import { AuthContext } from "../../context/AuthContext";
import RefreshToken from "../../services/RefreshToken";

import PulseLoader from "react-spinners/PulseLoader";
import { handlePostData } from "../../services/PostDataService";

const Message = ({ phoneNo }) => {
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [myText, setMyText] = useState("");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const buttonStyle = {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "14px",
    pr: 2,
    pl: 2.625,
    py: 0.75,
    "& .MuiButton-startIcon": {
      mr: 0.625,
    },
  };
  const validation = () => {
    let isError = false;

    if (!myText.trim()) {
      handleSnakbarOpen("Please select a role", "error");
      document.getElementById("myText").focus();
      return (isError = true);
    }
    return isError;
  };
  const handleSubmit = async (e) => {
    // console.log("editIndexNo", editIndexNo);
    e.preventDefault();
    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);

      let token = await RefreshToken(dizli_admin_panel, logout, login);

      // return console.log('data', data);

      const formData = new FormData();
      formData.append("phone", phoneNo);
      formData.append("body", myText);

      let url = `api/v1/private/admin/send-message-to-user`;
      let response = await handlePostData(url, formData, token, true);
      console.log("response", response);
      if (response?.status > 199 && response?.status < 300) {
        setMyText("");
        handleSnakbarOpen(response?.data?.message, "success");
        handleClose();
      }
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        disableElevation
        variant="contained"
        color="success"
        startIcon={
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.49935 12.6667H6.16602C3.49935 12.6667 2.16602 12 2.16602 8.66671V5.33337C2.16602 2.66671 3.49935 1.33337 6.16602 1.33337H11.4993C14.166 1.33337 15.4993 2.66671 15.4993 5.33337V8.66671C15.4993 11.3334 14.166 12.6667 11.4993 12.6667H11.166C10.9593 12.6667 10.7593 12.7667 10.6327 12.9334L9.63268 14.2667C9.19268 14.8534 8.47268 14.8534 8.03268 14.2667L7.03268 12.9334C6.92602 12.7867 6.67935 12.6667 6.49935 12.6667Z"
              stroke="#555555"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.5 5.33337H12.1667"
              stroke="#555555"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.5 8.66663H9.5"
              stroke="#555555"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        }
        sx={{
          ...buttonStyle,
          background: "#EFF3FF",
          color: "#555555",
          "&:hover": {
            background: "#CDD9FF",
          },
        }}
        onClick={handleClickOpen}
      >
        Text Client
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            minWidth: "450px",
            position: "relative",
            px: 4,
            pt: 5,
          }}
        >
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: 24, top: 10 }}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 4 }}>
          <Typography variant="h6">
            Send message to : <b>{phoneNo}</b>
          </Typography>

          <TextField
            sx={{ mb: 2,mt:3 }}
            id="myText"
            variant="outlined"
            placeholder="Enter Message"
            multiline
            rows={4}
            fullWidth
            value={myText}
            onChange={(e) => setMyText(e.target.value)}
          />

          <Box sx={{ mt: 1, mb: 2, textAlign: "center" }}>
            <Button
              variant="contained"
              color="success"
              disableElevation
              sx={{
                minHeight: "50px",
                minWidth: "180px",
                textTransform: "none",
              }}
              disabled={loading}
              onClick={handleSubmit}
            >
              {!loading && "Send Message"}
              <PulseLoader
                color={"#353b48"}
                loading={loading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
            </Button>
          </Box>
          <Box sx={{ mt: 1, mb: 2, textAlign: "center" }}>
            <a
              href="https://app.intercom.com/a/inbox/u5ngf0ay/inbox/admin"
              target="_blank"
              style={{ textAlign: "center" }}
            >
              View All Conversations
            </a>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Message;
