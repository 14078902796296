import React, { useContext, useEffect, useState } from "react";
import { Box, Breadcrumbs, Grid, Tooltip, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import MenuIcon from "../icons/MenuIcon";
import { useTheme } from "@mui/material/styles";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Chip from "@mui/material/Chip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Avatar,
  Button,
  IconButton,
  Paper,
  Skeleton,
  TextField,
} from "@mui/material";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Collapse from "@mui/material/Collapse";

import { AuthContext } from "../../../context/AuthContext";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import { handlePutData } from "../../../services/PutDataService";
import { getDataWithToken } from "../../../services/GetDataService";
import Descreption from "../live_cart/Descreption";
import EditHistory from "../edit-history/EditHistory";
import Message from "../../message/Message";

const DeletedCartDetails = () => {
  const theme = useTheme();
  const { id } = useParams();
  const location = useLocation();
  console.log("state ================", location?.state);
  const { dizli_admin_panel, logout } = useContext(AuthContext);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [gender, setGender] = useState("");
  const [status, setStatus] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [message, setMessage] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [createdStartTime, setCreatedStartTime] = useState(null);
  const [createdEndTime, setCreatedEndTime] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [timeRange, setTimeRange] = useState("3-4 Weeks");
  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };
  const pageLoading = () => {
    let rows = [];

    for (let i = 0; i < 25; i++) {
      let cells = [];

      for (let j = 0; j < 12; j++) {
        cells.push(
          <TableCell key={j} sx={{ py: 1.5 }}>
            <Skeleton></Skeleton>
          </TableCell>
        );
      }

      rows.push(<TableRow key={i}>{cells}</TableRow>);
    }

    return rows;
  };
  const clearFilter = (event) => {
    setName("");
    setEmail("");
    setmobileNo("");
    setStatus("");
    setCreatedStartTime(null);
    setCreatedEndTime(null);
    setPage(0);
    let newUrl = `api/customer`;
    getData();
  };
  const getData = async () => {
    setLoading(true);
    setMessage("");
    let newPageNO;

    let url = `api/v1/private/admin/deleted-cart/${id}`;
    let token = dizli_admin_panel.access_token;
    let res = await getDataWithToken(url, token);
    console.log("res", res);

    if (res?.status > 199 && res?.status < 300) {
      // setTotalData(res.data.data.total);
      // setRowsPerPage(res.data.data.per_page);

      setList(res.data.data);
      console.log("data:::", res.data.data.shoppingCartList);
    } else {
      setMessage(res.data.message);
    }
    setLoading(false);
  };
  const buttonStyle = {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "14px",
    pr: 2,
    pl: 2.625,
    py: 0.75,
    "& .MuiButton-startIcon": {
      mr: 0.625,
    },
  };
  const customeSelect = {
    background: "#fff",

    "& .MuiInputBase-input": {
      borderRadius: 50,
      position: "relative",
      // backgroundColor: theme.palette.background.paper,
      // border: "1px solid #ced4da",
      fontSize: 14,
      color: "#222",
      padding: "6px 16px 6px 44px",
      // Use the system font instead of the default Roboto font.
    },
  };

  const handleBack = async () => {
    try {
      let url = `/api/v1/private/admin/live-cart/under-process/unmark`;
      let token = dizli_admin_panel.access_token;
      let formData = new FormData();
      formData.append("cartId", id);
      console.log("id", id);
      let res = await handlePutData(url, formData, token);
      console.log("res", res);
      // history.back();
    } catch (error) {
      console.log("errr", error);
    }
  };

  useEffect(() => {
    console.log("id", id);
    // setLoading(true);
    getData();
  }, []);
  return (
    <div>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100vh" }}
      >
        <Grid item sx={{ width: "100%" }}>
          {" "}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 2.125, borderBottom: "1px solid #E5E5E5" }}
          >
            <Grid
              item
              xs="auto"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              {/* <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton> */}
              <Typography
                variant="h6"
                color="text.light"
                sx={{ fontWeight: 600 }}
              >
                Rejected Carts Details{" "}
                <span style={{ fontWeight: 400 }}>
                  ({list?.shoppingCartList?.length})
                </span>
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator="/"
                // className={classes.breadcrumbsStyle}
              >
                {/* <Link to="#">Carts</Link> */}

                <Link to="/rejected-carts">Rejected Carts</Link>
                <Link to="#">Details</Link>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: "Calc(100vh - 65px)",
              overflowY: "auto",
              // pt: 2.5,
              px: 2,
            }}
          >
            <Box sx={{ mt: 4 }}>
              <Grid
                item
                sx={{
                  // width: "Calc(100% - 324px)",
                  boxSizing: "border-box",
                  px: 2,
                }}
              >
                {" "}
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item xs="auto">
                    <Grid
                      sx={{
                        background: "#FAFAFA",
                        py: 0.5,
                        borderRadius: "6px",
                      }}
                    >
                      {/* <Grid
                      item
                      xs="auto"
                      sx={{ px: 1.5, borderRight: "1px solid #DBDADE" }}
                    > */}
                      <Typography
                        variant="medium"
                        color="text.fade"
                        sx={{
                          fontWeight: 700,
                          px: 1.5,
                          borderRight: "1px solid #DBDADE",
                          display: "inline-flex",
                        }}
                      >
                        User Phone No: &nbsp;&nbsp;
                        <span style={{ color: "#222", fontWeight: 400 }}>
                          {location?.state?.data?.phoneNumber}
                        </span>
                      </Typography>
                      <Typography
                        variant="medium"
                        color="text.fade"
                        sx={{
                          fontWeight: 700,
                          px: 1.5,
                          borderRight: "1px solid #DBDADE",
                          display: "inline-flex",
                        }}
                      >
                        Cart ID: &nbsp;&nbsp;
                        <span style={{ color: "#222", fontWeight: 400 }}>
                          {id}
                        </span>
                      </Typography>
                      <Typography
                        variant="medium"
                        color="text.fade"
                        sx={{
                          fontWeight: 700,
                          px: 1.5,
                          borderRight: "1px solid #DBDADE",
                          display: "inline-flex",
                        }}
                      >
                        Date:&nbsp;&nbsp;
                        <span style={{ color: "#222", fontWeight: 400 }}>
                          {/* {list.submittedAt} */}
                          {moment(list?.submittedAt).format("MMM DD YYYY")}
                        </span>
                      </Typography>
                      <Typography
                        variant="medium"
                        color="text.fade"
                        sx={{
                          fontWeight: 700,
                          px: 1.5,
                          borderRight: "1px solid #DBDADE",
                          display: "inline-flex",
                        }}
                      >
                        Time:&nbsp;&nbsp;
                        <span style={{ color: "#222", fontWeight: 400 }}>
                          {moment(list?.submittedAt).format("hh:mm")}
                        </span>
                      </Typography>
                      <Typography
                        variant="medium"
                        color="text.fade"
                        sx={{
                          fontWeight: 700,
                          px: 1.5,
                          // borderRight: "1px solid #DBDADE",
                          display: "inline-flex",
                        }}
                      >
                        Edits:&nbsp;&nbsp;
                        <span style={{ color: "#222", fontWeight: 400 }}>
                          {parseInt(list.editCount)}
                        </span>
                      </Typography>
                      {/* </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item xs="auto">
                    <EditHistory />
                    &nbsp;&nbsp;
                      <Message phoneNo={location?.state?.data?.phoneNumber} />
                  </Grid>
                </Grid>
              </Grid>

              <Box
                sx={{
                  width: "100%",
                  overflow: "auto",
                  pt: 1.125,
                  // px: 2,
                }}
              >
                <TableContainer
                  style={{
                    maxHeight: "Calc(100vh - 135px)",
                  }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Items</TableCell>
                        <TableCell>Img</TableCell>
                        <TableCell>Link</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Quantity
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Price
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Postage
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Ounces
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Pounds
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>KG</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Total W KG
                        </TableCell>

                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Item Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {list?.shoppingCartList?.map((item, index) => (
                        <TableRow key={item.id}>
                          <TableCell>{index + 1}</TableCell>

                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {item.product?.image === null ? (
                              <img
                                src="/no_image.png"
                                alt=""
                                style={{
                                  maxWidth: "90px",
                                  maxHeight: "120px",
                                }}
                              />
                            ) : (
                              <Tooltip
                                slotProps={{
                                  tooltip: {
                                    sx: {
                                      // color: "#514E6A",
                                      maxWidth: 500,
                                      backgroundColor: "#ffff",
                                      boxShadow:
                                        "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                      p: 3,
                                    },
                                  },
                                }}
                                title={
                                  <div>
                                    <img
                                      src={item.product?.image}
                                      alt=""
                                      style={{
                                        maxWidth: "350px",
                                        maxHeight: "450px",
                                      }}
                                    />
                                  </div>
                                }
                                placement="right"
                              >
                                <img
                                  src={item.product?.image}
                                  alt=""
                                  style={{
                                    maxWidth: "90px",
                                    maxHeight: "120px",
                                  }}
                                />
                              </Tooltip>
                            )}{" "}
                          </TableCell>
                          <TableCell>
                            {/* {item.product?.url && (
                                  <CopyUrl
                                    url={item.product?.url}
                                    websiteName={item.product?.websiteName}
                                  />
                                )} */}
                            <a
                              href={item.product?.url}
                              target="_blank"
                              style={{
                                whiteSpace: "nowrap",
                                color: "#666",
                              }}
                            >
                              {item.product?.websiteName} URL
                            </a>
                          </TableCell>
                          <TableCell>
                            {/* {item.product?.title} */}
                            <Descreption detail={item.product} />{" "}
                          </TableCell>
                          <TableCell>{item.product?.quantity}</TableCell>
                          <TableCell>{item.product?.price}</TableCell>
                          <TableCell>{item.postage}</TableCell>
                          <TableCell>
                            {/* {parseFloat(item.ounces)} */}
                          </TableCell>
                          <TableCell>
                            {/* {parseFloat(item.pounds)} */}
                          </TableCell>
                          <TableCell>{item.product?.weight}</TableCell>
                          <TableCell>{item.totalWeight}</TableCell>
                          <TableCell sx={{ minWidth: "100px" }}>
                            {/* {item?.itemStatus=== null ?    <span style={{ color: "#F23836" }}>Rejected</span> :
                            
                            
                            } */}
                            {item?.itemStatus === null ? (
                              "-------"
                            ) : item?.itemStatus?.statusType === "A_N" ? (
                              <span style={{ color: "#35b522" }}>
                                {" "}
                                Accepted
                              </span>
                            ) : (
                              <span style={{ color: "#F23836" }}>Rejected</span>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      {loading && pageLoading()}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeletedCartDetails;
