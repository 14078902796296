import React, { useContext, useEffect, useState } from "react";
import Countdown from "react-countdown";
import { Box, Breadcrumbs, Grid, TextField, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import MenuIcon from "../icons/MenuIcon";
import { useTheme } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, Button, IconButton, Paper, Skeleton } from "@mui/material";

import { getDataWithToken } from "../../../services/GetDataService";
import { AuthContext } from "../../../context/AuthContext";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import { handlePutData } from "../../../services/PutDataService";
import RefreshToken from "../../../services/RefreshToken";
import Reject from "./Reject";
import CopyUrl from "./CopyUrl";

import { handlePutRowData } from "../../../services/PutRowDataService";
import RejectCart from "./RejectCart";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Descreption from "./Descreption";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import ClearIcon from "@mui/icons-material/Clear";
import Message from "../../message/Message";
import EditHistory from "../edit-history/EditHistory";
const CartDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { id } = useParams();
  const { cartType } = useParams();
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState(location.state?.data?.data);
  const [oldData, setOldData] = useState(location.state?.data?.data);
  const [shoppingCartList, setShoppingCartList] = useState();
  const [invoiceData, setInvoiceData] = useState(
    location.state?.data.data.invoice
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [message, setMessage] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [createdStartTime, setCreatedStartTime] = useState(null);
  const [createdEndTime, setCreatedEndTime] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [postList, setPostList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [statusValue, setStatusValue] = useState(null);
  const [timeRange, setTimeRange] = useState("34");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [count, setCount] = useState("");
  const [countDownDate, setCountDownDate] = useState("");

  const [refresh, setRefresh] = useState(false);
  const [validationIndex, setValidationIndex] = useState();
  const [loading2, setLoading2] = useState(false);
  const [editHistoryList, setEditHistoryList] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  const pageLoading = () => {
    let rows = [];

    for (let i = 0; i < 10; i++) {
      rows.push(
        <Box key={i} sx={{ py: 1.5 }}>
          <Skeleton></Skeleton>
        </Box>
      );
    }

    return rows;
  };

  const postData = async (postType) => {
    console.log("postData-----------------------------working");
    let err = validation();

    if (err) {
      return;
    } else {
      setLoading(true);
      setMessage("");
      // APPROVE
      // let isAAnyItemStatusAcceptExist = shoppingCartList.some(
      //   (obj) => obj.itemStatus.statusType === "A_N"
      // );
      // if (!isAAnyItemStatusAcceptExist) {
      //   //         itemStatus
      //   // :
      //   // {statusType: 'R_N
      //   // shoppingCartList.find

      //   console.log("isAAnyItemStatusAcceptExist", isAAnyItemStatusAcceptExist);
      //   handleSnakbarOpen(
      //     "All cart items is rejected. So you can not approve the cart",
      //     "error"
      //   );
      // } else {
      //   handleSnakbarOpen("you can approve the cart", "success");
      // }
      // return;
      let min = timeRange[0];
      let max = timeRange[1];
      let url = `api/v1/private/admin/live-cart/process`;
      if (cartType === "review") {
        url = `api/v1/private/admin/review-cart/process`;
      }
      let token = await RefreshToken(dizli_admin_panel, logout, login);
      // working
      let editedList = [];
      // r console.log('postdata', postList)
      shoppingCartList.map((item) => {
        let l = {
          shoppingCartItemId: item.id,
          price: item.product.price,
          postage: item.postage,
          weight: item.product.weight,
          itemStatusId: item?.itemStatus?.id || 1,
          // reason: item?.itemStatus?.reason,
          // status: item?.itemStatus?.statusType,
        };
        editedList.push(l);
      });
      // working

      console.log("editedList", editedList);

      let data = {
        cartId: id,
        editData: editedList,
        minDeliveryWeeks: parseInt(min),
        maxDeliveryWeeks: parseInt(max),
        adminComment: null,
        approveType: postType,
      };
      console.log("post", data);

      let res = await handlePutRowData(url, data, token);

      if (res?.status > 199 && res?.status < 300) {
        console.log("response: ", res);
        enqueueSnackbar(res.data.message, {
          variant: "success",
          autoHideDuration: 3000,
        });

        navigate("/live-carts");
      } else {
        console.log("error response: ", res.data?.message);
        enqueueSnackbar(res.data?.message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const getData = async () => {
    handleClickOpen();

    setLoading2(true);
    setMessage("");
    let newPageNO;
    let url = `api/v1/private/admin/cart-edit-history/${id}`;
    // if (cartType === "review") {
    //   url = `api/v1/private/admin/review-cart/${id}`;
    // }

    // let token = dizli_admin_panel.access_token;
    let token = await RefreshToken(dizli_admin_panel, logout, login);

    let res = await getDataWithToken(url, token);

    console.log("res--------------------------------", res.data.data);
    if (res?.status > 199 && res?.status < 300) {
      setEditHistoryList(res.data.data);
    } else {
      enqueueSnackbar(res.data.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setLoading2(false);
  };

  const buttonStyle = {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "14px",
    pr: 2,
    pl: 2.625,
    py: 0.75,
    "& .MuiButton-startIcon": {
      mr: 0.625,
    },
  };
  const customeSelect = {
    background: "#fff",

    "& .MuiInputBase-input": {
      borderRadius: 50,
      position: "relative",
      // backgroundColor: theme.palette.background.paper,
      // border: "1px solid #ced4da",
      fontSize: 14,
      color: "#222",
      padding: "6px 16px 6px 44px",
      // Use the system font instead of the default Roboto font.
    },
  };

  const handleBack = async () => {
    try {
      let url = `/api/v1/private/admin/live-cart/under-process/unmark`;
      // let token = dizli_admin_panel.access_token;
      let token = await RefreshToken(dizli_admin_panel, logout, login);

      let formData = new FormData();
      formData.append("cartId", id);

      let res = await handlePutData(url, formData, token);

      if (cartType === "review") {
        return navigate("/review-carts");
      } else {
        return navigate("/live-carts");
      }
    } catch (error) {
      console.log("errr", error);
    }
  };

  const handleReview = async () => {
    try {
      let url = `/api/v1/private/admin/cart/transfer-to-review-cart`;
      // let token = dizli_admin_panel.access_token;
      let token = await RefreshToken(dizli_admin_panel, logout, login);

      let formData = new FormData();
      formData.append("cartId", id);

      let res = await handlePutData(url, formData, token);
      enqueueSnackbar(res.data.message, {
        variant: "info",
        autoHideDuration: 3000,
      });

      if (cartType === "review") {
        return navigate("/review-carts");
      } else {
        return navigate("/live-carts");
      }
    } catch (error) {
      console.log("errr", error);
    }
  };

  const checkTime = async (s_time) => {
    let currentDate = new Date(s_time);
    // Add  minutes
    let futureDate = new Date(currentDate.getTime() + 10 * 60 * 60000); // 10 hours
    setCountDownDate(futureDate);

    // Get the current date and time again
    let now = new Date();

    // Compare the future date with the current date
    if (futureDate <= now) {
      handleReview();
    }
  };

  // 111

  const handleInputChange = (value, inputName, item, i) => {
    // let product = item.product;

    let newProduct = item.product;
    let newObj = item;
    if (inputName === "price") {
      if (parseFloat(value) > 1000000) {
        handleSnakbarOpen("price shall not exceed 1000000", "error");
      } else {
        newProduct = {
          ...item?.product,
          price: parseFloat(value),
          // price: value === "" ? 0 : parseFloat(value),
        };

        newObj = {
          ...item,
          product: newProduct,
        };
      }
    }
    if (inputName === "ounces") {
      if (parseFloat(value) > 100000000) {
        handleSnakbarOpen("Ounces shall not exceed 1000000", "error");
      } else {
        newProduct = {
          ...item?.product,
          pounds: 0.0,
          ounces: parseFloat(value),
          weight: parseFloat(value * 0.0283495).toFixed(2),
        };
        newObj = {
          ...item,
          product: newProduct,
        };
      }
    }
    if (inputName === "pounds") {
      if (parseFloat(value) > 1000000) {
        handleSnakbarOpen("Pounds shall not exceed 1000000", "error");
      } else {
        newProduct = {
          ...item?.product,
          ounces: 0.0,
          pounds: parseFloat(value),
          weight: parseFloat(value * 0.453592).toFixed(2),
        };
        newObj = {
          ...item,
          product: newProduct,
        };
      }
    }
    if (inputName === "weight") {
      if (parseFloat(value) > 1000000) {
        handleSnakbarOpen("Weight shall not exceed 1000000", "error");
      } else {
        newProduct = {
          ...item?.product,
          ounces: 0.0,
          pounds: 0.0,
          weight: parseFloat(value).toFixed(2),
          // price: value === "" ? 0 : parseFloat(value),
        };
        newObj = {
          ...item,
          product: newProduct,
        };
      }
    }
    if (inputName === "postage") {
      if (parseFloat(value) > 1000000) {
        handleSnakbarOpen("Postage shall not exceed 1000000", "error");
      } else {
        newObj = {
          ...item,
          postage: parseFloat(value),
        };
      }
    }

    let newShoppingCartList = shoppingCartList;
    newShoppingCartList[i] = newObj;
    setShoppingCartList(newShoppingCartList);

    calculateInvoiceData(newShoppingCartList);

    setRefresh(!refresh);
  };
  const calculateInvoiceData = (newShoppingCartList) => {
    console.log(
      "--------------------calculateInvoiceData------------------------------"
    );
    // calculate invoice data
    console.log(
      "location.state?.invoiceCalculationData",
      location.state?.invoiceCalculationData
    );
    let tQuantity = 0;
    let tPrice = 0;
    let tPostage = 0;

    let tWeight = 0;

    newShoppingCartList.map((item, i) => {
      console.log("item?.itemStatus?.statusType", item?.itemStatus?.statusType);
      if (item?.itemStatus?.statusType !== "R_N") {
        if (!isNaN(item?.product?.quantity)) {
          tQuantity = tQuantity + item?.product?.quantity;
        }
        if (!isNaN(item?.product?.price)) {
          tPrice =
            tPrice + parseFloat(item?.product?.price) * item?.product?.quantity;
        }
        if (!isNaN(item?.product?.weight)) {
          tWeight =
            tWeight + parseFloat(item.product?.weight) * item.product?.quantity;
        }
        if (!isNaN(item?.postage)) {
          tPostage = tPostage + parseFloat(item?.postage);
        }
      }
    });

    let shippingCost =
      tWeight * location.state?.invoiceCalculationData?.shippingCharge;
    let customsVATCost =
      (tPrice + tPostage + shippingCost) *
      location.state?.invoiceCalculationData?.customsVATCharge;
    let serviceCost =
      (tPrice + tPostage + shippingCost + customsVATCost) *
      location.state?.invoiceCalculationData?.serviceInsuranceCharge;

    // tPrice + tPostage + shippingCost + customsVATCost + serviceCost
    setInvoiceData({
      ...invoiceData,
      tQuantity: tQuantity,
      tWeight: tWeight,
      itemsPrice: tPrice,
      postage: tPostage,
      shipping: shippingCost,
      customsVAT: customsVATCost,
      service: serviceCost,
      total: tPrice + tPostage + shippingCost + customsVATCost + serviceCost,
      deposit:
        (tPrice + tPostage + shippingCost + customsVATCost + serviceCost) *
        location.state?.invoiceCalculationData?.depositPercentage,
    });
  };
  const check = () => {
    console.log("list", list);
    console.log("oldData", oldData);
    console.log("shoppingCartList", shoppingCartList);
    console.log("invoiceData", invoiceData);
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const validation = () => {
    let isError = false;

    // let isAAnyItemStatusAcceptExist = shoppingCartList.some(
    //   (obj) => obj?.itemStatus?.statusType === "A_N"
    // );
    // if (!isAAnyItemStatusAcceptExist) {
    //   console.log("isAAnyItemStatusAcceptExist", isAAnyItemStatusAcceptExist);
    //   handleSnakbarOpen(
    //     "All cart items is rejected. So you can not approve the cart",
    //     "error"
    //   );
    //   isError = true;
    // }

    for (var i = 0; i < shoppingCartList.length; i++) {
      let item = shoppingCartList[i];
      console.log(
        "item.product.postage",
        isNaN(item.product.postage),
        item.product.postage
      );
      if (item?.itemStatus?.statusType !== "R_N") {
        if (isNaN(item.product.price) || item.product.price <= 0) {
          setValidationIndex(i);
          handleSnakbarOpen("Please enter product price", "error");
          document.getElementById(`price${i}`).focus();

          isError = true;
          break;
        }
        if (isNaN(item.postage)) {
          setValidationIndex(i);
          handleSnakbarOpen("Please enter postage", "error");
          document.getElementById(`postage${i}`).focus();
          isError = true;
          break;
        }
        if (isNaN(item.product.weight) || item.product.weight <= 0) {
          setValidationIndex(i);
          handleSnakbarOpen("Please enter weight", "error");
          document.getElementById(`weight${i}`).focus();
          isError = true;
          break;
        }
        // if (item.itemStatus === null) {
        //   setValidationIndex(i);
        //   handleSnakbarOpen("Please setect status", "error");
        //   document.getElementById(`status${i}`).focus();
        //   isError = true;
        //   break;
        // }
      }
    }

    return isError;
  };

  useEffect(() => {
    let newShoppingCartList = [];
    location.state?.data?.data?.shoppingCartList?.map((item) => {
      if (item.itemStatus === null) {
        // newShoppingCartList.push({
        //   ...item,
        //   itemStatus: {
        //     id: 1,
        //     reason: "",
        //     statusType: "A_N",
        //   },
        // });
        newShoppingCartList.push(item);
      } else {
        newShoppingCartList.push(item);
      }
    });
    setShoppingCartList(newShoppingCartList);
    calculateInvoiceData(location.state?.data?.data?.shoppingCartList);
  }, []);

  return (
    <div>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100vh" }}
      >
        <Grid item sx={{ width: "100%" }}>
          {" "}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 1.5, borderBottom: "1px solid #E5E5E5" }}
          >
            <Grid
              item
              xs="auto"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="text.light"
                sx={{ fontWeight: 600, textTransform: "capitalize" }}
                onClick={check}
              >
                {cartType} Cart Details
                {/* <span style={{ fontWeight: 400 }}>(14)</span>{" "} */}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator="/"
                // className={classes.breadcrumbsStyle}
              >
                {/* <Link to="#">Carts</Link> */}

                <Link
                  to={cartType === "live" ? "/live-carts" : "/review-carts"}
                >
                  {cartType === "live" ? "Live Carts" : "Review Carts"}
                </Link>
                <Link to="#">Process</Link>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: "Calc(100vh - 65px)",
              overflowY: "auto",
              // pt: 2.5,
              px: 2,
            }}
          >
            <Box sx={{ mt: 4 }}>
              <Grid container>
                <Grid
                  item
                  sx={{
                    width: "Calc(100% - 324px)",
                    boxSizing: "border-box",
                    px: 2,
                  }}
                >
                  {" "}
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <Grid item xs="auto">
                      <Grid
                        sx={{
                          background: "#FAFAFA",
                          py: 0.5,
                          borderRadius: "6px",
                        }}
                      >
                        {/* <Grid
                      item
                      xs="auto"
                      sx={{ px: 1.5, borderRight: "1px solid #DBDADE" }}
                    > */}
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{
                            fontWeight: 700,
                            px: 1.5,
                            borderRight: "1px solid #DBDADE",
                            display: "inline-flex",
                          }}
                        >
                          User Phone No: &nbsp;&nbsp;
                          <span style={{ color: "#222", fontWeight: 400 }}>
                            {location?.state?.username}
                          </span>
                        </Typography>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{
                            fontWeight: 700,
                            px: 1.5,
                            borderRight: "1px solid #DBDADE",
                            display: "inline-flex",
                          }}
                        >
                          Cart ID: &nbsp;&nbsp;
                          <span style={{ color: "#222", fontWeight: 400 }}>
                            {id}
                          </span>
                        </Typography>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{
                            fontWeight: 700,
                            px: 1.5,
                            borderRight: "1px solid #DBDADE",
                            display: "inline-flex",
                          }}
                        >
                          Date:&nbsp;&nbsp;
                          <span style={{ color: "#222", fontWeight: 400 }}>
                            {moment(list?.submittedAt).format("MMM DD YYYY")}
                          </span>
                        </Typography>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{
                            fontWeight: 700,
                            px: 1.5,
                            borderRight: "1px solid #DBDADE",
                            display: "inline-flex",
                          }}
                        >
                          Time:&nbsp;&nbsp;
                          <span style={{ color: "#222", fontWeight: 400 }}>
                            {moment(list?.submittedAt).format("hh:mm")}
                          </span>
                        </Typography>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{
                            fontWeight: 700,
                            px: 1.5,
                            // borderRight: "1px solid #DBDADE",
                            display: "inline-flex",
                          }}
                        >
                          Edits:&nbsp;&nbsp;
                          <span style={{ color: "#222", fontWeight: 400 }}>
                            {parseInt(list.editCount)}
                          </span>
                        </Typography>
                        {/* </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item xs="auto">
                      <EditHistory />
                      {/* <Button
                        disableElevation
                        variant="contained"
                        color="success"
                        startIcon={
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.4993 7.53337V4.69338C14.4993 2.00671 13.8727 1.33337 11.3527 1.33337H6.31268C3.79268 1.33337 3.16602 2.00671 3.16602 4.69338V12.2C3.16602 13.9734 4.13936 14.3934 5.31936 13.1267L5.32601 13.12C5.87268 12.54 6.70601 12.5867 7.17934 13.22L7.85268 14.12"
                              stroke="#555555"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.16602 4.66663H11.4993"
                              stroke="#555555"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.83301 7.33337H10.833"
                              stroke="#555555"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.974 9.84685L10.614 12.2069C10.5207 12.3002 10.434 12.4735 10.414 12.6002L10.2873 13.5002C10.2407 13.8269 10.4673 14.0535 10.794 14.0069L11.694 13.8802C11.8207 13.8602 12.0007 13.7735 12.0873 13.6802L14.4474 11.3202C14.854 10.9135 15.0474 10.4402 14.4474 9.8402C13.854 9.24686 13.3807 9.44018 12.974 9.84685Z"
                              stroke="#555555"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.6328 10.1869C12.8328 10.9069 13.3928 11.4669 14.1128 11.6669"
                              stroke="#555555"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                        sx={{
                          ...buttonStyle,
                          background: "#FFECEC",
                          color: "#555555",
                          "&:hover": {
                            background: "#FFC4C4",
                          },
                        }}
                        onClick={getData}
                      >
                        View Edits
                      </Button> */}
                      &nbsp;&nbsp;
                      {cartType !== "review" && (
                        <Button
                          disableElevation
                          variant="contained"
                          onClick={handleReview}
                          // color="success"
                          startIcon={
                            <svg
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.2196 7.99995C11.2196 9.31995 10.153 10.3866 8.83296 10.3866C7.51296 10.3866 6.44629 9.31995 6.44629 7.99995C6.44629 6.67995 7.51296 5.61328 8.83296 5.61328C10.153 5.61328 11.2196 6.67995 11.2196 7.99995Z"
                                stroke="#555555"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.8329 13.5133C11.1862 13.5133 13.3796 12.1266 14.9062 9.72665C15.5062 8.78665 15.5062 7.20665 14.9062 6.26665C13.3796 3.86665 11.1862 2.47998 8.8329 2.47998C6.47957 2.47998 4.28624 3.86665 2.75957 6.26665C2.15957 7.20665 2.15957 8.78665 2.75957 9.72665C4.28624 12.1266 6.47957 13.5133 8.8329 13.5133Z"
                                stroke="#555555"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          sx={{
                            ...buttonStyle,
                            background: "#FFFAE6",
                            color: "#555555",
                            "&:hover": {
                              background: "#FFEFB3",
                            },
                          }}
                        >
                          Further Review
                        </Button>
                      )}
                      &nbsp;&nbsp;
                      <Message phoneNo={location?.state?.username} />
                      {/* <Button
                        disableElevation
                        variant="contained"
                        color="success"
                        startIcon={
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.49935 12.6667H6.16602C3.49935 12.6667 2.16602 12 2.16602 8.66671V5.33337C2.16602 2.66671 3.49935 1.33337 6.16602 1.33337H11.4993C14.166 1.33337 15.4993 2.66671 15.4993 5.33337V8.66671C15.4993 11.3334 14.166 12.6667 11.4993 12.6667H11.166C10.9593 12.6667 10.7593 12.7667 10.6327 12.9334L9.63268 14.2667C9.19268 14.8534 8.47268 14.8534 8.03268 14.2667L7.03268 12.9334C6.92602 12.7867 6.67935 12.6667 6.49935 12.6667Z"
                              stroke="#555555"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.5 5.33337H12.1667"
                              stroke="#555555"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.5 8.66663H9.5"
                              stroke="#555555"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                        sx={{
                          ...buttonStyle,
                          background: "#EFF3FF",
                          color: "#555555",
                          "&:hover": {
                            background: "#CDD9FF",
                          },
                        }}
                      >
                        Text Client
                      </Button> */}
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      width: "100%",
                      overflow: "auto",
                      pt: 1.125,
                    }}
                  >
                    <TableContainer
                      style={{
                        maxHeight: "Calc(100vh - 135px)",
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Items</TableCell>
                            <TableCell>Img</TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Quantity
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Price &nbsp;&nbsp;
                              <svg
                                width="15"
                                height="14"
                                viewBox="0 0 15 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ position: "relative", top: 2 }}
                              >
                                <path
                                  d="M8.56764 2.09996L3.77847 7.16913C3.59764 7.36163 3.42264 7.7408 3.38764 8.0033L3.1718 9.8933C3.09597 10.5758 3.58597 11.0425 4.26264 10.9258L6.14097 10.605C6.40347 10.5583 6.77097 10.3658 6.9518 10.1675L11.741 5.0983C12.5693 4.2233 12.9426 3.2258 11.6535 2.00663C10.3701 0.79913 9.39597 1.22496 8.56764 2.09996Z"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.76855 2.9458C8.01939 4.5558 9.32605 5.78663 10.9477 5.94997"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M2.58301 12.8334H13.083"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Postage &nbsp;&nbsp;
                              <svg
                                width="15"
                                height="14"
                                viewBox="0 0 15 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ position: "relative", top: 2 }}
                              >
                                <path
                                  d="M8.56764 2.09996L3.77847 7.16913C3.59764 7.36163 3.42264 7.7408 3.38764 8.0033L3.1718 9.8933C3.09597 10.5758 3.58597 11.0425 4.26264 10.9258L6.14097 10.605C6.40347 10.5583 6.77097 10.3658 6.9518 10.1675L11.741 5.0983C12.5693 4.2233 12.9426 3.2258 11.6535 2.00663C10.3701 0.79913 9.39597 1.22496 8.56764 2.09996Z"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.76855 2.9458C8.01939 4.5558 9.32605 5.78663 10.9477 5.94997"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M2.58301 12.8334H13.083"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Ounces &nbsp;&nbsp;
                              <svg
                                width="15"
                                height="14"
                                viewBox="0 0 15 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ position: "relative", top: 2 }}
                              >
                                <path
                                  d="M8.56764 2.09996L3.77847 7.16913C3.59764 7.36163 3.42264 7.7408 3.38764 8.0033L3.1718 9.8933C3.09597 10.5758 3.58597 11.0425 4.26264 10.9258L6.14097 10.605C6.40347 10.5583 6.77097 10.3658 6.9518 10.1675L11.741 5.0983C12.5693 4.2233 12.9426 3.2258 11.6535 2.00663C10.3701 0.79913 9.39597 1.22496 8.56764 2.09996Z"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.76855 2.9458C8.01939 4.5558 9.32605 5.78663 10.9477 5.94997"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M2.58301 12.8334H13.083"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Pounds &nbsp;&nbsp;
                              <svg
                                width="15"
                                height="14"
                                viewBox="0 0 15 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ position: "relative", top: 2 }}
                              >
                                <path
                                  d="M8.56764 2.09996L3.77847 7.16913C3.59764 7.36163 3.42264 7.7408 3.38764 8.0033L3.1718 9.8933C3.09597 10.5758 3.58597 11.0425 4.26264 10.9258L6.14097 10.605C6.40347 10.5583 6.77097 10.3658 6.9518 10.1675L11.741 5.0983C12.5693 4.2233 12.9426 3.2258 11.6535 2.00663C10.3701 0.79913 9.39597 1.22496 8.56764 2.09996Z"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.76855 2.9458C8.01939 4.5558 9.32605 5.78663 10.9477 5.94997"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M2.58301 12.8334H13.083"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              KG &nbsp;&nbsp;
                              <svg
                                width="15"
                                height="14"
                                viewBox="0 0 15 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ position: "relative", top: 2 }}
                              >
                                <path
                                  d="M8.56764 2.09996L3.77847 7.16913C3.59764 7.36163 3.42264 7.7408 3.38764 8.0033L3.1718 9.8933C3.09597 10.5758 3.58597 11.0425 4.26264 10.9258L6.14097 10.605C6.40347 10.5583 6.77097 10.3658 6.9518 10.1675L11.741 5.0983C12.5693 4.2233 12.9426 3.2258 11.6535 2.00663C10.3701 0.79913 9.39597 1.22496 8.56764 2.09996Z"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.76855 2.9458C8.01939 4.5558 9.32605 5.78663 10.9477 5.94997"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M2.58301 12.8334H13.083"
                                  stroke="#969696"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Total W KG
                            </TableCell>
                            <TableCell sx={{ minWidth: "180px" }}>
                              Reason
                            </TableCell>

                            {/* <TableCell>Item Status</TableCell> */}
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!loading && shoppingCartList?.length > 0 && (
                            <>
                              {shoppingCartList?.map((item, index) => (
                                <TableRow
                                  key={item.id}
                                  sx={{
                                    background:
                                      validationIndex === index && "#FFECEC",
                                  }}
                                >
                                  <TableCell>{index + 1}</TableCell>
                                  {/* <TableCell>{item.product?.image}</TableCell> */}
                                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                                    {item.product?.image === null ? (
                                      <img
                                        src="/no_image.png"
                                        alt=""
                                        style={{
                                          maxWidth: "90px",
                                          maxHeight: "120px",
                                        }}
                                      />
                                    ) : (
                                      <Tooltip
                                        slotProps={{
                                          tooltip: {
                                            sx: {
                                              // color: "#514E6A",
                                              maxWidth: 500,
                                              backgroundColor: "#ffff",
                                              boxShadow:
                                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                              p: 3,
                                            },
                                          },
                                        }}
                                        title={
                                          <div>
                                            <img
                                              src={item.product?.image}
                                              alt=""
                                              style={{
                                                maxWidth: "350px",
                                                maxHeight: "450px",
                                              }}
                                            />
                                          </div>
                                        }
                                        placement="right"
                                      >
                                        <img
                                          src={item.product?.image}
                                          alt=""
                                          style={{
                                            maxWidth: "90px",
                                            maxHeight: "120px",
                                          }}
                                        />
                                      </Tooltip>
                                    )}{" "}
                                  </TableCell>
                                  <TableCell>
                                    <a
                                      href={item.product?.url}
                                      target="_blank"
                                      style={{
                                        whiteSpace: "nowrap",
                                        fontWeight: 800,
                                        // fontSize: "16px",
                                        background: "#f9f9f9",
                                        padding: "6px 24px",
                                        borderRadius: "10px",
                                        // border: "1px solid #666",
                                        border:
                                          item?.preReviewedStatus === "REJECTED"
                                            ? "1px solid #F23836"
                                            : item?.preReviewedStatus ===
                                              "ACCEPTED"
                                            ? "1px solid #35b522"
                                            : "1px solid #666",
                                        color:
                                          item?.preReviewedStatus === "REJECTED"
                                            ? "#F23836"
                                            : item?.preReviewedStatus ===
                                              "ACCEPTED"
                                            ? "#35b522"
                                            : "#666",

                                        // border: "1px solid #35b522",
                                        // color: "#35b522",
                                      }}
                                    >
                                      {item.product?.websiteName} URL
                                    </a>
                                  </TableCell>
                                  <TableCell>
                                    {" "}
                                    <Descreption detail={item.product} />{" "}
                                  </TableCell>
                                  <TableCell>
                                    {item.product?.quantity}
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      id={`price${index}`}
                                      variant="standard"
                                      sx={{
                                        backgroundColor: "#FFFAE7",
                                        px: 1,
                                        width: "100px",
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                        inputProps: {
                                          min: 0,
                                          maxLength: 100,
                                          step: 1,
                                        },
                                      }}
                                      type="number"
                                      size="small"
                                      value={parseFloat(item.product?.price)}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          "price",
                                          item,
                                          index
                                        )
                                      }
                                      // onBlur={handleBlur}
                                      // disabled={
                                      //   item.itemStatus?.statusType === "R_N" ||
                                      //   (item.itemStatus?.statusType === "R_N" &&
                                      //     item.itemStatus?.statusType !== "A_N")
                                      // }
                                      disabled={
                                        item.itemStatus?.statusType === "R_N"
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      id={`postage${index}`}
                                      variant="standard"
                                      sx={{
                                        backgroundColor: "#FFFAE7",
                                        px: 1,
                                        width: "100px",
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                        inputProps: {
                                          min: 0,
                                          maxLength: 100,
                                          step: 1,
                                        },
                                      }}
                                      type="number"
                                      size="small"
                                      value={parseFloat(item.postage)}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          "postage",
                                          item,
                                          index
                                        )
                                      }
                                      // onBlur={handleBlur}
                                      // disabled={
                                      //   item.itemStatus?.statusType === "R_N" ||
                                      //   (item.itemStatus?.statusType === "R_N" &&
                                      //     item.itemStatus?.statusType !== "A_N")
                                      // }
                                      disabled={
                                        item.itemStatus?.statusType === "R_N"
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      id={`ounces${index}`}
                                      type="number"
                                      variant="standard"
                                      sx={{
                                        backgroundColor: "#FFFAE7",
                                        px: 1,
                                        width: "100px",
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                        inputProps: {
                                          min: 0,
                                          max: 100,
                                          step: 1,
                                        },
                                      }}
                                      size="small"
                                      value={parseFloat(item.product?.ounces)}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          "ounces",
                                          item,
                                          index
                                        )
                                      }
                                      // onBlur={handleBlur}
                                      // disabled={
                                      //   item.itemStatus?.statusType === "R_N" ||
                                      //   (item.itemStatus?.statusType === "R_N" &&
                                      //     item.itemStatus?.statusType !== "A_N")
                                      // }

                                      disabled={
                                        item.itemStatus?.statusType === "R_N"
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      id={`pounds${index}`}
                                      type="number"
                                      variant="standard"
                                      sx={{
                                        backgroundColor: "#FFFAE7",
                                        px: 1,
                                        width: "100px",
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                        inputProps: {
                                          min: 0,
                                          max: 100,
                                          step: 1,
                                        },
                                      }}
                                      size="small"
                                      value={parseFloat(item.product?.pounds)}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          "pounds",
                                          item,
                                          index
                                        )
                                      }
                                      // onBlur={handleBlur}
                                      // disabled={
                                      //   item.itemStatus?.statusType === "R_N" ||
                                      //   (item.itemStatus?.statusType === "R_N" &&
                                      //     item.itemStatus?.statusType !== "A_N")
                                      // }

                                      disabled={
                                        item.itemStatus?.statusType === "R_N"
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      id={`weight${index}`}
                                      type="number"
                                      variant="standard"
                                      sx={{
                                        backgroundColor: "#FFFAE7",
                                        px: 1,
                                        width: "100px",
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                        inputProps: {
                                          min: 0,
                                          max: 100,
                                          step: 1,
                                        },
                                      }}
                                      size="small"
                                      value={parseFloat(item.product?.weight)}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e.target.value,
                                          "weight",
                                          item,
                                          index
                                        )
                                      }
                                      // onBlur={handleBlur}
                                      // disabled={
                                      //   item.itemStatus?.statusType === "R_N" ||
                                      //   (item.itemStatus?.statusType === "R_N" &&
                                      //     item.itemStatus?.statusType !== "A_N")
                                      // }

                                      disabled={
                                        item.itemStatus?.statusType === "R_N"
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {isNaN(item?.product?.weight)
                                      ? 0
                                      : (parseFloat(item.product?.weight) *
                                        item.product?.quantity).toFixed(4)}
                                  </TableCell>
                                  <TableCell sx={{ minWidth: "100px" }}>
                                    {item?.itemStatus?.reason}
                                  </TableCell>
                                  {/* <TableCell>
                                {item.itemStatus?.statusType === null
                                  ? "-------"
                                  : item.itemStatus?.statusType}
                              </TableCell> */}
                                  <TableCell>
                                    <Box sx={{ display: "flex", gap: 1 }}>
                                      <Reject
                                        item={item}
                                        index={index}
                                        list={list}
                                        shoppingCartList={shoppingCartList}
                                        setShoppingCartList={
                                          setShoppingCartList
                                        }
                                        setInvoiceData={setInvoiceData}
                                        invoiceData={invoiceData}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                        calculateInvoiceData={
                                          calculateInvoiceData
                                        }
                                      />
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}

                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell> </TableCell>
                                <TableCell> </TableCell>
                                <TableCell> </TableCell>
                                <TableCell></TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>
                                  {invoiceData?.tQuantity}
                                </TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>
                                  {invoiceData?.itemsPrice?.toFixed(2)}
                                </TableCell>
                                <TableCell
                                  sx={{ whiteSpace: "nowrap" }}
                                ></TableCell>
                                <TableCell
                                  sx={{ whiteSpace: "nowrap" }}
                                ></TableCell>
                                <TableCell
                                  sx={{ whiteSpace: "nowrap" }}
                                ></TableCell>
                                <TableCell
                                  sx={{ whiteSpace: "nowrap" }}
                                ></TableCell>
                                <TableCell>
                                  {invoiceData?.tWeight.toFixed(2)}
                                </TableCell>
                                <TableCell> </TableCell>

                                <TableCell> </TableCell>
                              </TableRow>
                            </>
                          )}

                          {loading &&
                            shoppingCartList?.length < 1 &&
                            pageLoading()}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {!loading && shoppingCartList?.length < 1 ? (
                      <Box sx={{ textAlign: "center", p: 2 }}>
                        <strong> No Data Found</strong>
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "324px",
                    boxSizing: "border-box",
                    background: "#FAFAFA",
                    p: 1.5,
                  }}
                >
                  <Box sx={{ textAlign: "right", color: "red" }}>
                    {countDownDate && <Countdown date={countDownDate} />}
                  </Box>
                  <Typography
                    variant="medium"
                    color="text.main"
                    sx={{ fontWeight: 700, mb: 0.5 }}
                  >
                    Delivery Time
                  </Typography>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ mb: 3, ...customeSelect }}
                  >
                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ position: "relative", left: 16, top: 25 }}
                    >
                      <path
                        d="M6.16602 1.33337V3.33337"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.5 1.33337V3.33337"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.16602 6.06006H14.4993"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.833 5.66671V11.3334C14.833 13.3334 13.833 14.6667 11.4997 14.6667H6.16634C3.83301 14.6667 2.83301 13.3334 2.83301 11.3334V5.66671C2.83301 3.66671 3.83301 2.33337 6.16634 2.33337H11.4997C13.833 2.33337 14.833 3.66671 14.833 5.66671Z"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.2965 9.13338H11.3025"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.2965 11.1334H11.3025"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.82967 9.13338H8.83566"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.82967 11.1334H8.83566"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.36287 9.13338H6.36886"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.36287 11.1334H6.36886"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // sx={{
                      //   ...customeSelect,
                      // }}
                      value={timeRange}
                      onChange={handleTimeRangeChange}
                    >
                      <MenuItem value="34">
                        {invoiceData?.minDeliveryWeeks}-
                        {invoiceData?.maxDeliveryWeeks} Weeks
                      </MenuItem>
                      {/* <MenuItem value="45">4-5 Weeks</MenuItem> */}
                    </Select>
                  </FormControl>
                  <Typography
                    variant="medium"
                    color="text.main"
                    sx={{ fontWeight: 700, mb: 1.5 }}
                  >
                    Delivery Invoice
                  </Typography>
                  <Box
                    sx={{
                      background: "#fff",
                      p: 1.5,
                      mb: 3,
                      height: "Calc(100vh - 490px)",
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{ fontWeight: 700, width: "142px" }}
                        >
                          Item price
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceData?.itemsPrice).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{ fontWeight: 700, width: "142px" }}
                        >
                          Postage
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceData.postage).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{ fontWeight: 700, width: "142px" }}
                        >
                          Shipping
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceData.shipping).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{ fontWeight: 700, width: "142px" }}
                        >
                          Customs/VAT
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceData.customsVAT).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{ fontWeight: 700, width: "142px" }}
                        >
                          Service
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceData.service).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2, borderTop: "1px solid #E5E5E5" }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.main"
                          sx={{ fontWeight: 700, width: "142px" }}
                        >
                          Total
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceData.total).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.main"
                          sx={{ fontWeight: 400, width: "142px" }}
                        >
                          Deposit
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceData.deposit).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <RejectCart cartId={id} />
                  <Button
                    color="success"
                    variant="outlined"
                    fullWidth
                    onClick={(e) => postData("APPROVE_EST")}
                    sx={{
                      ...buttonStyle,
                      py: 1.75,
                      mb: 1.25,
                    }}
                  >
                    <svg
                      width="13"
                      height="10"
                      viewBox="0 0 13 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.33301 5.00008L4.66242 8.33341L11.333 1.66675"
                        stroke="#68C81C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    &nbsp; Approve EST.
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    disableElevation
                    fullWidth
                    onClick={(e) => postData("APPROVE")}
                    sx={{
                      ...buttonStyle,
                      py: 1.75,
                      mb: 1.25,
                    }}
                  >
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 10.0001L10.8294 13.3334L17.5 6.66675"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.16602 10.0001L7.49543 13.3334L14.166 6.66675"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    &nbsp; Approve
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default CartDetails;
