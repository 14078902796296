import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import MenuIcon from "../icons/MenuIcon";
import { useTheme } from "@mui/material/styles";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Chip from "@mui/material/Chip";
import {
  Avatar,
  Button,
  IconButton,
  Paper,
  Skeleton,
  TextField,
} from "@mui/material";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Collapse from "@mui/material/Collapse";
import { getDataWithToken } from "../../services/GetDataService";
import { AuthContext } from "../../context/AuthContext";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RefreshToken from "../../services/RefreshToken";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { handlePostData } from "../../services/PostDataService";
import PulseLoader from "react-spinners/PulseLoader";
import { handlePutRowData } from "../../services/PutRowDataService";
import { handlePutData } from "../../services/PutDataService";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddEditCartValidityTime from "./AddEditCartValidityTime";

const CartValidityTimeList = () => {
  const theme = useTheme();
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [name, setName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [gender, setGender] = useState("");
  const [status, setStatus] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [message, setMessage] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [createdStartTime, setCreatedStartTime] = useState(null);
  const [createdEndTime, setCreatedEndTime] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [codStatusUpdateOpen, setCodStatusUpdateOpen] = useState(false);
  const [newDeleteValue, setNewDeleteValue] = useState({});
  const [selectedForDelete, setSelectedForDelete] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateStatusValue, setUpdateStatusValue] = useState("");
  const [editObj, setEditObj] = useState({});
  const [editIndexNo, setEditIndexNo] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [errors, setErrors] = useState({});
  const [formDialog, setFormDialog] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const openFormDialog = () => {
    setFormDialog(true);
  };
  const closeFormDialog = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setFormDialog(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    getData(0, event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    // let pageNo = newPage + 1;
    console.log("newPage", newPage);
    getData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };
  const pageLoading = () => {
    let rows = [];

    for (let i = 0; i < 15; i++) {
      let cells = [];

      for (let j = 0; j < 5; j++) {
        cells.push(
          <TableCell key={j} sx={{ py: 1.5 }}>
            <Skeleton></Skeleton>
          </TableCell>
        );
      }

      rows.push(<TableRow key={i}>{cells}</TableRow>);
    }

    return rows;
  };

  const getData = async (pageNO, pageLimit, newUrl) => {
    setLoading(true);
    setMessage("");
    let newPageNO;
    let newLimit;
    // let url;
    if (pageNO) {
      newPageNO = pageNO - 1;
    } else {
      newPageNO = 0;
    }
    if (pageLimit) {
      newLimit = pageLimit;
    } else {
      newLimit = limit;
    }

    // let url = `/api/v1/public/admin/dashboard-category?size=${newLimit}&page=${parseInt(
    //   newPageNO
    // )}`;
    let url = `/api/v1/private/admin/cart-validity-time/all`;
    console.log("dizli_admin_panel", dizli_admin_panel);

    let token = await RefreshToken(dizli_admin_panel, logout, login);

    console.log("token -----------------", token);
    // let token = dizli_admin_panel.access_token;
    let res = await getDataWithToken(url, token);
    console.log("res", res?.data?.data);
    // if (res?.status === 401 || res?.status === 403) {
    //   logout();
    //   return;
    // }

    if (res?.status > 199 && res?.status < 300) {
      // setTotalElements(res.data.data.totalElements);
      // setTotalPage(res.data.data.totalPages);
      // setRowsPerPage(res.data.data.per_page);
      if (res.data.data.length > 0) {
        setList(res.data.data);
      } else {
        setMessage("No data found");
        setList([]);
      }
    } else {
      setMessage(res.data.message);
    }
    setLoading(false);
  };
  const handleDelete = async (updateStatus) => {
    try {
      setDeleteLoading(true);

      let token = await RefreshToken(dizli_admin_panel, logout, login);
      let response = await axios({
        method: "delete",
        url: `/api/v1/private/admin/dashboard-category/${selectedForDelete.id}`,

        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        let newUserObj = { ...selectedForDelete, codStatus: updateStatus };
        list[newDeleteValue.index] = newUserObj;
        handleSnakbarOpen("Delete successfully", "success");
        getData();
        handleDeleteClose();
      } else {
        handleSnakbarOpen(response.data.messages.toString(), "error");
      }
    } catch (error) {
      console.log("error", error);
      setDeleteLoading(false);
      handleSnakbarOpen(error.response.data.messages.toString(), "error");
    }
    setDeleteLoading(false);
  };
  const buttonStyle = {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "14px",
    pr: 2,
    pl: 2.625,
    py: 0.75,
    "& .MuiButton-startIcon": {
      mr: 0.625,
    },
  };
  const customeTextFeild = {
    // padding: "15px 20px",
    background: "#FAFAFA",
    "& label.Mui-focused": {
      color: "#A0AAB4",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#B2BAC2",
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 24px 15px 0px",
    },
    "& .MuiOutlinedInput-root": {
      paddingLeft: "24px",
      "& fieldset": {
        borderColor: "rgba(0,0,0,0)",
      },

      "&:hover fieldset": {
        borderColor: "#969696",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#969696",
      },
    },
  };

  const convertSeconds = (seconds) => {
    // Calculate years
    const year = Math.floor(seconds / (365 * 24 * 3600));
    seconds %= 365 * 24 * 3600;

    // Calculate months
    const month = Math.floor(seconds / (30 * 24 * 3600));
    seconds %= 30 * 24 * 3600;

    // Calculate days
    const day = Math.floor(seconds / (24 * 3600));
    seconds %= 24 * 3600;

    // Calculate hours
    const hour = Math.floor(seconds / 3600);
    seconds %= 3600;

    // Calculate minutes
    const minute = Math.floor(seconds / 60);

    // Remaining seconds
    const second = seconds % 60;

    let result = "";
    if (year !== 0) result += year + " year ";
    if (month !== 0) result += month + " month ";
    if (day !== 0) result += day + " day ";
    if (hour !== 0) result += hour + " hour ";
    if (minute !== 0) result += minute + " minute ";
    if (second !== 0) result += second + " second ";

    // Trim any trailing whitespace
    result = result.trim();

    return result;
  };
  useEffect(() => {
    // setLoading(true);
    getData();
  }, []);
  return (
    <div>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100vh" }}
      >
        <Grid item sx={{ width: "100%" }}>
          {" "}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 2.125, borderBottom: "1px solid #E5E5E5" }}
          >
            <Grid item xs="auto">
              <Typography
                variant="h6"
                color="text.light"
                sx={{ fontWeight: 600 }}
              >
                Cart Validity Time List{" "}
                {/* <span style={{ fontWeight: 400 }}>({totalElements})</span> */}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Breadcrumbs aria-label="breadcrumb" separator="/">
                <Link to="#">Cart Validity Time</Link>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: "Calc(100vh - 135px)",
              // background: "red",
              overflowY: "auto",
              // pt: 2.5,
              px: 2,
            }}
          >
            <Box sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer
                sx={{
                  // overflowX: "auto",
                  // minWidth: "100%",
                  // width: "Calc(100vw - 385px)",
                  maxHeight: "Calc(100vh - 246px)",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Cart Stage
                      </TableCell>

                      <TableCell>Duration</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Updated By
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Updated At
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading &&
                      list?.length > 0 &&
                      list?.map((row, i) => (
                        <TableRow
                          key={i}
                          // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {row?.cartStage.replace(/_/g, " ")}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {convertSeconds(row?.durationInSeconds)}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {row?.updatedBy?.username}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: "nowrap" }}>
                            {moment(row?.updatedAt).format(
                              "DD-MM-YYYY , h:mm:ss a"
                            )}
                          </TableCell>

                          <TableCell
                            sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                          >
                            <Button
                              disableElevation
                              variant="contained"
                              color="warning"
                              onClick={() => {
                                setEditIndexNo(i);
                                setEditObj(row);
                                setFormDialog(true);
                              }}
                              sx={{
                                background: "#FFF4CC",
                                color: theme.palette.text.light,
                                fontSize: "12px",
                                fontWeight: 700,
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          {/* <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {row?.deposit.toFixed(2)}
                        </TableCell> */}

                          {/* <TableCell sx={{ whiteSpace: "nowrap", p: 0 }}>
                          <Button
                            disableElevation
                            variant="contained"
                            color="warning"
                            component={Link}
                            to={`/cart-details/${row?.cartId}`}
                            sx={{
                              background: "#D9F1F1",
                              color: theme.palette.text.light,
                              fontSize: "12px",
                              fontWeight: 700,
                              "&:hover": {
                                background: "#B5F5F5",
                              },
                            }}
                          >
                            View
                          </Button>
                        </TableCell> */}
                          {/* <TableCell
                          sx={{ whiteSpace: "nowrap", p: 0 }}
                          align="right"
                        >
                          <Button
                            disableElevation
                            variant="contained"
                            color="warning"
                            component={Link}
                            to={`/cart-details/${row?.cartId}`}
                            sx={{
                              background: "#EFF3FF",
                              color: theme.palette.text.light,
                              fontSize: "12px",
                              fontWeight: 700,
                              "&:hover": {
                                background: "#CDD9FF",
                              },
                            }}
                          >
                            View
                          </Button>
                        </TableCell> */}
                        </TableRow>
                      ))}

                    {loading && pageLoading()}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {!loading && list?.length < 1 ? (
              <Box sx={{ textAlign: "center", p: 2 }}>
                <strong> No Data Found</strong>
              </Box>
            ) : null}
          </Box>
        </Grid>
        <Grid
          item
          xs="auto"
          sx={{
            px: 2,
            py: 1.75,
            borderTop: "1px solid #E5E5E5",
            width: "100%",
          }}
        >
          {/* {list?.length > 0 && ( */}
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs="auto">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs="auto">
                  <Typography
                    variant="medium"
                    color="text.light"
                    // sx={{ display: "inline-flex" }}
                  >
                    Showing&nbsp; &nbsp;
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box>
                    <FormControl size="small">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={limit}
                        onChange={handleLimitChange}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                    &nbsp; &nbsp;
                  </Box>
                </Grid>
                {/* <Grid item xs="auto">
                  <Typography
                    variant="medium"
                    color="text.light"
                 
                  >
                    of 100
                  </Typography>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs="auto">
              {" "}
              <Pagination
                sx={{ display: "inline-block" }}
                count={totalPage}
                showFirstButton
                showLastButton
                shape="rounded"
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>
          {/* )} */}
        </Grid>
      </Grid>
      <Dialog
        open={formDialog}
        onClose={closeFormDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 4, minWidth: "500px" }}>
          <AddEditCartValidityTime
            getData={getData}
            setPage={setPage}
            list={list}
            setList={setList}
            editObj={editObj}
            setEditObj={setEditObj}
            editIndexNo={editIndexNo}
            refresh={refresh}
            setRefresh={setRefresh}
            closeFormDialog={closeFormDialog}
            formDialog={formDialog}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            minWidth: "450px",
            position: "relative",
            px: 4,
            pt: 5,
            textAlign: "center",
          }}
        >
          {/* <b>{"Mark Order as Delivered"}</b> */}
          <IconButton
            onClick={handleDeleteClose}
            style={{ position: "absolute", right: 24, top: 10 }}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 4 }}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", fontWeight: 600, mb: 2 }}
          >
            {" "}
            Are you sure!!!
          </Typography>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            {" "}
            You Want to Delete:{" "}
            <b>
              <i>{selectedForDelete?.name}</i>
            </b>{" "}
            Category
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", px: 4, pb: 5 }}>
          <Button
            variant="outlined"
            color="text"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            disabled={deleteLoading}
            startIcon={<ClearIcon />}
            onClick={handleDeleteClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            disabled={deleteLoading}
            startIcon={<DeleteOutlineOutlinedIcon />}
            onClick={handleDelete}
          >
            {!deleteLoading && "Delete"}
            <PulseLoader
              color={"#353b48"}
              loading={deleteLoading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CartValidityTimeList;
