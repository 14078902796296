import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import MenuIcon from "../icons/MenuIcon";
import { useTheme } from "@mui/material/styles";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Chip from "@mui/material/Chip";
import {
  Avatar,
  Button,
  IconButton,
  Paper,
  Skeleton,
  TextField,
} from "@mui/material";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Collapse from "@mui/material/Collapse";
import { getDataWithToken } from "../../services/GetDataService";
import { AuthContext } from "../../context/AuthContext";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RefreshToken from "../../services/RefreshToken";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { handlePostData } from "../../services/PostDataService";
import PulseLoader from "react-spinners/PulseLoader";
import { handlePutRowData } from "../../services/PutRowDataService";
import { handlePutData } from "../../services/PutDataService";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const CurrencyConversionList = () => {
  const theme = useTheme();
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [message, setMessage] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [editIndexNo, setEditIndexNo] = useState(0);
  const [errors, setErrors] = useState({});
  const [formDialog, setFormDialog] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [rate, setRate] = useState("");
  const [usdData, setUsddata] = useState({});
  const [iqdData, setIqdData] = useState({});

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const getData = async () => {
    setLoading(true);
    setMessage("");

    let url = `/api/v1/private/admin/all-base-currency`;
    console.log("dizli_admin_panel", dizli_admin_panel);

    let token = await RefreshToken(dizli_admin_panel, logout, login);

    let res = await getDataWithToken(url, token);

    if (res?.status > 199 && res?.status < 300) {
      if (res.data.data?.length > 0) {
        setList(res.data.data);

        setUsddata(res.data.data?.find((res) => res.sourceCurrency === "USD"));
        setIqdData(res.data.data?.find((res) => res.sourceCurrency === "IQD"));
      } else {
        setMessage("No data found");
        setList([]);
      }
    } else {
      setMessage(res.data.message);
    }
    setLoading(false);
  };
  const validation = () => {
    let isError = false;

    console.log("rate", rate);
    console.log("parseFloat(rate)", parseFloat(rate));
    if (parseFloat(rate) < 0 || isNaN(parseFloat(rate))) {
      handleSnakbarOpen("Please enter conversion rate", "error");
      document.getElementById("rate").focus();
      return (isError = true);
    }

    return isError;
  };

  const handleSubmit = async (e) => {
    // console.log("editIndexNo", editIndexNo);
    e.preventDefault();
    let err = validation();
    if (err) {
      return;
    } else {
      setUpdateLoading(true);
      let response;
      let token = await RefreshToken(dizli_admin_panel, logout, login);

      try {
        const formData = new FormData();
        formData.append("usdToSourceRate", rate);

        let url = `api/v1/private/admin/currency-conversion-rate/${iqdData.id}`;
        response = await handlePutData(url, formData, token, true);

        if (response?.status > 199 && response?.status < 300) {
          getData();
          setRate("");
          handleSnakbarOpen(response?.data?.message, "success");
        }
        setUpdateLoading(false);
      } catch (error) {
        setUpdateLoading(false);
        if (error?.response?.status === 500) {
          handleSnakbarOpen(error?.response?.statusText, "error");
        } else {
          setErrors(error.response.data.errors);
        }
      }
    }
    setUpdateLoading(false);
  };
  useEffect(() => {
    // setLoading(true);
    getData();
  }, []);
  return (
    <div>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100vh" }}
      >
        <Grid item sx={{ width: "100%" }}>
          {" "}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 2.125, borderBottom: "1px solid #E5E5E5" }}
          >
            <Grid item xs="auto">
              <Typography
                variant="h6"
                color="text.light"
                sx={{ fontWeight: 600 }}
              >
                Currency Conversion
                {/* <span style={{ fontWeight: 400 }}>({totalElements})</span> */}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Breadcrumbs aria-label="breadcrumb" separator="/">
                <Link to="#">Currency Conversion</Link>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: "Calc(100vh - 135px)",
              // background: "red",
              overflowY: "auto",
              // pt: 2.5,
              px: 2,
            }}
          >
            {/* <Box sx={{ mt: 4, mb: 2, textAlign: "right" }}>
              <Button
                disableElevation
                variant="contained"
                color="info"
                startIcon={<AddOutlinedIcon />}
                sx={{
                  ...buttonStyle,
                }}
                onClick={(e) => {
                  setFormDialog(true);
                }}
              >
                Add New
              </Button>
            </Box> */}
            <Box sx={{ width: "100%", overflow: "hidden", mt: 5 }}>
              <Box
                sx={{
                  border: "1px solid #E5E5E5",

                  pb: 6,
                  mt: 3,
                  width: "600px",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h3"
                  // color="text.light"
                  sx={{ fontWeight: 600, mb: 3, background: "#e5e5e5", p: 2 }}
                >
                  Currency Conversion
                  {/* <span style={{ fontWeight: 400 }}>({totalElements})</span> */}
                </Typography>
                {!loading && list?.length > 0 ? (
                  <Box>
                    <Typography variant="h3" sx={{ mb: 1 }}>
                      {usdData?.usdToSourceRate}&nbsp; {usdData?.sourceCurrency}{" "}
                      &nbsp;&nbsp;= &nbsp;&nbsp;
                      {iqdData?.usdToSourceRate}&nbsp; {iqdData?.sourceCurrency}
                    </Typography>
                    <Typography
                      variant="base"
                      color="text.light"
                      sx={{ mb: 3 }}
                    >
                      Updated on :{" "}
                      {moment(iqdData?.updatedAt).format("MMM DD, YYYY")}
                    </Typography>
                    <Box
                      sx={{
                        borderTop: "1px solid #E5E5E5",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: 600,
                          mb: 2,
                          background: "#e5e5e5",
                          p: 2,
                        }}
                      >
                        Set Conversion Rate
                      </Typography>
                      <TextField
                        // label="With normal TextField"
                        type="number"
                        id="rate"
                        sx={{
                          width: "30ch",
                          margin: "auto",
                          "& input": {
                            borderLeft: "2px solid #E5E5E5",
                            pl: 2,
                            fontSize: "20px",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ color: "#222", px: 1, fontWeight: 500 }}
                            >
                              {usdData?.usdToSourceRate}{" "}
                              {usdData?.sourceCurrency}
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ "& .MuiTypography-root":{color: "#222", px: 1, fontWeight: 500} }}
                            >
                              IQD
                            </InputAdornment>
                          ),
                        }}
                        value={rate}
                        onChange={(e) => setRate(e.target.value)}
                      />

                      <Box sx={{ mt: 2, textAlign: "center" }}>
                        <Button
                          variant="contained"
                          color="success"
                          disableElevation
                          sx={{ minHeight: "50px", minWidth: "180px" }}
                          disabled={updateLoading}
                          type="submit"
                          // startIcon={<DoneAllIcon />}
                          onClick={handleSubmit}
                        >
                          {/* {updateStatusValue !== "VERIFIED" && "Submit"} */}
                          Update
                          <PulseLoader
                            color={"#353b48"}
                            loading={updateLoading}
                            size={10}
                            speedMultiplier={0.5}
                          />{" "}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Skeleton variant="rectangular" height={400} />
                )}
              </Box>
            </Box>
            {!loading && list?.length < 1 ? (
              <Box sx={{ textAlign: "center", p: 2 }}>
                <strong> No Data Found</strong>
              </Box>
            ) : null}
          </Box>
        </Grid>
        {/* <Grid
          item
          xs="auto"
          sx={{
            px: 2,
            py: 1.75,
            borderTop: "1px solid #E5E5E5",
            width: "100%",
          }}
        >
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs="auto">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs="auto">
                  <Typography
                    variant="medium"
                    color="text.light"
                    // sx={{ display: "inline-flex" }}
                  >
                    Showing&nbsp; &nbsp;
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box>
                    <FormControl size="small">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={limit}
                        onChange={handleLimitChange}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                    &nbsp; &nbsp;
                  </Box>
                </Grid>
              
              </Grid>
            </Grid>
            <Grid item xs="auto">
              {" "}
              <Pagination
                sx={{ display: "inline-block" }}
                count={totalPage}
                showFirstButton
                showLastButton
                shape="rounded"
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>
   
        </Grid> */}
      </Grid>
    </div>
  );
};

export default CurrencyConversionList;
