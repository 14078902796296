import React from "react";
import Countdown from "react-countdown";

const Test = () => {
  const Completionist = () => <span>You are good to go!</span>;

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  return (
    <div>
      Test
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <Countdown date={Date.now() + 5000} renderer={renderer} />
    </div>
  );
};

export default Test;
