import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import RefreshToken from "../../services/RefreshToken";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InvoiceModal from "./InvoiceModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 4,
};

const DeliveryReplaceList = ({ row, i, getData }) => {
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [newUpdateValue, setNewUpdateValue] = useState({});
  const [selectedForUpdate, setSelectedForUpdate] = useState({});
  const [codStatusUpdateOpen, setCodStatusUpdateOpen] = useState(false);

  const [dropDownstatus, setDropdownStatus] = useState("OPEN");
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [coNumberFieldValue, setCoNumberFieldValue] = useState("");
  const [weyBillFieldValue, setWeyBillFieldValue] = useState("");
  const [commentFieldValue, setCommentFieldValue] = useState("");
  const theme = useTheme();

  //modal..................!
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setLoading(true);
    setOpen(true);
  };
  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };

  //get phoneNumber................!
  const Number = row?.phoneNumber;

  const showStatus = (status) => {
    const parts = status.split("_");
    const lastValue = parts[parts.length - 1];
    return lastValue;
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  //get form value.................!
  const handleChangeCoNumber = (event) => {
    setCoNumberFieldValue(event.target.value);
  };
  const handleChangeWayBill = (event) => {
    setWeyBillFieldValue(event.target.value);
  };
  const handleChangeComment = (event) => {
    setCommentFieldValue(event.target.value);
  };
  const handleChangeDropDown = (event) => {
    setDropdownStatus(event.target.value);
  };

  //set updated data.....................!
  const setUpdateData = () => {
    setCoNumberFieldValue(row?.coNumber || "");
    setWeyBillFieldValue(row?.wayBillNumber || "");
    setCommentFieldValue(row?.commentOnDelivery || "");
  };

  const cartID = row?.cartId;

  //handleDeliveredData..............!
  const handleDeliveredData = async (id) => {
    try {
      let formData = new FormData();
      formData.append("coNumber", coNumberFieldValue);
      formData.append("wayBillNumber", weyBillFieldValue);
      formData.append("commentOnDelivery", commentFieldValue);
      formData.append(
        "status",
        dropDownstatus !== "OPEN" ? dropDownstatus : ""
      );

      let token = await RefreshToken(dizli_admin_panel, logout, login);

      let response = await axios({
        method: "put",
        url: `/api/v1/private/admin/delivery-list/${id}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response?.status >= 200 && response?.status < 300) {
        handleSnakbarOpen("Update successful", "success");
        getData();
      } else {
        handleSnakbarOpen(response.data.message || "Update failed", "error");
      }
    } catch (error) {
      handleSnakbarOpen(
        error.response?.data?.message ||
          "An error occurred during the update. Please try again.",
        "error"
      );
    }
  };

  useEffect(() => {
    setUpdateData();
  }, []);

  return (
    <>
      <TableRow>
        <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
          {row?.date !== null
            ? moment(row?.date).format("MMM DD, YYYY")
            : "-------"}
        </TableCell>
        <TableCell
          sx={{ whiteSpace: "nowrap", justifyContent: "center" }}
          align="center"
        >
          {row?.cartId}
        </TableCell>
        <TableCell
          sx={{ whiteSpace: "nowrap", justifyContent: "center" }}
          align="center"
        >
          {row?.clientName}
        </TableCell>
        <TableCell
          sx={{ whiteSpace: "nowrap", justifyContent: "center" }}
          align="center"
        >
          {row?.phoneNumber}
        </TableCell>
        <TableCell
          sx={{ whiteSpace: "nowrap", justifyContent: "center" }}
          align="center"
        >
          <TextField
            variant="standard"
            sx={{
              backgroundColor: "#FFFAE7",
              px: 1,
              width: "100px",
              justifyContent: "center",
            }}
            InputProps={{ disableUnderline: true }}
            type="text"
            size="small"
            value={coNumberFieldValue}
            onChange={handleChangeCoNumber}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
          <TextField
            variant="standard"
            sx={{
              backgroundColor: "#FFFAE7",
              px: 1,
              width: "100px",
              justifyContent: "center",
            }}
            InputProps={{ disableUnderline: true }}
            type="text"
            size="small"
            value={weyBillFieldValue}
            onChange={handleChangeWayBill}
          />
        </TableCell>
        <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
          <FormControl size="small" sx={{ mb: 2, mt: 2, width: 120 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dropDownstatus}
              onChange={handleChangeDropDown}
            >
              <MenuItem value="OPEN">Open</MenuItem>
              <MenuItem value="ORDER_DELIVERED">Delivered</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", p: 0 }} align="center">
          <Button
            disableElevation
            variant="contained"
            color="warning"
            disabled={row.status === "DELIVERED"}
            sx={{
              background: "#EFF3FF",
              color: "text.light",
              fontSize: "12px",
              fontWeight: 700,
              "&:hover": {
                background: "#CDD9FF",
              },
            }}
            onClick={() => handleOpen()}
          >
            Invoice
          </Button>
        </TableCell>
        <TableCell
          sx={{ whiteSpace: "nowrap", justifyContent: "center" }}
          align="center"
        >
          <TextField
            variant="standard"
            sx={{
              backgroundColor: "#FFFAE7",
              px: 1,
              width: "100px",
              justifyContent: "center",
            }}
            InputProps={{ disableUnderline: true }}
            type="text"
            size="small"
            value={commentFieldValue}
            onChange={handleChangeComment}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", p: 0 }} align="center">
          <Button
            disableElevation
            variant="contained"
            color="warning"
            disabled={row.status === "DELIVERED"}
            sx={{
              background: "#EFF3FF",
              color: "text.light",
              fontSize: "12px",
              fontWeight: 700,
              "&:hover": {
                background: "#CDD9FF",
              },
            }}
            onClick={() => handleDeliveredData(row.cartId)}
          >
            Confirm
          </Button>
        </TableCell>
      </TableRow>

      {/* modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <InvoiceModal
            id={row.cartId}
            number={row.phoneNumber}
            coNumber={row.coNumber}
            wayBillNumber={row.wayBillNumber}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
};

export default DeliveryReplaceList;
