import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

// new imports-----------------------------------------
import { useSnackbar } from "notistack";
import { Box, Collapse, FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshToken from "../../services/RefreshToken";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AuthContext } from "../../context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import { handlePostData } from "../../services/PostDataService";
import { handlePutData } from "../../services/PutDataService";

const AddEditAdmin = ({
  getData,
  editObj,
  setEditObj,
  closeFormDialog,
  formDialog,
}) => {
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);

  const [id, setId] = useState("");

  const [status, setStatus] = useState(true);
  const [imageFile, setImageFile] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [addForm, setAddForm] = useState(true);
  const [editForm, setEditForm] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const clearStates = () => {
    setId("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setRole("");

    setEditObj({});
  };
  const clickUpload = () => {
    document.getElementById("imageUpload").click();
  };

  const validation = () => {
    let isError = false;
    if (!firstName.trim()) {
      handleSnakbarOpen("Please enter first name", "error");
      document.getElementById("firstName").focus();
      return (isError = true);
    }
    if (!lastName.trim()) {
      handleSnakbarOpen("Please enter last name", "error");
      document.getElementById("lastName").focus();
      return (isError = true);
    }
    if (!email.trim()) {
      handleSnakbarOpen("Please enter email address", "error");
      document.getElementById("email").focus();
      return (isError = true);
    } else if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email.trim()
      )
    ) {
      handleSnakbarOpen("Invalid email address", "error");
      document.getElementById("email").focus();
      return (isError = true);
    }
    if (!role.trim()) {
      handleSnakbarOpen("Please select a role", "error");
      document.getElementById("role").focus();
      return (isError = true);
    }
    return isError;
  };
  const handleSubmit = async (e) => {
    // console.log("editIndexNo", editIndexNo);
    e.preventDefault();
    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);
      let response;
      let token = await RefreshToken(dizli_admin_panel, logout, login);

      // return console.log('data', data);

      try {
        if (addForm) {
          const formData = new FormData();
          formData.append("firstName", firstName);
          formData.append("lastName", lastName);
          formData.append("email", email);
          formData.append("roleAssign", role);
          let url = `api/v1/private/auth/create-admin`;
          response = await handlePostData(url, formData, token, true);
        } else if (editForm) {
          const formData = new FormData();
          // formData.append("firstName", firstName);
          // formData.append("lastName", lastName);
          formData.append("email", email);
          formData.append("roleAssign", role);
          let url = `api/v1/private/role/setup`;
          response = await handlePostData(url, formData, token, true);
        }

        if (response?.status > 199 && response?.status < 300) {
          clearStates();
          getData(0);
          closeFormDialog();
          activeAddForm();
          handleSnakbarOpen("Successful", "success");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.status === 500) {
          handleSnakbarOpen(error?.response?.statusText, "error");
        } else {
          setErrors(error.response.data.errors);
        }
      }
    }
    setLoading(false);
  };

  const handleSwitch = () => {
    setStatus(!status);
  };

  useEffect(() => {
    if (editObj.id) {
      setAddForm(false);
      setEditForm(true);

      setTimeout(() => {
        setId(editObj.id);

        setFirstName(editObj.firstName);
        setLastName(editObj.lastName);
        setEmail(editObj.email);
        setRole(editObj.roleAssignEnumsList[0]);
      }, 100);
    }
  }, [editObj]);
  const buttonStyle = {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "14px",
    pr: 2,
    pl: 2.625,
    py: 0.75,
    "& .MuiButton-startIcon": {
      mr: 0.625,
    },
  };
  const customeTextFeild = {
    // padding: "15px 20px",
    background: "#FAFAFA",
    "& label.Mui-focused": {
      color: "#A0AAB4",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#B2BAC2",
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 24px 15px 0px",
    },
    "& .MuiOutlinedInput-root": {
      paddingLeft: "24px",
      "& fieldset": {
        borderColor: "rgba(0,0,0,0)",
      },

      "&:hover fieldset": {
        borderColor: "#969696",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#969696",
      },
    },
  };
  const activeAddForm = () => {
    clearStates();

    // setEditForm(false);
    // setAddForm(true);
  };
  useEffect(() => {
    if (formDialog === false) {
      activeAddForm();
    }
  }, [formDialog]);

  return (
    <React.Fragment>
      <div style={{ position: "relative", margin: "15px 0px 32px" }}>
        <IconButton
          style={{ position: "absolute", right: 0, top: -24 }}
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            closeFormDialog();
            activeAddForm();
          }}
        >
          <ClearOutlinedIcon />
        </IconButton>

        <Typography
          sx={{
            fontWeight: 600,
            color: "#222222",
            fontFamily: "'Poppins', sans-serif",
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          {editForm ? "Update Admin" : "Add Admin"}
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        {addForm && (
          <>
            <Box sx={{ mb: 1.5 }}>
              <Typography sx={{ fontWeight: 600 }}>First Name</Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />{" "}
              {errors?.firstName && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.firstName.toString()}
                </Typography>
              )}
            </Box>
            <Box sx={{ mb: 1.5 }}>
              <Typography sx={{ fontWeight: 600 }}>Last Name</Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />{" "}
              {errors?.lastName && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.lastName.toString()}
                </Typography>
              )}
            </Box>
            <Box sx={{ mb: 1.5 }}>
              <Typography sx={{ fontWeight: 600 }}>Email</Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />{" "}
              {errors?.email && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.email.toString()}
                </Typography>
              )}
            </Box>
          </>
        )}
        <Box sx={{ mb: 1.5 }}>
          <Typography sx={{ fontWeight: 600 }}>Role</Typography>
          <FormControl fullWidth sx={{ ...customeTextFeild }}>
            <Select
              labelId="demo-simple-select-label"
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <MenuItem value={"COMPLIANCE_ADMIN"}>Compliance Admin </MenuItem>
              <MenuItem value={"FINANCE_ADMIN"}>Finance Admin </MenuItem>
              <MenuItem value={"AGENT_ADMIN"}>Agent Admin </MenuItem>
            </Select>
          </FormControl>
          {errors?.roleAssign && (
            <Typography
              variant="small"
              color="error.main"
              sx={{ textAlign: "left" }}
            >
              {errors.roleAssign.toString()}
            </Typography>
          )}
        </Box>

        {/* {editForm && (
          <div style={{ textAlign: "center", margin: "7px 0" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={status}
                  onChange={handleSwitch}
                  name="status"
                  color="primary"
                />
              }
              label="Status"
              labelPlacement="start"
            />
          </div>
        )} */}

        {/* <div style={{ textAlign: "center" }}>
         

          <Button
            disableElevation
            variant="contained"
            sx={{
              marginTop: "24px",
              // borderRadius: 25,
              maxWidth: "300px",
            }}
            color="primary"
            disabled={loading}
            fullWidth
            type="submit"
          >
            {loading ? (
              <CircularProgress size="1rem" style={{ marginRight: "10px" }} />
            ) : null}
            {editForm ? "Update" : "Save"}
          </Button>
        </div> */}
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Button
            variant="contained"
            color="success"
            disableElevation
            sx={{ minHeight: "50px", minWidth: "180px" }}
            disabled={loading}
            type="submit"
            // startIcon={<DoneAllIcon />}
            // onClick={() => handleUpdateStatus("VERIFIED")}
          >
            {/* {updateStatusValue !== "VERIFIED" && "Submit"} */}
            {editForm ? "Update" : "Save"}
            <PulseLoader
              color={"#353b48"}
              loading={loading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default AddEditAdmin;
