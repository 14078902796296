import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

// new imports-----------------------------------------
import { useSnackbar } from "notistack";
import { Box, Collapse, FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshToken from "../../services/RefreshToken";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AuthContext } from "../../context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import { handlePostData } from "../../services/PostDataService";
import { handlePutData } from "../../services/PutDataService";

const AddEditCategory = ({
  getData,
  editObj,
  setEditObj,
  closeFormDialog,
  formDialog,
}) => {
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);

  const [id, setId] = useState("");

  const [status, setStatus] = useState(true);
  const [name, setName] = useState("");
  const [store, setStore] = useState("");
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [addForm, setAddForm] = useState(true);
  const [editForm, setEditForm] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const clearStates = () => {
    setId("");
    setName("");
    setDescription("");
    setPriority("");
    setStore("");
    setImageFile(null);
    setStatus(false);
    setEditObj({});
  };
  const clickUpload = () => {
    document.getElementById("imageUpload").click();
  };
  const imageProcess = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setImageFile(imageFile);
        setPreview(x.target.result);
      };
      reader.readAsDataURL(imageFile);
    } else {
      setImageFile(null);
    }
  };

  const validation = () => {
    let isError = false;
    if (!name.trim()) {
      handleSnakbarOpen("Please enter name", "error");
      document.getElementById("name").focus();
      return (isError = true);
    }

    if (!store) {
      handleSnakbarOpen("Please enter store", "error");
      document.getElementById("store").focus();
      return (isError = true);
    }
    if (!priority) {
      handleSnakbarOpen("Please enter priority", "error");
      document.getElementById("priority").focus();
      return (isError = true);
    }
    if (!description.trim()) {
      handleSnakbarOpen("Please enter description", "error");
      document.getElementById("description").focus();
      return (isError = true);
    }
    if (editForm === false && !imageFile) {
      handleSnakbarOpen("Please upload a cover picture", "error");
      return (isError = true);
    }
    return isError;
  };
  const handleSubmit = async (e) => {
    // console.log("editIndexNo", editIndexNo);
    e.preventDefault();
    let err = validation();
    if (err) {
      return;
    } else {
      setLoading(true);
      let response;
      let token = await RefreshToken(dizli_admin_panel, logout, login);

      // return console.log('data', data);

      try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("priority", parseInt(priority));
        formData.append("description", description);
        formData.append("ecom", store);

        formData.append("coverPicture", imageFile);
        if (addForm) {
          let url = `api/v1/private/admin/dashboard-category`;
          response = await handlePostData(url, formData, token, true);
        } else if (editForm) {
          let url = `api/v1/private/admin/dashboard-category/${id}`;
          response = await handlePutData(url, formData, token);
          //   response = await axios({
          //     url: `/api/v1/private/admin/dashboard-category/${id}`,
          //     method: "put",
          //     data: formData,
          //     headers: {
          //       Authorization: `Bearer ${token}`,
          //     },
          //   });
        }

        if (response?.status > 199 && response?.status < 300) {
          clearStates();
          getData(0);
          closeFormDialog();
          activeAddForm();
          handleSnakbarOpen("Successful", "success");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.status === 500) {
          handleSnakbarOpen(error?.response?.statusText, "error");
        } else {
          setErrors(error.response.data.errors);
        }
      }
    }
    setLoading(false);
  };

  const handleSwitch = () => {
    setStatus(!status);
  };

  useEffect(() => {
    if (editObj.id) {
      setAddForm(false);
      setEditForm(true);

      setTimeout(() => {
        setId(editObj.id);
        setName(editObj.name);
        setStore(editObj.ecom);
        setPriority(editObj.priority);
        setDescription(editObj.description);
        setStatus(editObj.status);
      }, 100);
    }
  }, [editObj]);
  const buttonStyle = {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "14px",
    pr: 2,
    pl: 2.625,
    py: 0.75,
    "& .MuiButton-startIcon": {
      mr: 0.625,
    },
  };
  const customeTextFeild = {
    // padding: "15px 20px",
    background: "#FAFAFA",
    "& label.Mui-focused": {
      color: "#A0AAB4",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#B2BAC2",
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 24px 15px 0px",
    },
    "& .MuiOutlinedInput-root": {
      paddingLeft: "24px",
      "& fieldset": {
        borderColor: "rgba(0,0,0,0)",
      },

      "&:hover fieldset": {
        borderColor: "#969696",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#969696",
      },
    },
  };
  const activeAddForm = () => {
    clearStates();

    // setEditForm(false);
    // setAddForm(true);
  };
  useEffect(() => {
    if (formDialog === false) {
      activeAddForm();
    }
  }, [formDialog]);

  return (
    <React.Fragment>
      <div style={{ position: "relative", margin: "15px 0px 32px" }}>
        <IconButton
          style={{ position: "absolute", right: 0, top: -24 }}
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            closeFormDialog();
            activeAddForm();
          }}
        >
          <ClearOutlinedIcon />
        </IconButton>

        <Typography
          sx={{
            fontWeight: 600,
            color: "#222222",
            fontFamily: "'Poppins', sans-serif",
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          {editForm ? "Update Category" : "Add Category"}
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 1.5 }}>
          <Typography sx={{ fontWeight: 600 }}> Name</Typography>
          <TextField
            sx={{ ...customeTextFeild }}
            fullWidth
            // size="small"

            variant="outlined"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />{" "}
          {errors?.email && (
            <Typography
              variant="small"
              color="error.main"
              sx={{ textAlign: "left" }}
            >
              {errors.name.toString()}
            </Typography>
          )}
        </Box>
        <Box sx={{ mb: 1.5 }}>
          <Typography sx={{ fontWeight: 600 }}> Store</Typography>
          {/* <TextField
            sx={{ ...customeTextFeild }}
            fullWidth
            // size="small"

            variant="outlined"
            id="store"
            value={store}
            onChange={(e) => setStore(e.target.value)}
          />{" "} */}
          <FormControl fullWidth sx={{ ...customeTextFeild }}>
            <Select
              labelId="demo-simple-select-label"
              id="store"
              value={store}
              onChange={(e) => setStore(e.target.value)}
            >
              <MenuItem value={101}>Amazon </MenuItem>
              <MenuItem value={102}>Ebay </MenuItem>
              <MenuItem value={103}>Iherb </MenuItem>
            </Select>
          </FormControl>
          {errors?.email && (
            <Typography
              variant="small"
              color="error.main"
              sx={{ textAlign: "left" }}
            >
              {errors.ecom.toString()}
            </Typography>
          )}
        </Box>
        <Box sx={{ mb: 1.5 }}>
          <Typography sx={{ fontWeight: 600 }}> Priority</Typography>
          <TextField
            sx={{ ...customeTextFeild }}
            fullWidth
            // size="small"
            type="number"
            variant="outlined"
            id="priority"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
          />{" "}
          {errors?.email && (
            <Typography
              variant="small"
              color="error.main"
              sx={{ textAlign: "left" }}
            >
              {errors.priority.toString()}
            </Typography>
          )}
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography sx={{ fontWeight: 600 }}> Description</Typography>
          <TextField
            sx={{ ...customeTextFeild }}
            fullWidth
            // size="small"
            multiline
            rows={3}
            variant="outlined"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />{" "}
          {errors?.description && (
            <Typography
              variant="small"
              color="error.main"
              sx={{ textAlign: "left" }}
            >
              {errors.name.toString()}
            </Typography>
          )}
        </Box>
        <Box sx={{ mb: 1.5 }}>
          <Typography sx={{ fontWeight: 600 }}> Cover Picture</Typography>
          <div style={{ background: "#FAFAFA", borderRadius: "4px" }}>
            <input
              type="file"
              name="file"
              id="imageUpload"
              hidden
              accept="image/*"
              onChange={imageProcess}
              multiple
            />
            <Button variant="outlined" color="text" onClick={clickUpload}>
              Upload Picture
            </Button>
            <div style={{ display: "inline-flex" }}>
              <p
                style={{
                  whiteSpace: "nowrap",
                  width: "250px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginLeft: "5px",
                  // color: "#A0AAB4",
                }}
              >
                {imageFile !== null ? <>{imageFile.name}</> : "No image chosen"}
              </p>
            </div>
          </div>
        </Box>

        {/* {editForm && (
          <div style={{ textAlign: "center", margin: "7px 0" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={status}
                  onChange={handleSwitch}
                  name="status"
                  color="primary"
                />
              }
              label="Status"
              labelPlacement="start"
            />
          </div>
        )} */}

        {/* <div style={{ textAlign: "center" }}>
         

          <Button
            disableElevation
            variant="contained"
            sx={{
              marginTop: "24px",
              // borderRadius: 25,
              maxWidth: "300px",
            }}
            color="primary"
            disabled={loading}
            fullWidth
            type="submit"
          >
            {loading ? (
              <CircularProgress size="1rem" style={{ marginRight: "10px" }} />
            ) : null}
            {editForm ? "Update" : "Save"}
          </Button>
        </div> */}
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Button
            variant="contained"
            color="success"
            disableElevation
            sx={{ minHeight: "50px", minWidth: "180px" }}
            disabled={loading}
            type="submit"
            // startIcon={<DoneAllIcon />}
            // onClick={() => handleUpdateStatus("VERIFIED")}
          >
            {/* {updateStatusValue !== "VERIFIED" && "Submit"} */}
            {editForm ? "Update" : "Save"}
            <PulseLoader
              color={"#353b48"}
              loading={loading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default AddEditCategory;
