import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

// new imports-----------------------------------------
import { useSnackbar } from "notistack";
import { Box, Collapse, FormControlLabel, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshToken from "../../services/RefreshToken";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AuthContext } from "../../context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import { handlePostData } from "../../services/PostDataService";
import { handlePutData } from "../../services/PutDataService";
import { handlePutRowData } from "../../services/PutRowDataService";

const AddEditDeliveryInvoiceConfiguration = ({
  getData,
  editObj,
  setEditObj,
  closeFormDialog,
  formDialog,
}) => {
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);

  const [id, setId] = useState("");

  const [status, setStatus] = useState(true);
  const [name, setName] = useState("");
  const [store, setStore] = useState("");
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState("");

  const [shippingCharge, setShippingCharge] = useState("");
  const [customsVATCharge, setCustomsVATCharge] = useState("");
  const [serviceInsuranceCharge, setServiceInsuranceCharge] = useState("");
  const [depositPercentage, setDepositPercentage] = useState("");
  const [minDeliveryWeeks, setMinDeliveryWeeks] = useState("");
  const [maxDeliveryWeeks, setMaxDeliveryWeeks] = useState("");
  const [payLaterCharge, setPayLaterCharge] = useState("");
  const [payLaterDepositPercentage, setPayLaterDepositPercentage] =
    useState("");
  const [depositFreeAmount, setDepositFreeAmount] = useState("");
  const [validityTimeInSeconds, setValidityTimeInSeconds] = useState("");

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [addForm, setAddForm] = useState(true);
  const [editForm, setEditForm] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const clearStates = () => {
    setId("");
    setName("");
    setDescription("");
    setPriority("");
    setStore("");
    setImageFile(null);
    setStatus(false);
    setEditObj({});
  };
  const clickUpload = () => {
    document.getElementById("imageUpload").click();
  };
  const imageProcess = (e) => {
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setImageFile(imageFile);
        setPreview(x.target.result);
      };
      reader.readAsDataURL(imageFile);
    } else {
      setImageFile(null);
    }
  };

  const validation = () => {
    let isError = false;
    if (!name.trim()) {
      handleSnakbarOpen("Please enter name", "error");
      document.getElementById("name").focus();
      return (isError = true);
    }

    if (!store) {
      handleSnakbarOpen("Please enter store", "error");
      document.getElementById("store").focus();
      return (isError = true);
    }
    if (!priority) {
      handleSnakbarOpen("Please enter priority", "error");
      document.getElementById("priority").focus();
      return (isError = true);
    }
    if (!description.trim()) {
      handleSnakbarOpen("Please enter description", "error");
      document.getElementById("description").focus();
      return (isError = true);
    }
    if (editForm === false && !imageFile) {
      handleSnakbarOpen("Please upload a cover picture", "error");
      return (isError = true);
    }
    return isError;
  };
  const handleSubmit = async (e) => {
    console.log("handleSubmit ===============================");
    // console.log("editIndexNo", editIndexNo);
    // e.preventDefault();
    // let err = validation();
    // if (err) {
    //   return;
    // } else {
    setLoading(true);
    let response;
    let token = await RefreshToken(dizli_admin_panel, logout, login);

    // return console.log('data', data);

    try {
      let data = {
        shippingCharge: parseFloat(shippingCharge),
        customsVATCharge: parseFloat(customsVATCharge),
        serviceInsuranceCharge: parseFloat(serviceInsuranceCharge),
        depositPercentage: parseFloat(depositPercentage),
        minDeliveryWeeks: parseFloat(minDeliveryWeeks),
        maxDeliveryWeeks: parseFloat(maxDeliveryWeeks),
        payLaterCharge: parseFloat(payLaterCharge),
        payLaterDepositPercentage: parseFloat(payLaterDepositPercentage),
        depositFreeAmount: parseFloat(depositFreeAmount),
        validityTimeInSeconds: parseFloat(validityTimeInSeconds),
      };

      let url = `api/v1/private/admin/delivery-invoice-configuration-data`;
      response = await handlePutRowData(url, data, token);

      if (response?.status > 199 && response?.status < 300) {
        clearStates();
        getData();
        closeFormDialog();
        activeAddForm();
        handleSnakbarOpen("Successful", "success");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 500) {
        handleSnakbarOpen(error?.response?.statusText, "error");
      } else {
        setErrors(error.response.data.errors);
      }
    }
    // }
    setLoading(false);
  };

  const handleSwitch = () => {
    setStatus(!status);
  };

  useEffect(() => {
    console.log("editObj", editObj);

    setEditForm(true);

    setTimeout(() => {
      // setId(editObj.id);
      setName(editObj.name);
      setStore(editObj.ecom);
      setPriority(editObj.priority);
      setDescription(editObj.description);
      setStatus(editObj.status);

      setShippingCharge(editObj.shippingCharge);
      setCustomsVATCharge(editObj.customsVATCharge);
      setServiceInsuranceCharge(editObj.serviceInsuranceCharge);
      setDepositPercentage(editObj.depositPercentage);
      setMinDeliveryWeeks(editObj.minDeliveryWeeks);
      setMaxDeliveryWeeks(editObj.maxDeliveryWeeks);
      setPayLaterCharge(editObj.payLaterCharge);
      setPayLaterDepositPercentage(editObj.payLaterDepositPercentage);
      setDepositFreeAmount(editObj.depositFreeAmount);
      setValidityTimeInSeconds(editObj.validityTimeInSeconds);
    }, 100);
  }, [editObj]);
  const buttonStyle = {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "14px",
    pr: 2,
    pl: 2.625,
    py: 0.75,
    "& .MuiButton-startIcon": {
      mr: 0.625,
    },
  };
  const customeTextFeild = {
    // padding: "15px 20px",
    background: "#FAFAFA",
    "& label.Mui-focused": {
      color: "#A0AAB4",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#B2BAC2",
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 24px 15px 0px",
    },
    "& .MuiOutlinedInput-root": {
      paddingLeft: "24px",
      "& fieldset": {
        borderColor: "rgba(0,0,0,0)",
      },

      "&:hover fieldset": {
        borderColor: "#969696",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#969696",
      },
    },
  };
  const activeAddForm = () => {
    clearStates();

    // setEditForm(false);
    // setAddForm(true);
  };
  useEffect(() => {
    if (formDialog === false) {
      activeAddForm();
    }
  }, [formDialog]);

  return (
    <React.Fragment>
      <div style={{ position: "relative", margin: "15px 0px 32px" }}>
        <IconButton
          style={{ position: "absolute", right: 0, top: -24 }}
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            closeFormDialog();
            activeAddForm();
          }}
        >
          <ClearOutlinedIcon />
        </IconButton>

        <Typography
          sx={{
            fontWeight: 600,
            color: "#222222",
            fontFamily: "'Poppins', sans-serif",
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          {editForm
            ? "Update Delivery Invoice Configuration"
            : "Add Delivery Invoice Configuration"}
        </Typography>
      </div>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box>
              <Typography sx={{ fontWeight: 600 }}> Shipping Charge</Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="name"
                type="number"
                value={shippingCharge}
                onChange={(e) => setShippingCharge(e.target.value)}
              />{" "}
              {errors?.shippingCharge && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.shippingCharge.toString()}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Customs VAT Charge
              </Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="name"
                type="number"
                value={customsVATCharge}
                onChange={(e) => setCustomsVATCharge(e.target.value)}
              />{" "}
              {errors?.customsVATCharge && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.customsVATCharge.toString()}
                </Typography>
              )}
            </Box>
          </Grid>
         
          <Grid item xs={6}>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Deposit Percentage
              </Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="name"
                type="number"
                value={depositPercentage}
                onChange={(e) => setDepositPercentage(e.target.value)}
              />{" "}
              {errors?.depositPercentage && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.depositPercentage.toString()}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Deposit Free Amount
              </Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="name"
                type="number"
                value={depositFreeAmount}
                onChange={(e) => setDepositFreeAmount(e.target.value)}
              />{" "}
              {errors?.depositFreeAmount && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.depositFreeAmount.toString()}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Min Delivery Weeks
              </Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="name"
                type="number"
                value={minDeliveryWeeks}
                onChange={(e) => setMinDeliveryWeeks(e.target.value)}
              />{" "}
              {errors?.minDeliveryWeeks && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.minDeliveryWeeks.toString()}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Max Delivery Weeks
              </Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="name"
                type="number"
                value={maxDeliveryWeeks}
                onChange={(e) => setMaxDeliveryWeeks(e.target.value)}
              />{" "}
              {errors?.maxDeliveryWeeks && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.maxDeliveryWeeks.toString()}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Pay Later Charge
              </Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="name"
                type="number"
                value={payLaterCharge}
                onChange={(e) => setPayLaterCharge(e.target.value)}
              />{" "}
              {errors?.payLaterCharge && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.payLaterCharge.toString()}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Pay Later Deposit Percentage
              </Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="name"
                type="number"
                value={payLaterDepositPercentage}
                onChange={(e) => setPayLaterDepositPercentage(e.target.value)}
              />{" "}
              {errors?.payLaterDepositPercentage && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.payLaterDepositPercentage.toString()}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Service Insurance Charge
              </Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="name"
                type="number"
                value={serviceInsuranceCharge}
                onChange={(e) => setServiceInsuranceCharge(e.target.value)}
              />{" "}
              {errors?.serviceInsuranceCharge && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.serviceInsuranceCharge.toString()}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Validity Time (In Seconds)
              </Typography>
              <TextField
                sx={{ ...customeTextFeild }}
                fullWidth
                // size="small"

                variant="outlined"
                id="name"
                type="number"
                value={validityTimeInSeconds}
                onChange={(e) => setValidityTimeInSeconds(e.target.value)}
              />{" "}
              {errors?.validityTimeInSeconds && (
                <Typography
                  variant="small"
                  color="error.main"
                  sx={{ textAlign: "left" }}
                >
                  {errors.validityTimeInSeconds.toString()}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>

        {/* {editForm && (
          <div style={{ textAlign: "center", margin: "7px 0" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={status}
                  onChange={handleSwitch}
                  name="status"
                  color="primary"
                />
              }
              label="Status"
              labelPlacement="start"
            />
          </div>
        )} */}

        {/* <div style={{ textAlign: "center" }}>
         

          <Button
            disableElevation
            variant="contained"
            sx={{
              marginTop: "24px",
              // borderRadius: 25,
              maxWidth: "300px",
            }}
            color="primary"
            disabled={loading}
            fullWidth
            type="submit"
          >
            {loading ? (
              <CircularProgress size="1rem" style={{ marginRight: "10px" }} />
            ) : null}
            {editForm ? "Update" : "Save"}
          </Button>
        </div> */}
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Button
            variant="contained"
            color="success"
            disableElevation
            sx={{ minHeight: "50px", minWidth: "180px" }}
            disabled={loading}
            // type="submit"
            // startIcon={<DoneAllIcon />}
            onClick={() => handleSubmit()}
          >
            {/* {updateStatusValue !== "VERIFIED" && "Submit"} */}
            {editForm ? "Update" : "Save"}
            <PulseLoader
              color={"#353b48"}
              loading={loading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default AddEditDeliveryInvoiceConfiguration;
