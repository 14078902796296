import React, { useContext, useEffect, useState } from "react";
import Countdown from "react-countdown";
import { Box, Breadcrumbs, Grid, Tooltip, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import MenuIcon from "../icons/MenuIcon";
import { useTheme } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, Button, IconButton, Paper, Skeleton } from "@mui/material";

import { getDataWithToken } from "../../../services/GetDataService";
import { AuthContext } from "../../../context/AuthContext";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import { handlePutData } from "../../../services/PutDataService";
import RefreshToken from "../../../services/RefreshToken";

import { handlePutRowData } from "../../../services/PutRowDataService";
import CopyUrl from "../live_cart/CopyUrl";
import Message from "../../message/Message";
import EditHistory from "../edit-history/EditHistory";
import Descreption from "../live_cart/Descreption";

const ApprovedCartDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  const location = useLocation();
  console.log("state", location?.state);
  const { cartType } = useParams();
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [gender, setGender] = useState("");
  const [status, setStatus] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [message, setMessage] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [createdStartTime, setCreatedStartTime] = useState(null);
  const [createdEndTime, setCreatedEndTime] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [allStatus, setAllStatus] = useState([]);
  const [postList, setPostList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [statusValue, setStatusValue] = useState(null);
  const [timeRange, setTimeRange] = useState("34");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [count, setCount] = useState("");
  const [submittedAt, setSubmittedAt] = useState("");
  const [countDownDate, setCountDownDate] = useState("");
  const [statusType, setStatusType] = useState("A_N");

  // invoice

  const [i_deposit, set_i_deposit] = useState(0);

  const [invoiceDetails, setInvoiceDetails] = useState({});

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };
  const pageLoading = () => {
    let rows = [];

    for (let i = 0; i < 25; i++) {
      let cells = [];

      for (let j = 0; j < 12; j++) {
        cells.push(
          <TableCell key={j} sx={{ py: 1.5 }}>
            <Skeleton></Skeleton>
          </TableCell>
        );
      }

      rows.push(<TableRow key={i}>{cells}</TableRow>);
    }

    return rows;
  };

  const postData = async (postType) => {
    setLoading(true);
    setMessage("");

    let min = timeRange[0];
    let max = timeRange[1];
    let url = `api/v1/private/admin/live-cart/process`;
    if (cartType === "review") {
      url = `api/v1/private/admin/review-cart/process`;
    }
    let token = await RefreshToken(dizli_admin_panel, logout, login);
    let data = {
      cartId: id,
      editData: postList,
      minDeliveryWeeks: parseInt(min),
      maxDeliveryWeeks: parseInt(max),
      adminComment: "Price is updated for mac m2",
      approveType: postType,
    };
    console.log("post", data);

    let res = await handlePutRowData(url, data, token);

    if (res?.status > 199 && res?.status < 300) {
      console.log("response: ", res);
      enqueueSnackbar(res.data.message, {
        variant: "success",
        autoHideDuration: 3000,
      });

      navigate("/live-carts");
    } else {
      console.log("error response: ", res.data.message);
      enqueueSnackbar(res.data.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setLoading(false);
  };
  const getData = async () => {
    setLoading(true);
    setMessage("");
    let newPageNO;
    let url = `api/v1/private/admin/approved-cart/${id}`;

    // let token = dizli_admin_panel.access_token;
    let token = await RefreshToken(dizli_admin_panel, logout, login);

    let res = await getDataWithToken(url, token);
    console.log("res::", res);
    if (res?.status === 203) {
      enqueueSnackbar(res.data.message, {
        variant: "info",
        autoHideDuration: 3000,
      });
      // return navigate("/approved-carts");
    }

    if (res?.status > 199 && res?.status < 300) {
      setTotalData(res.data.data.total);
      // setRowsPerPage(res.data.data.per_page);

      setList(res.data.data);

      setInvoiceDetails(res.data.data.invoice);
    } else {
      enqueueSnackbar(res.data.message, {
        variant: "error",
        autoHideDuration: 3000,
      });

      // if (cartType === "review") {
      //   return navigate("/review-carts");
      // } else {
      //   return navigate("/live-carts");
      // }
    }
    setLoading(false);
  };

  const buttonStyle = {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "14px",
    pr: 2,
    pl: 2.625,
    py: 0.75,
    "& .MuiButton-startIcon": {
      mr: 0.625,
    },
  };
  const customeSelect = {
    background: "#fff",

    "& .MuiInputBase-input": {
      borderRadius: 50,
      position: "relative",
      // backgroundColor: theme.palette.background.paper,
      // border: "1px solid #ced4da",
      fontSize: 14,
      color: "#222",
      padding: "6px 16px 6px 44px",
      // Use the system font instead of the default Roboto font.
    },
  };

  const handleBack = async () => {
    try {
      let url = `/api/v1/private/admin/live-cart/under-process/unmark`;
      // let token = dizli_admin_panel.access_token;
      let token = await RefreshToken(dizli_admin_panel, logout, login);

      let formData = new FormData();
      formData.append("cartId", id);

      let res = await handlePutData(url, formData, token);

      navigate(-1);
    } catch (error) {
      console.log("errr", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100vh" }}
      >
        <Grid item sx={{ width: "100%" }}>
          {" "}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 2.125, borderBottom: "1px solid #E5E5E5" }}
          >
            <Grid
              item
              xs="auto"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              {/* <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton> */}
              <Typography
                variant="h6"
                color="text.light"
                sx={{ fontWeight: 600, textTransform: "capitalize" }}
              >
                Approved Cart Details
                {/* <span style={{ fontWeight: 400 }}>(14)</span>{" "} */}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator="/"
                // className={classes.breadcrumbsStyle}
              >
                <Link to="/approved-carts">Approved Carts</Link>
                <Link to="#">Process</Link>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: "Calc(100vh - 65px)",
              overflowY: "auto",
              // pt: 2.5,
              px: 2,
            }}
          >
            <Box sx={{ mt: 4 }}>
              <Grid container>
                <Grid
                  item
                  sx={{
                    width: "Calc(100% - 324px)",
                    boxSizing: "border-box",
                    px: 2,
                  }}
                >
                  {" "}
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <Grid item xs="auto">
                      <Grid
                        sx={{
                          background: "#FAFAFA",
                          py: 0.5,
                          borderRadius: "6px",
                        }}
                      >
                        {/* <Grid
                      item
                      xs="auto"
                      sx={{ px: 1.5, borderRight: "1px solid #DBDADE" }}
                    > */}
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{
                            fontWeight: 700,
                            px: 1.5,
                            borderRight: "1px solid #DBDADE",
                            display: "inline-flex",
                          }}
                        >
                          User Phone No: &nbsp;&nbsp;
                          <span style={{ color: "#222", fontWeight: 400 }}>
                            {location?.state?.data?.phoneNumber}
                          </span>
                        </Typography>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{
                            fontWeight: 700,
                            px: 1.5,
                            borderRight: "1px solid #DBDADE",
                            display: "inline-flex",
                          }}
                        >
                          Cart ID: &nbsp;&nbsp;
                          <span style={{ color: "#222", fontWeight: 400 }}>
                            {id}
                          </span>
                        </Typography>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{
                            fontWeight: 700,
                            px: 1.5,
                            borderRight: "1px solid #DBDADE",
                            display: "inline-flex",
                          }}
                        >
                          Date:&nbsp;&nbsp;
                          <span style={{ color: "#222", fontWeight: 400 }}>
                            {/* {list.submittedAt} */}
                            {moment(list?.submittedAt).format("MMM DD YYYY")}
                          </span>
                        </Typography>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{
                            fontWeight: 700,
                            px: 1.5,
                            borderRight: "1px solid #DBDADE",
                            display: "inline-flex",
                          }}
                        >
                          Time:&nbsp;&nbsp;
                          <span style={{ color: "#222", fontWeight: 400 }}>
                            {moment(list?.submittedAt).format("hh:mm")}
                          </span>
                        </Typography>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{
                            fontWeight: 700,
                            px: 1.5,
                            // borderRight: "1px solid #DBDADE",
                            display: "inline-flex",
                          }}
                        >
                          Edits:&nbsp;&nbsp;
                          <span style={{ color: "#222", fontWeight: 400 }}>
                            {parseInt(list.editCount)}
                          </span>
                        </Typography>
                        {/* </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item xs="auto">
                      <EditHistory />
                      &nbsp;&nbsp;
                      <Message phoneNo={location?.state?.data?.phoneNumber} />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      width: "100%",
                      overflow: "auto",
                      pt: 1.125,
                      // px: 2,
                    }}
                  >
                    <TableContainer
                      style={{
                        maxHeight: "Calc(100vh - 135px)",
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Items</TableCell>
                            <TableCell>Img</TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Quantity
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Price
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Postage
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Ounces
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Pounds
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              KG
                            </TableCell>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Total W KG
                            </TableCell>

                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Item Status
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {list?.shoppingCartList?.map((item, index) => (
                            <TableRow key={item.id}>
                              <TableCell>{index + 1}</TableCell>

                              <TableCell sx={{ whiteSpace: "nowrap" }}>
                                {item.product?.image === null ? (
                                  <img
                                    src="/no_image.png"
                                    alt=""
                                    style={{
                                      maxWidth: "90px",
                                      maxHeight: "120px",
                                    }}
                                  />
                                ) : (
                                  <Tooltip
                                    slotProps={{
                                      tooltip: {
                                        sx: {
                                          // color: "#514E6A",
                                          maxWidth: 500,
                                          backgroundColor: "#ffff",
                                          boxShadow:
                                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                          p: 3,
                                        },
                                      },
                                    }}
                                    title={
                                      <div>
                                        <img
                                          src={item.product?.image}
                                          alt=""
                                          style={{
                                            maxWidth: "350px",
                                            maxHeight: "450px",
                                          }}
                                        />
                                      </div>
                                    }
                                    placement="right"
                                  >
                                    <img
                                      src={item.product?.image}
                                      alt=""
                                      style={{
                                        maxWidth: "90px",
                                        maxHeight: "120px",
                                      }}
                                    />
                                  </Tooltip>
                                )}{" "}
                              </TableCell>
                              <TableCell>
                                {/* {item.product?.url && (
                                  <CopyUrl
                                    url={item.product?.url}
                                    websiteName={item.product?.websiteName}
                                  />
                                )} */}
                                  <a
                                      href={item.product?.url}
                                      target="_blank"
                                      style={{
                                        whiteSpace: "nowrap",
                                        color: "#666",
                                      }}
                                    >
                                      {item.product?.websiteName} URL
                                    </a>
                              </TableCell>
                              <TableCell>
                                {/* {item.product?.title} */}
                                <Descreption detail={item.product} />{" "}
                              </TableCell>
                              <TableCell>{item.product?.quantity}</TableCell>
                              <TableCell>{item.product?.price}</TableCell>
                              <TableCell>{item.postage}</TableCell>
                              <TableCell>
                                {/* {parseFloat(item.ounces)} */}
                              </TableCell>
                              <TableCell>
                                {/* {parseFloat(item.pounds)} */}
                              </TableCell>
                              <TableCell>{item.product?.weight}</TableCell>
                              <TableCell>{item.totalWeight}</TableCell>
                              <TableCell sx={{ minWidth: "100px" }}>
                                {item?.itemStatus?.statusType === null ? (
                                  "-------"
                                ) : item?.itemStatus?.statusType === "A_N" ? (
                                  <span style={{ color: "#35b522" }}>
                                    {" "}
                                    Accepted
                                  </span>
                                ) : (
                                  <span style={{ color: "#F23836" }}>
                                    Rejected
                                  </span>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}

                          {loading && pageLoading()}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "324px",
                    boxSizing: "border-box",
                    background: "#FAFAFA",
                    p: 1.5,
                  }}
                >
                  <Box sx={{ textAlign: "right", color: "red" }}>
                    {countDownDate && <Countdown date={countDownDate} />}
                  </Box>
                  <Typography
                    variant="medium"
                    color="text.main"
                    sx={{ fontWeight: 700, mb: 0.5 }}
                  >
                    Delivery Time
                  </Typography>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ mb: 3, ...customeSelect }}
                  >
                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ position: "relative", left: 16, top: 25 }}
                    >
                      <path
                        d="M6.16602 1.33337V3.33337"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.5 1.33337V3.33337"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.16602 6.06006H14.4993"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.833 5.66671V11.3334C14.833 13.3334 13.833 14.6667 11.4997 14.6667H6.16634C3.83301 14.6667 2.83301 13.3334 2.83301 11.3334V5.66671C2.83301 3.66671 3.83301 2.33337 6.16634 2.33337H11.4997C13.833 2.33337 14.833 3.66671 14.833 5.66671Z"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.2965 9.13338H11.3025"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.2965 11.1334H11.3025"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.82967 9.13338H8.83566"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.82967 11.1334H8.83566"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.36287 9.13338H6.36886"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.36287 11.1334H6.36886"
                        stroke="#969696"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // sx={{
                      //   ...customeSelect,
                      // }}
                      value={timeRange}
                      onChange={handleTimeRangeChange}
                    >
                      <MenuItem value="34">3-4 Weeks</MenuItem>
                      <MenuItem value="45">4-5 Weeks</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography
                    variant="medium"
                    color="text.main"
                    sx={{ fontWeight: 700, mb: 1.5 }}
                  >
                    Delivery Invoice
                  </Typography>
                  <Box
                    sx={{
                      background: "#fff",
                      p: 1.5,
                      mb: 3,
                      height: "Calc(100vh - 466px)",
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{ fontWeight: 700, width: "142px" }}
                        >
                          Item price
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          $
                          {parseFloat(invoiceDetails?.itemsSubTotal).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{ fontWeight: 700, width: "142px" }}
                        >
                          Postage
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceDetails?.postage).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{ fontWeight: 700, width: "142px" }}
                        >
                          Shipping
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceDetails?.shipping).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{ fontWeight: 700, width: "142px" }}
                        >
                          Customs/VAT
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceDetails.customsVAT).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.fade"
                          sx={{ fontWeight: 700, width: "142px" }}
                        >
                          Service
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceDetails.service).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2, borderTop: "1px solid #E5E5E5" }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.main"
                          sx={{ fontWeight: 700, width: "142px" }}
                        >
                          Total
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceDetails.total).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mb: 2 }}
                    >
                      <Grid item>
                        <Typography
                          variant="medium"
                          color="text.main"
                          sx={{ fontWeight: 400, width: "142px" }}
                        >
                          Deposit
                          <span style={{ float: "inline-end" }}>:</span>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography variant="medium" color="text.main">
                          ${parseFloat(invoiceDetails.deposit).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ApprovedCartDetails;
