import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import MenuIcon from "../icons/MenuIcon";
import { useTheme } from "@mui/material/styles";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Chip from "@mui/material/Chip";
import {
  Avatar,
  Button,
  IconButton,
  Paper,
  Skeleton,
  TextField,
} from "@mui/material";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Collapse from "@mui/material/Collapse";
import { getDataWithToken } from "../../services/GetDataService";
import { AuthContext } from "../../context/AuthContext";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RefreshToken from "../../services/RefreshToken";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { handlePostData } from "../../services/PostDataService";
import PulseLoader from "react-spinners/PulseLoader";
import { handlePutRowData } from "../../services/PutRowDataService";
import { handlePutData } from "../../services/PutDataService";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddEditAdmin from "./AddEditAdmin";

const AdminList = () => {
  const theme = useTheme();
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [name, setName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [gender, setGender] = useState("");
  const [status, setStatus] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [message, setMessage] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [createdStartTime, setCreatedStartTime] = useState(null);
  const [createdEndTime, setCreatedEndTime] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [codStatusUpdateOpen, setCodStatusUpdateOpen] = useState(false);
  const [newUpdateValue, setNewUpdateValue] = useState({});
  const [selectedForUpdate, setSelectedForUpdate] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateStatusValue, setUpdateStatusValue] = useState("");
  const [editObj, setEditObj] = useState({});
  const [editIndexNo, setEditIndexNo] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [errors, setErrors] = useState({});
  const [formDialog, setFormDialog] = useState(false);

  const [updateLoading, setUpdateLoading] = useState(false);
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };
  const openFormDialog = () => {
    setFormDialog(true);
  };
  const closeFormDialog = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setFormDialog(false);
  };
  const handleCodStatusUpdateClickOpen = (index, row) => {
    setCodStatusUpdateOpen(true);
  };

  const handleCodStatusUpdateClose = () => {
    setCodStatusUpdateOpen(false);
    setUpdateStatusValue("");
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    getData(0, event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    // let pageNo = newPage + 1;
    console.log("newPage", newPage);
    getData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };
  const pageLoading = () => {
    let rows = [];

    for (let i = 0; i < 15; i++) {
      let cells = [];

      for (let j = 0; j < 4; j++) {
        cells.push(
          <TableCell key={j} sx={{ py: 1.5 }}>
            <Skeleton></Skeleton>
          </TableCell>
        );
      }

      rows.push(<TableRow key={i}>{cells}</TableRow>);
    }

    return rows;
  };
  const clearFilter = (event) => {
    setName("");
    setEmail("");
    setmobileNo("");
    setStatus("");
    setCreatedStartTime(null);
    setCreatedEndTime(null);
    setPage(0);
    let newUrl = `api/customer`;
    getData("", "", newUrl);
  };

  const getData = async (pageNO, pageLimit, newUrl) => {
    setLoading(true);
    setMessage("");
    let newPageNO;
    let newLimit;
    // let url;
    if (pageNO) {
      newPageNO = pageNO - 1;
    } else {
      newPageNO = 0;
    }
    if (pageLimit) {
      newLimit = pageLimit;
    } else {
      newLimit = limit;
    }
    // if (newUrl) {
    //   url = newUrl;
    // } else {
    //   let newStatus = status;
    //   let newCreatedStartTime = "";
    //   let newCreatedEndTime = "";
    //   if (status === "None") {
    //     newStatus = "";
    //   }
    //   if (createdStartTime !== null) {
    //     newCreatedStartTime = dayjs(createdStartTime).format("YYYY-MM-DD");
    //   }
    //   if (createdEndTime !== null) {
    //     newCreatedEndTime = dayjs(createdEndTime).format("YYYY-MM-DD");
    //   }

    //   url = `api/customer?keyword=${name.trim()}&email=${encodeURIComponent(
    //     email.trim()
    //   )}&mobile=${encodeURIComponent(
    //     mobileNo.trim()
    //   )}&gender=${gender}&status=${newStatus}&from=${newCreatedStartTime}&to=${newCreatedEndTime}&page=${newPageNO}`;
    // }
    let url = `/api/v1/private/admins?size=${newLimit}&page=${parseInt(
      newPageNO
    )}`;
    console.log("dizli_admin_panel", dizli_admin_panel);

    let token = await RefreshToken(dizli_admin_panel, logout, login);

    console.log("token -----------------", token);
    // let token = dizli_admin_panel.access_token;
    let res = await getDataWithToken(url, token);
    console.log("res", res?.data?.data);
    // if (res?.status === 401 || res?.status === 403) {
    //   logout();
    //   return;
    // }

    if (res?.status > 199 && res?.status < 300) {
      // setTotalElements(res.data.data.totalElements);
      // setTotalPage(res.data.data.totalPages);
      // setRowsPerPage(res.data.data.per_page);
      if (res.data.data.length > 0) {
        setList(res.data.data);
      } else {
        setMessage("No data found");
        setList([]);
      }
    } else {
      setMessage(res.data.message);
    }
    setLoading(false);
  };
  const handleUpdateStatus = async (updateStatus) => {
    // try {
    setUpdateLoading(true);
    setUpdateStatusValue(updateStatus);
    let formData = new FormData();
    formData.append("phoneNumber", selectedForUpdate.phoneNumber);
    formData.append("documentStatus", updateStatus);

    let token = await RefreshToken(dizli_admin_panel, logout, login);
    let response = await handlePutData(
      `/api/v1/private/admins`,
      formData,
      token
    );
    // await axios({
    //   url: `api/update-user-status`,
    //   method: "post",
    //   data: data,
    //   headers: {
    //     Authorization: `Bearer ${fastpay_support_panel_user.token}`,
    //   },
    // });

    if (response.status === 200) {
      let newUserObj = { ...selectedForUpdate, codStatus: updateStatus };
      list[newUpdateValue.index] = newUserObj;
      handleSnakbarOpen("Update successfully", "success");
      handleCodStatusUpdateClose();
    } else {
      handleSnakbarOpen(response.data.messages.toString(), "error");
    }
    // } catch (error) {
    //   console.log("error", error);

    //   handleSnakbarOpen(error.response.data.messages.toString(), "error");
    // }
    setUpdateLoading(false);
  };
  const buttonStyle = {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "14px",
    pr: 2,
    pl: 2.625,
    py: 0.75,
    "& .MuiButton-startIcon": {
      mr: 0.625,
    },
  };
  const customeTextFeild = {
    // padding: "15px 20px",
    background: "#FAFAFA",
    "& label.Mui-focused": {
      color: "#A0AAB4",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#B2BAC2",
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 24px 15px 0px",
    },
    "& .MuiOutlinedInput-root": {
      paddingLeft: "24px",
      "& fieldset": {
        borderColor: "rgba(0,0,0,0)",
      },

      "&:hover fieldset": {
        borderColor: "#969696",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#969696",
      },
    },
  };
  function convertToTitleCase(str) {
    // Split the string into words based on underscores
    var words = str.split("_");

    // Capitalize the first letter of each word
    for (var i = 0; i < words.length; i++) {
      words[i] =
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    // Join the words back together with a space between them
    return words.join(" ");
  }
  useEffect(() => {
    // setLoading(true);
    getData();
  }, []);
  return (
    <div>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100vh" }}
      >
        <Grid item sx={{ width: "100%" }}>
          {" "}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 2.125, borderBottom: "1px solid #E5E5E5" }}
          >
            <Grid item xs="auto">
              <Typography
                variant="h6"
                color="text.light"
                sx={{ fontWeight: 600 }}
              >
                Admin List{" "}
                <span style={{ fontWeight: 400 }}>({list?.length})</span>
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Breadcrumbs aria-label="breadcrumb" separator="/">
                <Link to="#">Admin</Link>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: "Calc(100vh - 135px)",
              // background: "red",
              overflowY: "auto",
              // pt: 2.5,
              px: 2,
            }}
          >
            <Box sx={{ mt: 4, mb: 2, textAlign: "right" }}>
              <Button
                disableElevation
                variant="contained"
                color="info"
                startIcon={<AddOutlinedIcon />}
                sx={{
                  ...buttonStyle,
                  // background: "#FFECEC",
                  // color: "#555555",
                  // "&:hover": {
                  //   background: "#FFC4C4",
                  // },
                }}
                onClick={(e) => {
                  // setNewUpdateValue({
                  //   index: i,
                  //   id: row.id,
                  //   value: e.target.checked,
                  // });
                  // setSelectedForUpdate(row);
                  setFormDialog(true);
                }}
              >
                Add New
              </Button>
            </Box>
            <Box sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer
                sx={{
                  // overflowX: "auto",
                  // minWidth: "100%",
                  // width: "Calc(100vw - 385px)",
                  maxHeight: "Calc(100vh - 246px)",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>

                      <TableCell>Email</TableCell>
                      <TableCell>Role</TableCell>

                      <TableCell sx={{ textAlign: "right" }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading &&
                      list?.length > 0 &&
                      list?.map((row, i) => (
                        <TableRow
                          key={i}
                          // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell
                            sx={{
                              fontWeight: 700,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row?.firstName} {row?.lastName}
                          </TableCell>

                          <TableCell>{row?.email}</TableCell>
                          <TableCell>
                            {" "}
                            {convertToTitleCase(row?.roleAssignEnumsList[0])}
                          </TableCell>

                          {/* <TableCell>
                            <Button
                              disableElevation
                              variant="contained"
                              component={Link}
                              to={`/product-list/${row?.id}`}
                              sx={{
                                background: "#EFF3FF",
                                color: theme.palette.text.light,
                                fontSize: "12px",
                                fontWeight: 700,
                                "&:hover": {
                                  background: "#CDD9FF",
                                },
                              }}
                            >
                              View Products
                            </Button>
                          </TableCell> */}
                          <TableCell
                            sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                          >
                            <Button
                              disableElevation
                              variant="contained"
                              color="warning"
                              onClick={() => {
                                setEditIndexNo(i);
                                setEditObj(row);
                                setFormDialog(true);
                              }}
                              sx={{
                                background: "#FFF4CC",
                                color: theme.palette.text.light,
                                fontSize: "12px",
                                fontWeight: 700,
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          {/* <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {row?.deposit.toFixed(2)}
                        </TableCell> */}

                          {/* <TableCell sx={{ whiteSpace: "nowrap", p: 0 }}>
                          <Button
                            disableElevation
                            variant="contained"
                            color="warning"
                            component={Link}
                            to={`/cart-details/${row?.cartId}`}
                            sx={{
                              background: "#D9F1F1",
                              color: theme.palette.text.light,
                              fontSize: "12px",
                              fontWeight: 700,
                              "&:hover": {
                                background: "#B5F5F5",
                              },
                            }}
                          >
                            View
                          </Button>
                        </TableCell> */}
                          {/* <TableCell
                          sx={{ whiteSpace: "nowrap", p: 0 }}
                          align="right"
                        >
                          <Button
                            disableElevation
                            variant="contained"
                            color="warning"
                            component={Link}
                            to={`/cart-details/${row?.cartId}`}
                            sx={{
                              background: "#EFF3FF",
                              color: theme.palette.text.light,
                              fontSize: "12px",
                              fontWeight: 700,
                              "&:hover": {
                                background: "#CDD9FF",
                              },
                            }}
                          >
                            View
                          </Button>
                        </TableCell> */}
                        </TableRow>
                      ))}

                    {loading && pageLoading()}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {!loading && list?.length < 1 ? (
              <Box sx={{ textAlign: "center", p: 2 }}>
                <strong> No Data Found</strong>
              </Box>
            ) : null}
          </Box>
        </Grid>
        {/* {list?.length > 0 && ( */}
        {/* <Grid
          item
          xs="auto"
          sx={{
            px: 2,
            py: 1.75,
            borderTop: "1px solid #E5E5E5",
            width: "100%",
          }}
        >
        
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs="auto">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs="auto">
                  <Typography
                    variant="medium"
                    color="text.light"
                    // sx={{ display: "inline-flex" }}
                  >
                    Showing&nbsp; &nbsp;
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box>
                    <FormControl size="small">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={limit}
                        onChange={handleLimitChange}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                    &nbsp; &nbsp;
                  </Box>
                </Grid>
              
              </Grid>
            </Grid>
            <Grid item xs="auto">
              {" "}
              <Pagination
                sx={{ display: "inline-block" }}
                count={totalPage}
                showFirstButton
                showLastButton
                shape="rounded"
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </Grid> */}
        {/* )} */}
      </Grid>
      <Dialog
        open={formDialog}
        onClose={closeFormDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 4, minWidth: "500px" }}>
          <AddEditAdmin
            getData={getData}
            setPage={setPage}
            list={list}
            setList={setList}
            editObj={editObj}
            setEditObj={setEditObj}
            editIndexNo={editIndexNo}
            refresh={refresh}
            setRefresh={setRefresh}
            closeFormDialog={closeFormDialog}
            formDialog={formDialog}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AdminList;
