import React, { useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { getDataWithToken } from "../../../services/GetDataService";
import RefreshToken from "../../../services/RefreshToken";
import { AuthContext } from "../../../context/AuthContext";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";

export default function Reject({
  item,
  index,
  list,
  shoppingCartList,
  setShoppingCartList,
  setInvoiceData,
  invoiceData,
  refresh,
  setRefresh,
  calculateInvoiceData,
}) {
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [allStatus, setAllStatus] = useState([]);
  const [statusType, setStatusType] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [reasons, setReasons] = useState("");

  const [action, setAction] = useState("");

  const handleSatsusChange = () => {};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    setAction("");
  };

  const customeSelect = {
    background: "#fff",

    "& .MuiInputBase-input": {
      // borderRadius: 50,
      position: "relative",
      // backgroundColor: theme.palette.background.paper,
      border: "none",
      fontSize: 14,
      color: "#222",
      // padding: "10px 16px",
      background: "#FFFAE7",
      // Use the system font instead of the default Roboto font.
    },
  };

  const handleChange = (e) => {
    setValue(parseInt(e.target.value));

    console.log("parseInt(e.target.value)", parseInt(e.target.value));
    console.log("item", item);
    // let product = item.product;

    let newItemStatus = item.itemStatus;
    let newObj = item;

    newItemStatus = {
      statusType: statusType,
      id: parseInt(e.target.value),
      reason: allStatus?.find((res) => res.id === parseInt(e.target.value))
        .reason,
    };

    newObj = {
      ...item,
      itemStatus: newItemStatus,
    };

    let newShoppingCartList = shoppingCartList;
    newShoppingCartList[index] = newObj;
    setShoppingCartList(newShoppingCartList);
    calculateInvoiceData(newShoppingCartList);
    setRefresh(!refresh);

    handleClose();
  };

  const getAllStatus = async (statusType) => {
    setLoading(true);
    setAllStatus([]);
    let url = `api/v1/private/admin/reasons-by-status?statusType=${statusType}`;
    let token = await RefreshToken(dizli_admin_panel, logout, login);
    let res = await getDataWithToken(url, token);
    if (res?.status > 199 && res?.status < 300) {
      setAllStatus(res.data.data);
      setLoading(false);
    } else {
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   getAllStatus();
  // }, [statusType]);

  return (
    <React.Fragment>
      <FormControl
        fullWidth
        sx={{ ...customeSelect, width: "150px" }}
        id={`status${index}`}
        size="small"
      >
        <InputLabel id="demo-simple-select-label">Select Please</InputLabel>
        <Select
          id={`status${index}`}
          label="Select Please"
          value={item?.itemStatus?.statusType}
          onChange={(e) => {
            // setAction(e.target.value);
            handleClickOpen();
            setStatusType(e.target.value);
            getAllStatus(e.target.value);
          }}
        >
          {/* <MenuItem value="">Select Please</MenuItem> */}
          <MenuItem value="A_N">Accept</MenuItem>
          <MenuItem value="R_N">Reject</MenuItem>
        </Select>
      </FormControl>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            minWidth: "450px",
            position: "relative",
            px: 4,
            pt: 5,
            pb: 0,
          }}
        >
          Select a reason for{" "}
          <b>{statusType === "R_N" ? "Reject" : "Accept"}</b>
          {/* <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: 24, top: 10 }}
          >
            <ClearIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent
          sx={{ px: 4 }}
          // sx={{ display: "flex", flexDirection: "column", gap: 2, width: 400 }}
        >
          {loading && (
            <Box sx={{ mt: 2 }}>
              {[1, 2, 3, 4, 5].map((item, i) => (
                <Skeleton
                  variant="rectangular"
                  key={i}
                  sx={{ mb: 2 }}
                ></Skeleton>
              ))}
            </Box>
          )}
          <FormControl sx={{ mb: 3, mt: 2 }}>
            {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={item?.itemStatus?.id}
              onChange={handleChange}
            >
              {allStatus?.map((item, index) => (
                <FormControlLabel
                  key={index}
                  sx={{
                    // alignItems: 'center',
                    ".MuiFormControlLabel-label": {
                      fontSize: "14px",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: 18,
                    },
                  }}
                  value={item.id}
                  control={<Radio />}
                  label={item.reason}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {/* <FormControl fullWidth sx={{ mb: 3, mt: 2 }} size="small">
            <InputLabel id="demo-simple-select-label">Select Please</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // size="small"
              label="Select Please"
              value={item?.itemStatus?.id}
              onChange={handleChange}
            >
              {loading && <MenuItem value="">Loading...</MenuItem>}
              {allStatus?.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.reason}
                  </MenuItem>
                );
              })}
        
            </Select>
          </FormControl> */}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
