import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
// import MenuIcon from "../icons/MenuIcon";
import { useTheme } from "@mui/material/styles";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Chip from "@mui/material/Chip";
import {
  Avatar,
  Button,
  IconButton,
  Paper,
  Skeleton,
  TextField,
} from "@mui/material";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Collapse from "@mui/material/Collapse";
import { getDataWithToken } from "../../services/GetDataService";
import { AuthContext } from "../../context/AuthContext";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import axios from "axios";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RefreshToken from "../../services/RefreshToken";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { handlePostData } from "../../services/PostDataService";
import PulseLoader from "react-spinners/PulseLoader";
import { handlePutRowData } from "../../services/PutRowDataService";
import { handlePutData } from "../../services/PutDataService";
import BlockIcon from "@mui/icons-material/Block";

const UserDetails = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { dizli_admin_panel, logout, login } = useContext(AuthContext);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [gender, setGender] = useState("");
  const [status, setStatus] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [message, setMessage] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [createdStartTime, setCreatedStartTime] = useState(null);
  const [createdEndTime, setCreatedEndTime] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [codStatusUpdateOpen, setCodStatusUpdateOpen] = useState(false);
  const [newUpdateValue, setNewUpdateValue] = useState({});
  const [selectedForUpdate, setSelectedForUpdate] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateStatusValue, setUpdateStatusValue] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [blockDialog, setBlockDialog] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false);
  const [userStatus, setUserStatus] = useState(false);

  const handleBlockClose = () => {
    setBlockDialog(false);
  };

  const handleBlockChange = (event) => {
    console.log("event.target.checked", event.target.checked);
    setUserStatus(event.target.checked);
    setBlockDialog(true);
  };

  const [updateLoading, setUpdateLoading] = useState(false);
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const handleCodStatusUpdateClickOpen = (index, row) => {
    setCodStatusUpdateOpen(true);
  };

  const handleCodStatusUpdateClose = () => {
    setCodStatusUpdateOpen(false);
    setUpdateStatusValue("");
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    getData(0, event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    // let pageNo = newPage + 1;
    console.log("newPage", newPage);
    getData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };
  const pageLoading = () => {
    let rows = [];

    for (let i = 0; i < 15; i++) {
      let cells = [];

      for (let j = 0; j < 10; j++) {
        cells.push(
          <TableCell key={j} sx={{ py: 1.5 }}>
            <Skeleton></Skeleton>
          </TableCell>
        );
      }

      rows.push(<TableRow key={i}>{cells}</TableRow>);
    }

    return rows;
  };
  const handleBlock = async () => {
    // console.log("editIndexNo", editIndexNo);

    setBlockLoading(true);
    let response;
    let token = await RefreshToken(dizli_admin_panel, logout, login);

    // return console.log('data', data);

    const formData = new FormData();
    formData.append("phoneNumber", list.phoneNumber);
    formData.append("isEnabled", checked);

    let url = `api/v1/private/user/enabled`;
    response = await handlePostData(url, formData, token, true);

    if (response?.status > 199 && response?.status < 300) {
      handleBlockClose();
      handleSnakbarOpen("Successful", "success");
      setBlockLoading(false);
      setChecked(userStatus);
    } else {
      setBlockLoading(false);
      handleSnakbarOpen(response?.data?.message, "error");
    }
  };
  const getData = async (pageNO, pageLimit, newUrl) => {
    setLoading(true);
    setMessage("");
    let newPageNO;
    let newLimit;
    // let url;
    if (pageNO) {
      newPageNO = pageNO - 1;
    } else {
      newPageNO = 0;
    }
    if (pageLimit) {
      newLimit = pageLimit;
    } else {
      newLimit = limit;
    }

    let url = `/api/v1/private/user/statistics/${id}`;

    let token = await RefreshToken(dizli_admin_panel, logout, login);

    // let token = dizli_admin_panel.access_token;
    let res = await getDataWithToken(url, token);
    console.log("res", res?.data?.data);
    // if (res?.status === 401 || res?.status === 403) {
    //   logout();
    //   return;
    // }
    console.log("res.data.data", res.data.data);
    if (res?.status > 199 && res?.status < 300) {
      // setTotalElements(res.data.data.totalElements);
      // setTotalPage(res.data.data.totalPages);
      // setRowsPerPage(res.data.data.per_page);
      // if (res.data.data.content.length > 0) {
      //   setList(res.data.data);
      // } else {
      //   setMessage("No data found");
      //   setList([]);
      // }
      setList(res.data.data);
      setChecked(!res.data.data.isEnabled);
      setUserStatus(!res.data.data.isEnabled);
    } else {
      setMessage(res.data.message);
    }
    setLoading(false);
  };
  const handleUpdateStatus = async (updateStatus) => {
    // try {
    setUpdateLoading(true);
    setUpdateStatusValue(updateStatus);
    let formData = new FormData();
    formData.append("phoneNumber", selectedForUpdate.phoneNumber);
    formData.append("documentStatus", updateStatus);

    let token = await RefreshToken(dizli_admin_panel, logout, login);
    let response = await handlePutData(
      `/api/v1/private/user/update/cod-status`,
      formData,
      token
    );
    // await axios({
    //   url: `api/update-user-status`,
    //   method: "post",
    //   data: data,
    //   headers: {
    //     Authorization: `Bearer ${fastpay_support_panel_user.token}`,
    //   },
    // });

    if (response.status === 200) {
      let newUserObj = { ...selectedForUpdate, codStatus: updateStatus };
      list[newUpdateValue.index] = newUserObj;
      handleSnakbarOpen("Update successfully", "success");
      handleCodStatusUpdateClose();
    } else {
      handleSnakbarOpen(response.data.messages.toString(), "error");
    }
    // } catch (error) {
    //   console.log("error", error);

    //   handleSnakbarOpen(error.response.data.messages.toString(), "error");
    // }
    setUpdateLoading(false);
  };
  useEffect(() => {
    // setLoading(true);
    getData();
  }, []);
  return (
    <div>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        sx={{ height: "100vh" }}
      >
        <Grid item sx={{ width: "100%" }}>
          {" "}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 2.125, borderBottom: "1px solid #E5E5E5" }}
          >
            <Grid item xs="auto">
              <Typography
                variant="h6"
                color="text.light"
                sx={{ fontWeight: 600 }}
              >
                User Details
                {/* <span style={{ fontWeight: 400 }}>({totalElements})</span> */}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator="/"
                // className={classes.breadcrumbsStyle}
              >
                <Link to="#">Users</Link>

                {/* <Link to="#">Confirmed Carts</Link> */}
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: "Calc(100vh - 75px)",
              // background: "red",
              overflowY: "auto",
              // pt: 2.5,
              px: 2,
            }}
          >
            <Grid container justifyContent="space-between" sx={{ mt: 4 }}>
              <Grid
                item
                sx={{
                  width: "380px",
                  py: 6,
                  px: 2,
                  border: "1px solid #E5E5E5",
                  borderRadius: "6px",
                }}
              >
                <Box>
                  <img
                    src="/user_avatar.png"
                    alt="avatar"
                    style={{
                      width: "156px",
                      height: "156px",
                      borderRadius: "100vw",
                      display: "block",
                      margin: "auto",
                    }}
                  />
                  <Typography
                    color="text.light"
                    sx={{ fontSize: "18px", textAlign: "center", mt: 1.5 }}
                  >
                    {list.firstName} {list.lastName}
                  </Typography>
                  <Typography
                    variant="small"
                    color="text.fade"
                    sx={{ textAlign: "center", mt: 0.5, fontWeight: 700 }}
                  >
                    Member Since
                  </Typography>
                  <Typography
                    variant="medium"
                    color="text.light"
                    sx={{ textAlign: "center", mt: 0.5 }}
                  >
                    {list?.createdAt !== null
                      ? moment(list?.createdAt).format("MMM DD, YYYY")
                      : "-------"}
                  </Typography>

                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ my: 4 }}
                  >
                    <Typography variant="medium" sx={{ fontWeight: 700 }}>
                      Unblock
                    </Typography>
                    <Switch
                      color="error"
                      checked={checked}
                      onChange={handleBlockChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography variant="medium" color="text.light">
                      Block
                    </Typography>
                  </Stack>

                  <Typography
                    variant="base"
                    color="primary.main"
                    sx={{ fontWeight: 500, mb: 1.5 }}
                  >
                    Personal Details
                  </Typography>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow sx={{ "& td, & th": { border: 0 } }}>
                        <TableCell
                          sx={{
                            width: "120px",
                            color: "#969696",
                            fontWeight: 700,
                            pl: 0,
                            py: 1,
                          }}
                        >
                          User ID
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "20px",
                            color: "#969696",
                            fontWeight: 700,
                            py: 1,
                          }}
                        >
                          :
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#222222",
                            fontWeight: 500,
                            py: 1,
                          }}
                        >
                          {list.userId}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ "& td, & th": { border: 0 } }}>
                        <TableCell
                          sx={{
                            width: "120px",
                            color: "#969696",
                            fontWeight: 700,
                            pl: 0,
                            py: 1,
                          }}
                        >
                          First Name
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "20px",
                            color: "#969696",
                            fontWeight: 700,
                            py: 1,
                          }}
                        >
                          :
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#222222",
                            fontWeight: 500,
                            py: 1,
                          }}
                        >
                          {list.firstName}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ "& td, & th": { border: 0 } }}>
                        <TableCell
                          sx={{
                            width: "120px",
                            color: "#969696",
                            fontWeight: 700,
                            pl: 0,
                            py: 1,
                          }}
                        >
                          Last Name
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "20px",
                            color: "#969696",
                            fontWeight: 700,
                            py: 1,
                          }}
                        >
                          :
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#222222",
                            fontWeight: 500,
                            py: 1,
                          }}
                        >
                          {list.lastName}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ "& td, & th": { border: 0 } }}>
                        <TableCell
                          sx={{
                            width: "120px",
                            color: "#969696",
                            fontWeight: 700,
                            pl: 0,
                            py: 1,
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "20px",
                            color: "#969696",
                            fontWeight: 700,
                            py: 1,
                          }}
                        >
                          :
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#222222",
                            fontWeight: 500,
                            py: 1,
                          }}
                        >
                          {list.email}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ "& td, & th": { border: 0 } }}>
                        <TableCell
                          sx={{
                            width: "120px",
                            color: "#969696",
                            fontWeight: 700,
                            pl: 0,
                            py: 1,
                          }}
                        >
                          Phone Number
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "20px",
                            color: "#969696",
                            fontWeight: 700,
                            py: 1,
                          }}
                        >
                          :
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#222222",
                            fontWeight: 500,
                            py: 1,
                          }}
                        >
                          {list.phoneNumber}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ "& td, & th": { border: 0 } }}>
                        <TableCell
                          sx={{
                            width: "120px",
                            color: "#969696",
                            fontWeight: 700,
                            pl: 0,
                            py: 1,
                          }}
                        >
                          Address
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "20px",
                            color: "#969696",
                            fontWeight: 700,
                            py: 1,
                          }}
                        >
                          :
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#222222",
                            fontWeight: 500,
                            py: 1,
                          }}
                        >
                          {list.region}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ "& td, & th": { border: 0 } }}>
                        <TableCell
                          sx={{
                            width: "120px",
                            color: "#969696",
                            fontWeight: 700,
                            pl: 0,
                            py: 1,
                          }}
                        >
                          Download Date
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "20px",
                            color: "#969696",
                            fontWeight: 700,
                            py: 1,
                          }}
                        >
                          :
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#222222",
                            fontWeight: 500,
                            py: 1,
                          }}
                        >
                          {list?.downloadDate !== null
                            ? moment(list?.downloadDate).format("MMM DD, YYYY")
                            : "-------"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  width: "calc(100% - 396px)",
                }}
              >
                <Box
                  sx={{
                    py: 3,
                    px: 2,
                    border: "1px solid #E5E5E5",
                    borderRadius: "6px",
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="base"
                    color="primary.main"
                    sx={{ fontWeight: 500, mb: 2 }}
                  >
                    Purchase Details
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Typography
                        variant="base"
                        color="text.fade"
                        sx={{ fontWeight: 700, mb: 0.75 }}
                      >
                        Customer Type
                      </Typography>
                      <Typography
                        variant="base"
                        color="text.main"
                        sx={{ mb: 1.5 }}
                      >
                        {list.orderCount < 1 ? "New" : "Returning Customer"}
                      </Typography>
                      <Typography
                        variant="base"
                        color="text.fade"
                        sx={{ fontWeight: 700, mb: 0.75 }}
                      >
                        Value of Orders
                      </Typography>
                      <Typography variant="base" color="text.main">
                        {list.totalOrderAmount?.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="base"
                        color="text.fade"
                        sx={{ fontWeight: 700, mb: 0.75 }}
                      >
                        Sessions
                      </Typography>
                      <Typography
                        variant="base"
                        color="text.main"
                        sx={{ mb: 1.5 }}
                      >
                        {list.sessionsCount}
                      </Typography>
                      <Typography
                        variant="base"
                        color="text.fade"
                        sx={{ fontWeight: 700, mb: 0.75 }}
                      >
                        First Order Date
                      </Typography>
                      <Typography variant="base" color="text.main">
                        {list?.firstOrderDate !== null
                          ? moment(list?.firstOrderDate).format("MMM DD, YYYY")
                          : "-------"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="base"
                        color="text.fade"
                        sx={{ fontWeight: 700, mb: 0.75 }}
                      >
                        Checkouts
                      </Typography>
                      <Typography
                        variant="base"
                        color="text.main"
                        sx={{ mb: 1.5 }}
                      >
                        {list.checkoutCount}
                      </Typography>
                      <Typography
                        variant="base"
                        color="text.fade"
                        sx={{ fontWeight: 700, mb: 0.75 }}
                      >
                        Last Order Date
                      </Typography>
                      <Typography variant="base" color="text.main">
                        {list?.lastOrderDate !== null
                          ? moment(list?.lastOrderDate).format("MMM DD, YYYY")
                          : "-------"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="base"
                        color="text.fade"
                        sx={{ fontWeight: 700, mb: 0.75 }}
                      >
                        Number of Orders
                      </Typography>
                      <Typography
                        variant="base"
                        color="text.main"
                        sx={{ mb: 1.5 }}
                      >
                        {list.orderCount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    py: 3,
                    px: 2,
                    border: "1px solid #E5E5E5",
                    borderRadius: "6px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography
                        variant="base"
                        color="primary.main"
                        sx={{ fontWeight: 500, mb: 2 }}
                      >
                        COD Activation
                      </Typography>
                      <Typography
                        variant="base"
                        color="text.fade"
                        sx={{ fontWeight: 700, mb: 0.75 }}
                      >
                        COD Status
                      </Typography>
                      <Typography variant="base" color="text.main">
                        {list.codStatus}
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography
                        variant="base"
                        color="primary.main"
                        sx={{ fontWeight: 500, mb: 2 }}
                      >
                        Credits
                      </Typography>
                      <Typography
                        variant="base"
                        color="text.fade"
                        sx={{ fontWeight: 700, mb: 0.75 }}
                      >
                        Credit Balance
                      </Typography>
                      <Typography variant="base" color="text.main">
                        -------
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="base"
                        color="text.fade"
                        sx={{ fontWeight: 700, mb: 0.75, mt: 5.125 }}
                      >
                        Add Credit
                      </Typography>
                      <Typography variant="base" color="text.main">
                        -------
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={codStatusUpdateOpen}
        onClose={handleCodStatusUpdateClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            minWidth: "450px",
            position: "relative",
            px: 4,
            pt: 5,
            textAlign: "center",
          }}
        >
          <b>{"COD Status Update"}</b>
          <IconButton
            onClick={handleCodStatusUpdateClose}
            style={{ position: "absolute", right: 24, top: 10 }}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 4 }}>
          <Typography>
            {" "}
            <b>Name : </b> &nbsp; {selectedForUpdate?.firstName}{" "}
            {selectedForUpdate?.lastName}
          </Typography>
          <Typography>
            {" "}
            <b>Email : </b> &nbsp; {selectedForUpdate?.email}
          </Typography>
          <Typography>
            {" "}
            <b>Number : </b> &nbsp; {selectedForUpdate?.phoneNumber}
          </Typography>
          <img
            src={selectedForUpdate?.doc}
            style={{
              width: "100%",
              // maxWidth: "350px",
              maxHeight: "400px",
              marginTop: "12px",
            }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", px: 4, pb: 5 }}>
          <Button
            variant="outlined"
            color="error"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            disabled={updateStatusValue === "REJECTED"}
            startIcon={<ClearIcon />}
            onClick={() => handleUpdateStatus("REJECTED")}
          >
            {updateStatusValue !== "REJECTED" && "REJECTED"}
            <PulseLoader
              color={"#353b48"}
              loading={updateStatusValue === "REJECTED"}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
          <Button
            variant="contained"
            color="success"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            disabled={updateStatusValue === "VERIFIED"}
            startIcon={<DoneAllIcon />}
            onClick={() => handleUpdateStatus("VERIFIED")}
          >
            {updateStatusValue !== "VERIFIED" && "VERIFIED"}
            <PulseLoader
              color={"#353b48"}
              loading={updateStatusValue === "VERIFIED"}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
          {/* <Button
             onClick={()=>handleUpdateStatus("REJECTED")}
            variant="outlined"
            color="error"
            startIcon={<ClearIcon />}
          >
            REJECTED
          </Button>
          <Button
            disableElevation
            onClick={()=>handleUpdateStatus("VERIFIED")}
            variant="contained"
            color="success"
            startIcon={<DoneAllIcon />}
          >
            VERIFIED
          </Button> */}
        </DialogActions>
      </Dialog>

      <Dialog
        open={blockDialog}
        onClose={handleBlockClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            minWidth: "450px",
            position: "relative",
            px: 4,
            pt: 5,
            textAlign: "center",
          }}
        >
          {/* <b>{"Mark Order as Delivered"}</b> */}
          <IconButton
            onClick={handleBlockClose}
            style={{ position: "absolute", right: 24, top: 10 }}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 4 }}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", fontWeight: 600, mb: 2 }}
          >
            {" "}
            Are you sure!!!
          </Typography>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            {" "}
            You Want to &nbsp;
            <b>{userStatus ? "Block" : "Unblock"}</b> this user
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", px: 4, pb: 5 }}>
          <Button
            variant="outlined"
            color="text"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            disabled={blockLoading}
            startIcon={<ClearIcon />}
            onClick={handleBlockClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color={userStatus ? "error" : "success"}
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            disabled={blockLoading}
            startIcon={<BlockIcon />}
            onClick={handleBlock}
          >
            {userStatus ? "Block" : "Unblock"}
            {/* {!blockLoading && "Delete"} */}
            <PulseLoader
              color={"#353b48"}
              loading={blockLoading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDetails;
