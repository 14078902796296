import { Button } from "@mui/material";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

const CopyUrl = ({ url, websiteName }) => {
  const [copy, setCopy] = useState(false);

  return (
    <div>
      <CopyToClipboard text={url} onCopy={() => setCopy(true)}>
        <Button
          variant="outlined"
          size="small"
          sx={{ height: 26 }}
          color="info"
        >
          {copy ? "Copied"  : `${websiteName} url`}
        </Button>
      </CopyToClipboard>
    </div>
  );
};

export default CopyUrl;
