import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { AuthContext } from "../../../context/AuthContext";
import RefreshToken from "../../../services/RefreshToken";
import PulseLoader from "react-spinners/PulseLoader";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { handlePutData } from "../../../services/PutDataService";

const buttonStyle = {
  textTransform: "none",
  fontWeight: 700,
  fontSize: "14px",
  pr: 2,
  pl: 2.625,
  py: 0.75,
  "& .MuiButton-startIcon": {
    mr: 0.625,
  },
};

export default function RejectCart({ cartId }) {
  const navigate = useNavigate();
  const { dizli_admin_panel, logout, login } = React.useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReject = async () => {
    if (!rejectReason.trim()) {
      handleSnakbarOpen("Please enter reject reason", "error");
      document.getElementById("rejectReason").focus();
      return;
    }
    setLoading(true);
    try {
      let url = `api/v1/private/admin/cart/${cartId}/reject`;
      let token = await RefreshToken(dizli_admin_panel, logout, login);
      let formData = new FormData();
      formData.append("rejectionReason", rejectReason.trim());

      // let res = await axios({
      //   method: "put",
      //   url: url,
      //   data: formData,
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      let res = await handlePutData(url, formData, token);
      enqueueSnackbar(res.data.message, {
        variant: "success",
        autoHideDuration: 3000,
      });
      navigate("/live-carts");
      setLoading(true);
    } catch (error) {
      console.log("error", error);
      setLoading(true);
      return error.response;
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="text"
        color="error"
        fullWidth
        sx={{
          ...buttonStyle,
          py: 1.75,
          mb: 1.25,
        }}
        onClick={handleClickOpen}
      >
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.333 4.98332C15.558 4.70832 12.7663 4.56665 9.98301 4.56665C8.33301 4.56665 6.68301 4.64998 5.03301 4.81665L3.33301 4.98332"
            stroke="#F23836"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.91602 4.14175L8.09935 3.05008C8.23268 2.25841 8.33268 1.66675 9.74102 1.66675H11.9243C13.3327 1.66675 13.441 2.29175 13.566 3.05841L13.7493 4.14175"
            stroke="#F23836"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.5417 7.6167L16 16.0084C15.9083 17.3167 15.8333 18.3334 13.5083 18.3334H8.15833C5.83333 18.3334 5.75833 17.3167 5.66667 16.0084L5.125 7.6167"
            stroke="#F23836"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.44141 13.75H12.2164"
            stroke="#F23836"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.75 10.4167H12.9167"
            stroke="#F23836"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        &nbsp; Reject Cart
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            minWidth: "450px",
            position: "relative",
            px: 4,
            pt: 5,
            pb: 0,
            textAlign: "center",
          }}
        >
          Are you sure to Reject the cart?
        </DialogTitle>
        <DialogContent>
          <br />
          <TextField
            id="rejectReason"
            label="Reason"
            // placeholder="Placeholder"
            multiline
            rows={4}
            fullWidth
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
          />
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", px: 4, pb: 5 }}>
          <Button
            variant="outlined"
            color="text"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            startIcon={<ClearIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            disableElevation
            sx={{ minHeight: "37px", minWidth: "130px" }}
            disabled={loading}
            startIcon={<HighlightOffRoundedIcon />}
            onClick={handleReject}
          >
            {!loading && "Reject"}
            <PulseLoader
              color={"#353b48"}
              loading={loading}
              size={10}
              speedMultiplier={0.5}
            />{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
