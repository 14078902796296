import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, IconButton, Typography } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
export default function Descreption({ detail }) {
  const [open, setOpen] = React.useState(false);
  const [variations, setVariations] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    console.log("detail?.variations", detail?.variations);
    let obj = detail?.variations;
    let arr = [];
    if (obj) {
      Object.entries(obj).forEach(([key, value]) => {
        console.log("Key: " + key + ", Value: " + value);
        arr.push({
          key: key,
          value: value,
        });
      });
    }
    setVariations(arr);
  }, []);

  return (
    <React.Fragment>
      <Button variant="outlined" size="small" onClick={handleClickOpen}>
        Description
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontSize: "16px",
            minWidth: "450px",
            position: "relative",
            px: 4,
            pt: 6,
          }}
        >
          {detail?.title}
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: 24, top: 10 }}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 4, pb: 6 }}>
          {detail?.dimension && (
            <Typography variant="h6" sx={{ fontWeight: 600, color: "#222" }}>
              {" "}
              Dimention
            </Typography>
          )}
          {detail?.dimension}

          <Typography variant="h6" sx={{ fontWeight: 600, color: "#222" }}>
            {" "}
            Variations
          </Typography>

          {variations.length > 0 &&
            variations.map((item, index) => (
              <Box>
                {item.key} : {item.value}
              </Box>
            ))}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
